exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-Modal-CompanyModal-UpperLimitModal-style__modalContent--2Kwvj {\n  width: 432px;\n  box-sizing: border-box;\n  padding: 16px 16px 24px;\n}\n\n.src-features-Modal-CompanyModal-UpperLimitModal-style__contentBody--3Up-Y {\n  color: #000;\n  line-height: 20px;\n}\n\n.src-features-Modal-CompanyModal-UpperLimitModal-style__bottomContent--2Ot6v {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  -ms-flex-align: end;\n      align-items: end;\n}\n\n.src-features-Modal-CompanyModal-UpperLimitModal-style__buttonWrapper--11kOB {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  margin-top: 24px;\n}\n.src-features-Modal-CompanyModal-UpperLimitModal-style__buttonWrapper--11kOB .src-features-Modal-CompanyModal-UpperLimitModal-style__button--1D0Nm {\n  width: 148px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"modalContent": "src-features-Modal-CompanyModal-UpperLimitModal-style__modalContent--2Kwvj",
	"contentBody": "src-features-Modal-CompanyModal-UpperLimitModal-style__contentBody--3Up-Y",
	"bottomContent": "src-features-Modal-CompanyModal-UpperLimitModal-style__bottomContent--2Ot6v",
	"buttonWrapper": "src-features-Modal-CompanyModal-UpperLimitModal-style__buttonWrapper--11kOB",
	"button": "src-features-Modal-CompanyModal-UpperLimitModal-style__button--1D0Nm"
};