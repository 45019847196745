import { useCallback, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useReduxState } from "hooks/useReduxState";
import { Response } from "models/api/binder/List";
import { actionCreators as headerActionCreators } from "modules/header";
import { request } from "utils/apiClient";
import {
  setBinderInfo,
  setBinderCategoryInfo
} from "modules/binders/item/actions";
import { useHistory } from "react-router";
import { useBinderType } from "./Components/Common/hook";

export const useBinderItem = (binderId: string) => {
  const binderItem = useReduxState(s => s.binders.item);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const type = useBinderType();

  const fetchBinderList = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await request<Response>("/binder/list", { binderId });
      dispatch(setBinderInfo(response.binderInfo));
      dispatch(setBinderCategoryInfo(response.lists));
      setIsLoading(false);
    } catch (e) {
      const redirectPath =
        type === "nikkei"
          ? "/binder/public"
          : type === "personal"
          ? "/binder/personal"
          : "/";

      history.replace(redirectPath, { reserveErrors: true });
    }
  }, [binderId, dispatch, type, history]);

  const categoryList = {
    corp: binderItem.categoryInfoList.find(item => item.categoryCode === "VS02")
      ?.totalCount,
    industry: binderItem.categoryInfoList.find(
      item => item.categoryCode === "VS03"
    )?.totalCount,
    statistic: binderItem.categoryInfoList.find(
      item => item.categoryCode === "VS04"
    )?.totalCount,
    savedArticle: binderItem.categoryInfoList.find(
      item => item.categoryCode === "VS05"
    )?.totalCount,
    externalPage: binderItem.categoryInfoList.find(
      item => item.categoryCode === "VS06"
    )?.totalCount
  };

  const updatePin = async (binderId: string) => {
    try {
      await request("/binder/pinned", { binderId }, "POST");
      dispatch(
        headerActionCreators.requestUpdate({ shouldLoadCollection: true })
      );
      return { success: true };
    } catch {
      return { success: false };
    }
  };

  const updateMailCollection = async () => {
    await request("/binder/mail-alert", { binderId }, "POST");
  };

  const pinned = binderItem.binderInfo?.pinnedFlg === "1";

  const published = binderItem.binderInfo?.publicStatus === "1";

  const mailCollection = useMemo(
    () => binderItem.binderInfo?.mailAlertFlg === "1",
    [binderItem.binderInfo]
  );

  return {
    isLoading,
    binderItem,
    fetchBinderList,
    categoryList,
    updatePin,
    pinned,
    published,
    updateMailCollection,
    mailCollection
  };
};
