import { isArray } from "util";
import { AreaExtType, ExtType } from "./Macro";

export interface BinderItem {
  binderInfo: BinderInfo;
  articleList: Article[];
  corpTotalCount: number;
  industryTotalCount: number;
  saveArticleTotalCount: number;
  statisticsTotalCount: number;
  externalPageTotalCount: number;
  thumbnailUrl?: string;
  checked: boolean;
}

export interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
  baseOptFlg?: string;
}

export interface Complements {
  label: string;
  text: string;
}

export interface ComplementsError {
  labelError: boolean;
  textError: boolean;
}

export interface CollectionCorpInfo {
  corpCode: string;
  corpName: string;
  source: string;
  stkCode: string;
  baseOptFlg?: string;
}

export interface BinderInfo {
  binderId: string;
  caption: string;
  corpList: CollectionCorpInfo[];
  description: string;
  andKeyword: string;
  orKeyword: string;
  notKeyword: string;
  includeRelatedCorp: string;
  createUserName?: string;
  sharedFlg: boolean;
  updateDate?: string;
  publicStatus: string;
  pinnedFlg: string;
  mediaList: Media[];
  definition: Complements[];
  background: Complements[];
  task: Complements[];
  technology: Complements[];
  topics: Complements[];
  query: string;
  mailAlertFlg: string;
  searchType: string;
  regulation: Complements[];
  upperLimit: UpperLimit;
}

export interface UpperLimit {
  definition: Complements;
  background: Complements;
  task: Complements;
  technology: Complements;
  topics: Complements;
  regulation: Complements;
}

export interface Media {
  baseOptFlg: string;
  label: string;
  checked: boolean;
  value: string;
  mediaFeeType: string;
  hierarchy: string;
}

export interface Image {
  description: string;
  height: string;
  width: string;
  imagePath: string;
  style: string;
}

export interface Article {
  articleId: string;
  articleKbn: string;
  attachmentPath: string;
  cardId: string;
  corpName: string;
  dataExistsFlg: boolean;
  displayTime: string;
  highlight: Array<string>;
  highlightIndex: Array<string>;
  thumbPath: string;
  mediaCode: string;
  mediaName: string;
  title: string;
  description: string;
  keywords: Array<string>;
  sponserName: string;
  canonicalUrl: string;
  mediaFeeType: string;
  keyPdfFare: string;
  keyPdf: string;
  keyPpt: string;
  keyBodyFare: string;
  errorStatus?: string;
  errorDetailMessage?: string;
}

export interface ExternalPage {
  cardId: string;
  url: string;
  title: string;
  thumbPath: string;
  domain: string;
  favicon: string;
  description: string;
}

export interface MessageDto {
  msgCode: string;
  message: string;
}

export interface CategoryInfo {
  categoryCode: string;
  totalCount: number;
}

export interface Corporation {
  asrf: string;
  businessDescription: string;
  cardId: string;
  corpCode: string;
  corpIndustryKbn: string;
  corpName: string;
  corpType: string;
  dataExistsFlg: boolean;
  industryName: string;
  stockType: string;
  source: string;
  memo: string;
}

export const isCorporation = (arg: any): arg is Corporation => {
  return Object.prototype.hasOwnProperty.call(arg, "corpName");
};

export const isCorporationArray = (arg: any): arg is Corporation => {
  if (!isArray(arg)) return false;
  return isCorporation(arg[0]);
};

export interface Industry {
  cardId: string;
  corpIndustryKbn: string;
  dataExistsFlg: boolean;
  industryClassification: string;
  industryCode: string;
  industryDefinition: string;
  industryName: string;
  sectorReportPath: string;
  keyPpt: string;
  largeIndustryCode: string;
  middleIndustryCode: string;
}

export interface Statistic {
  area: string;
  areadescj: string;
  dataExistsFlg: boolean;
  cardId: string;
  schema: string;
  series: string;
  statisticsType: string;
  title: string;
  seriesDatas: {
    date: string;
    value: string;
  }[];
  areaSeriesData: {
    name: string;
    seriesData: {
      date: string;
      value: string;
    }[];
  }[];
  extFlg: ExtType;
  areaExtFlg: AreaExtType;
  seriesdescj: string;
  orgdescj: string;
}

export type BinderActionType =
  | "delete"
  | "delete_return_index"
  | "clone"
  | "clone_show_new"
  | "share"
  | "edit"
  | "editItem"
  | "new"
  | "publish";

export interface BinderActionSet {
  type: BinderActionType;
  binderInfo?: BinderInfo;
}

export type BinderType = "nikkei" | "personal";

export const isBinderType = (arg: any): arg is BinderType => {
  return arg === "nikkei" || arg === "personal";
};
