import React, { useState, useMemo, useEffect } from "react";
import Modal from "components/Common/Modal";
import { useBinderAction, BinderRequestParams } from "./hook";
import { usePersonalBinder } from "../../List/Components/PersonalBinderList/hook";
import styles from "./style.scss";
import Button from "components/Common/Button/Text";
import { useHistory, useLocation } from "react-router";
import { useToast } from "hooks/useToast";
import { BinderActionType } from "models/BinderItem";
import { assertUnreachable } from "utils/ts/typeAssertions";
import TextInput from "components/Common/TextInput";
import { BinderForm } from "../BinderForm";
import { CloseConfirmModal } from "../CloseConfirmModal";
import { trackAction } from "utils/helper/analytics";
import { useBinderItem } from "features/BinderPage/Item/hook";
import { AdvancedSearch } from "features/AboutSearch/AdvancedSearch";
import OverseasPremiumModal from "features/OverseasPremium/DetailModal";

const modalTexts = {
  delete: {
    title: "コレクションの削除",
    detail:
      "・一度削除したコレクションは復元できません。\n・共有中のコレクションを削除した場合、共有先のユーザーはこれ\nらを参照できなくなります。",
    button: "削除"
  },
  clone: {
    title: "コレクションの複製",
    detail:
      "複製を行うとフィード設定や保存コンテンツを引き継いだコレクションを作成します。\n複製元のコレクションの共有設定は新たなコレクションには引き継がれません。",
    button: "複製"
  },
  share: {
    title: "コレクションの共有",
    button: "共有"
  },
  edit: {
    title: "編集",
    button: "保存"
  },
  editItem: {
    title: "入力内容の確認",
    detail: "この内容で新規作成してもよろしいですか？",
    button: "編集の保存"
  },
  new: {
    title: "コレクションを作成",
    button: "新規作成"
  },
  publish: {
    title: "コレクションの公開",
    button: "保存"
  }
};

const getModalType = (type: BinderActionType) => {
  switch (type) {
    case "delete":
    case "delete_return_index":
      return "delete";
    case "clone":
    case "clone_show_new":
      return "clone";
    case "new":
      return "new";
    case "share":
      return "share";
    case "edit":
      return "edit";
    case "editItem":
      return "editItem";
    case "publish":
      return "publish";
  }
  assertUnreachable(type);
};

interface Props {
  onClose?: () => void;
  handleSave?: (params: BinderRequestParams) => void;
  isSaveLoading?: boolean;
  isOpen?: boolean;
  errors?: string[];
}

export const BinderActionModal: React.FunctionComponent<Props> = ({
  onClose = () => {},
  handleSave = undefined,
  isSaveLoading = false,
  isOpen = null,
  errors = []
}) => {
  const {
    binderActionState,
    closeBinderActionModal,
    cloneBinder,
    shareBinder,
    publishBinder,
    editComplement,
    createBinder,
    updateBinder,
    cloneCaption,
    setCloneCaption,
    limit
  } = useBinderAction();

  const isOpenModal = useMemo(() => {
    if (isOpen !== null) {
      return isOpen;
    } else {
      return binderActionState !== null;
    }
  }, [binderActionState, isOpen]);

  const { fetchBinderList } = useBinderItem(
    binderActionState?.binderInfo?.binderId ?? ""
  );

  const { fetchPersonalBinders, deletePersonalBinder } = usePersonalBinder();

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenCompanyModal, setIsOpenCompanyModal] = useState(false);

  const [definitionError, setDefinitionError] = useState("");
  const [backgroundError, setBackgroundError] = useState("");
  const [otherError, setOtherError] = useState("");
  const [taskError, setTaskError] = useState("");
  const [technologyError, setTechnologyError] = useState("");
  const [topicsError, setTopicsError] = useState("");
  const [regulationError, setRegulationError] = useState("");
  const [reOpenFlag, setReOpenFlag] = useState(false);
  const [notDeletable, setNotDeletable] = useState(true);
  const [isOpenHelp, setIsOpenHelp] = useState(false);
  const [isOpenDetailModal, setIsOpenDetailModal] = useState(false);

  const setErrors = (messages: string[] | undefined) => {
    if (!messages) return;
    messages.forEach(message => {
      if (message.includes("定義")) {
        setDefinitionError(message);
      } else if (message.includes("背景")) {
        setBackgroundError(message);
      } else if (message.includes("課題")) {
        setTaskError(message);
      } else if (message.includes("技術")) {
        setTechnologyError(message);
      } else if (message.includes("話題")) {
        setTopicsError(message);
      } else if (message.includes("法規制")) {
        setRegulationError(message);
      } else {
        setOtherError(message);
      }
    });
  };

  const resetErrors = () => {
    setDefinitionError("");
    setBackgroundError("");
    setTaskError("");
    setTechnologyError("");
    setTopicsError("");
    setRegulationError("");
    setOtherError("");
  };

  const history = useHistory();
  const location = useLocation();
  const toast = useToast();

  const sendTrackAction = (
    type: BinderActionType,
    binderId: string,
    binderName: string
  ) => {
    const actionType: {
      [key in BinderActionType]?: "編集" | "共有" | "複製" | "削除" | "公開";
    } = {
      edit: "編集",
      share: "共有",
      clone: "複製",
      clone_show_new: "複製",
      delete: "削除",
      delete_return_index: "削除",
      publish: "公開"
    };

    const action = actionType[type];
    if (!action) return;

    trackAction("doneMyCollectionOperation", {
      action: action,
      id: binderId,
      name: binderName
    });
  };

  // ボタン押下後のアクション
  const onAction = async (binderInfo: BinderRequestParams | null = null) => {
    if (!binderActionState) return;

    const binderId = binderActionState.binderInfo?.binderId ?? "";
    const binderName = binderActionState?.binderInfo?.caption ?? "";

    sendTrackAction(binderActionState.type, binderId, binderName);

    // 作成・編集時はAPIを待って閉じる
    if (!["new", "edit", "editItem"].includes(binderActionState.type)) {
      closeBinderActionModal();
      onClose();
    }

    switch (binderActionState.type) {
      // 削除
      case "delete":
      case "delete_return_index":
        await deletePersonalBinder(binderId);
        await fetchPersonalBinders(limit);
        if (binderActionState.type === "delete_return_index") {
          history.push("/binder/personal");
        }
        toast.setMessages(["コレクションを削除しました"]);
        break;
      // 複製
      case "clone":
      case "clone_show_new":
        const defaultCaption =
          binderActionState?.binderInfo?.caption + "のコピー";
        const cloneBinderId = await cloneBinder(binderId, defaultCaption);
        await fetchPersonalBinders(limit);
        if (binderActionState.type === "clone_show_new") {
          history.push(`/binder/${cloneBinderId}/article?type=personal`);
        } else {
          toast.setMessages(["コレクションを複製しました"]);
        }
        break;
      // 共有
      case "share":
        const sharedFlg = binderInfo?.setShare ?? false;
        await shareBinder(binderId, sharedFlg);
        // 画面を再読み込みし、共有マークの表示/非表示を行うため
        if (location.pathname.match(/\/binder\/[0-9]+/)) {
          // コレクション詳細ページの場合
          await fetchBinderList();
        } else {
          await fetchPersonalBinders(limit);
        }
        toast.setMessages([
          sharedFlg
            ? "コレクションを共有しました"
            : "コレクションの共有を解除しました"
        ]);
        break;
      // 公開
      case "publish":
        const publishedFlg = binderInfo?.setPublish ?? false;
        await publishBinder(binderId, publishedFlg);
        if (location.pathname.match(/\/binder\/[0-9]+/)) {
          // コレクション詳細ページの場合
          await fetchBinderList();
        } else {
          // 画面を再読み込みし、公開マークの表示/非表示を行うため
          await fetchPersonalBinders(limit);
        }
        toast.setMessages([
          publishedFlg
            ? "コレクションを公開しました"
            : "コレクションの公開を解除しました"
        ]);
        break;
      // 新規作成
      case "new":
        if (!binderInfo) break;
        resetErrors();
        const createResult = await createBinder(binderInfo);
        if (!createResult.success) {
          setErrors(createResult.messages);
          return;
        }
        closeBinderActionModal();
        onClose();
        await fetchPersonalBinders(limit);
        toast.setMessages(["コレクションを作成しました"]);
        break;
      // 編集
      case "edit":
        if (!binderInfo) break;
        resetErrors();
        const updateResult = await updateBinder(
          binderInfo,
          binderId,
          closeBinderActionModal
        );
        if (!updateResult.success) {
          setErrors(updateResult.messages);
          return;
        }
        await fetchPersonalBinders(limit);
        toast.setMessages(["コレクションを編集しました"]);
        break;
      case "editItem":
        if (!binderInfo) break;
        resetErrors();
        const updateArticleResult = await updateBinder(
          binderInfo,
          binderId,
          closeBinderActionModal,
          true
        );
        const complementResult = await editComplement(binderInfo, binderId);
        if (!updateArticleResult.success || !complementResult.success) {
          setErrors(updateArticleResult.messages);
          return;
        }
        toast.setMessages(["コレクションを編集しました"]);
        break;
    }
  };

  const modalType = binderActionState
    ? getModalType(binderActionState.type)
    : "new";

  const checkReOpenFlag = () => {
    if (reOpenFlag) {
      setReOpenFlag(false);
    }
    return reOpenFlag;
  };

  useEffect(() => {
    setErrors(errors);
  }, [errors]);

  return (
    <>
      <Modal
        isOpen={isOpenModal}
        uncontrolled
        modalClassName={
          modalType === "delete" ? styles.deleteModal : styles.modal
        }
        title={modalTexts[modalType].title}
        qsFlag={
          modalType === "new" ||
          modalType === "edit" ||
          modalType === "editItem"
        }
        onClose={() => {
          if (
            isOpenModal &&
            binderActionState &&
            (modalType === "new" ||
              modalType === "edit" ||
              modalType === "editItem")
          ) {
            if (isOpenCompanyModal) return true;

            setIsOpenConfirmModal(true);
            // 共通モーダルを閉じなかったこととして返す
            return true;
          } else if (modalType === "clone") {
            setCloneCaption("");
          }
          setNotDeletable(true);
          closeBinderActionModal();
          onClose();
        }}
        checkReOpenFlag={checkReOpenFlag}
        appendix={
          isOpenHelp && (
            <AdvancedSearch
              isInModal={true}
              onClose={() => setIsOpenHelp(false)}
            />
          )
        }
      >
        {modalType === "clone" && (
          <div className={styles.nameInput}>
            <div className={styles.inputLabel}>コレクション名</div>
            <div>
              <TextInput
                placeholder={
                  binderActionState?.binderInfo?.caption + "のコピー"
                }
                value={cloneCaption}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setCloneCaption(e.target.value);
                }}
              />
            </div>
          </div>
        )}
        {modalType === "new" ||
        modalType === "edit" ||
        modalType === "editItem" ||
        modalType === "share" ||
        modalType === "publish" ? (
          <BinderForm
            menuType={modalType}
            binderInfo={binderActionState?.binderInfo}
            onAction={onAction}
            handleSave={handleSave}
            isSaveLoading={isSaveLoading}
            isOpenCompanyModal={isOpenCompanyModal}
            setOpenCompanyModal={isOpen => setIsOpenCompanyModal(isOpen)}
            error={{
              definition: definitionError,
              background: backgroundError,
              task: taskError,
              technology: technologyError,
              topics: topicsError,
              other: otherError,
              regulation: regulationError
            }}
            setIsOpenHelp={setIsOpenHelp}
            setIsOpenDetailModal={setIsOpenDetailModal}
            setOtherError={setOtherError}
          />
        ) : (
          <>
            {modalType === "delete" && (
              <div>
                <div className={styles.deleteLabel}>削除するコレクション</div>
                <div
                  className={styles.collectionName}
                  data-testid="BinderPage-Common-BinderActionModal-collectionName"
                >
                  {binderActionState?.binderInfo?.caption}
                </div>
              </div>
            )}
            <div
              className={
                modalType === "delete" ? styles.deleteContent : styles.content
              }
            >
              {modalType === "delete" && (
                <div className={styles.deleteLabel}>注意点</div>
              )}
              {modalTexts[modalType].detail}
            </div>
            {modalType === "delete" && (
              <>
                <div className={styles.deleteLabel}>本当に削除しますか？</div>
                <div className={styles.deleteConfirmText}>
                  ・誤操作防止のため、削除するコレクションの名称を入力してください。
                </div>
                <div>
                  <TextInput
                    placeholder="削除するコレクションの名前"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setNotDeletable(
                        event.target.value !==
                          binderActionState?.binderInfo?.caption
                      );
                    }}
                    entireText={true}
                  />
                </div>
              </>
            )}
            <div className={styles.buttonWrapper}>
              <Button
                color="navy"
                onClick={() => {
                  closeBinderActionModal();
                  onClose();
                  if (modalType === "clone") {
                    setCloneCaption("");
                  }
                }}
                data-testid="BinderPage-Common-BinderActionModal-closeButton"
              >
                閉じる
              </Button>
              <Button
                color={modalType === "delete" ? "red" : "orange"}
                onClick={() => {
                  onAction();
                }}
                disabled={modalType === "delete" && notDeletable}
                data-testid="BinderPage-Common-BinderActionModal-actionModal"
              >
                {modalTexts[modalType].button}
              </Button>
            </div>
          </>
        )}
      </Modal>

      <CloseConfirmModal
        isOpen={isOpenConfirmModal}
        keepButtonText="入力を再開"
        onClickKeepButton={() => {
          setIsOpenConfirmModal(false);
          setReOpenFlag(true);
        }}
        onClickCloseButton={() => {
          setIsOpenConfirmModal(false);
          resetErrors();
          closeBinderActionModal();
          onClose();
        }}
      />
      <OverseasPremiumModal
        isOpen={isOpenDetailModal}
        onClose={() => {
          setIsOpenDetailModal(false);
        }}
      />
    </>
  );
};
