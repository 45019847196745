import React from "react";
import styles from "./style.scss";
import LoadingSpinner from "components/Common/LoadingSpinner";

export interface Props {
  isSearchResult?: boolean;
  isLoading?: boolean;
}

const EmptyView: React.FunctionComponent<Props> = ({
  isSearchResult = true,
  isLoading = false
}: Props) => {
  if (isLoading) {
    return (
      <div className={styles.loadingWrapper}>
        <LoadingSpinner size="L" />
      </div>
    );
  }

  return (
    <div className={styles.component} data-testid="Common-EmptyView">
      {isSearchResult ? (
        <>
          <div className={styles.searchResult}>検索結果： 0件</div>
          <div>
            ご指定の条件に該当する情報が見つかりませんでした。
            <br />
            条件を変更して再度ご検索ください。
          </div>
        </>
      ) : (
        <div>保存されたデータがありません。</div>
      )}
    </div>
  );
};

export default EmptyView;
