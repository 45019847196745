import React, { useEffect } from "react";
import styles from "./style.scss";
import Button from "components/Common/Button/Text";
import cn from "classnames";
import { useFileImport } from "./hook";
import LoadingSpinner from "components/Common/LoadingSpinner";
import { ToolTip } from "features/ToolTip";
import ImportImage from "images/icon/import_collection.svg";
import { preloadImage } from "utils/helper/preload";
import { trackAction } from "utils/helper/analytics";

const HELP_URL = "https://intercom.help/valuesearch/ja/articles/4447459";

interface Props {
  onFinishImport: () => void;
}

export const FileImport: React.FunctionComponent<Props> = props => {
  const {
    displayImportArea,
    file,
    isLoading,
    componentRef,
    inputRef,
    handleClose,
    handleClickOpen,
    handleChangeFile,
    handleClickCancel,
    handleClickExecute
  } = useFileImport();

  useEffect(() => {
    preloadImage(ImportImage);

    window.addEventListener("click", handleClose);
    return () => {
      window.removeEventListener("click", handleClose);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={componentRef} className={styles.component}>
      <ToolTip text="コレクションのインポート" position="bottom">
        <div
          className={styles.import}
          onClick={() => {
            handleClickOpen();
            trackAction("myCollectionImportClick");
          }}
        >
          <span className={styles.importImage} />
          <span>インポート</span>
        </div>
      </ToolTip>
      <div
        className={cn(styles.importArea, { [styles.hide]: !displayImportArea })}
      >
        <div
          className={cn(styles.loadingWrapper, { [styles.hide]: !isLoading })}
        >
          <LoadingSpinner size="L" />
        </div>
        <div className={cn({ [styles.hide]: isLoading })}>
          <div className={styles.inputWrapper}>
            <div className={styles.title}>コレクションのインポート</div>
            <Button
              onClick={() => {
                inputRef.current?.click();
                trackAction("myCollectionExcelClick");
              }}
            >
              Excelファイルの選択
            </Button>
            <input
              type="file"
              ref={inputRef}
              className={styles.file}
              accept=".xlsx"
              onChange={handleChangeFile}
            />
            <div>ファイル名：{file?.name}</div>
            <div>
              ファイルのフォーマットについては
              <a href={HELP_URL} target="_blank" rel="noopener noreferrer">
                こちら
              </a>
              をご参照ください。
            </div>
          </div>
          <div className={styles.bottom}>
            <Button
              onClick={() => {
                trackAction("myCollectionImportSave");
                handleClickExecute(props.onFinishImport);
              }}
              disabled={file === undefined}
            >
              実行
            </Button>
            <Button
              color="link"
              onClick={() => {
                trackAction("myCollectionImportCancel");
                handleClickCancel();
              }}
            >
              キャンセル
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
