exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-EmptyView-style__component--czY_n {\n  padding: 32px;\n  white-space: pre-wrap;\n  color: #141e32;\n  border: 1px solid #eaf1f5;\n  background-color: #fff;\n}\n.src-components-Common-EmptyView-style__component--czY_n .src-components-Common-EmptyView-style__searchResult--3HeLF {\n  margin-bottom: 16px;\n  font-size: 16px;\n  font-weight: bold;\n  line-height: 1.2;\n}\n\n.src-components-Common-EmptyView-style__loadingWrapper--3dWkP {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  width: 100%;\n  height: 100%;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-components-Common-EmptyView-style__component--czY_n",
	"searchResult": "src-components-Common-EmptyView-style__searchResult--3HeLF",
	"loadingWrapper": "src-components-Common-EmptyView-style__loadingWrapper--3dWkP"
};