import React from "react";
import styles from "./style.scss";

import Checkbox from "components/Common/Checkbox";

const OverseasAreaDropDown = props => {
  return (
    <div className={styles.dropDown}>
      <ul className={styles.vsList}>
        <li
          className={styles.allCheckboxItem}
          data-testid="Common-CorpTypeSourceSelector-Implementation-OverseasAreaDropDown-allCheckboxItem"
        >
          <Checkbox
            name={`すべて ${props.allCount}`}
            onChange={e => props.onChangeAllCheckbox(e.target.checked)}
            isActive={props.isAllActive}
            isChecked={props.isAllChecked}
            isIndeterminate={props.isAllIndeterminate}
            noHover={true}
            textClassName={styles.checkboxLabel}
          />
        </li>
        <li>
          {/* 海外 */}
          <ul className={styles.vsList}>
            {props.areaInfo.map((child, index) => {
              const label =
                props.allCount !== null
                  ? `${child.item.label} ${child.count}`
                  : child.item.label;
              const isActive = child.isActive;

              return (
                <li
                  key={child.item.inneyCode}
                  className={styles.checkboxChildItem}
                  data-testid={`Common-CorpTypeSourceSelector-Implementation-OverseasAreaDropDown-childItem-${index}`}
                >
                  <Checkbox
                    name={label}
                    onChange={e => {
                      props.onChangeChildCheckbox(
                        child.item.inneyCode,
                        e.target.checked
                      );
                    }}
                    className={styles.checkbox}
                    isActive={isActive}
                    isChecked={child.isChecked}
                    isSmall={false}
                    textClassName={styles.checkboxLabel}
                    noHover={true}
                  />
                </li>
              );
            })}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default OverseasAreaDropDown;
