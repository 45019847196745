import React from "react";
import styles from "./style.scss";
import cn from "classnames";

interface Item {
  companyCode: string;
  companyName: string;
  stkCode?: string;
  source?: string;
  companyKbn?: string;
  baseOptFlg?: string;
}

interface Props {
  items: Item[];
  onDelete: (item: Item) => void;
  onClear: () => void;
}

export const SelectedItemTable: React.FC<Props> = ({
  items,
  onDelete,
  onClear
}) => {
  return (
    <>
      <div className={styles.tableTop}>
        <div className={styles.label}>選択済み項目</div>
        <div
          className={styles.clear}
          onClick={onClear}
          data-testid="components-Tool-Screening-Sorporation-Item-Modern-Condition-Modal-CompanyModal-SelectedItemTable-clear"
        >
          項目をクリア
        </div>
      </div>
      <div className={styles.table}>
        {items.map((item, index) => {
          const disabled = item.baseOptFlg === "9";
          return (
            <div
              className={cn(styles.row, {
                [styles.disabled]: disabled
              })}
              key={`selected-item-row-${index}`}
            >
              {!disabled && (
                <span
                  className={styles.close}
                  onClick={() => onDelete(item)}
                  data-testid="components-Tool-Screening-Sorporation-Item-Modern-Condition-Modal-CompanyModal-SelectedItemTable-delete"
                />
              )}
              <span
                className={cn(styles.kabushiki, {
                  [styles.disabled]: disabled
                })}
              >
                {item.stkCode}
              </span>
              <span className={styles.source}>{item.source}</span>
              <span>{item.companyName}</span>
            </div>
          );
        })}
      </div>
    </>
  );
};
