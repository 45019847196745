import React from "react";
import styles from "./style.scss";

const ListItem = props => {
  const onMouseOver = () => {
    props.setItemFocus(props.index);
  };

  const onClick = () => {
    props.selectCallback(props.item);
  };

  return (
    <li
      data-testid="list-item"
      className={`
        ${styles.item}
        ${props.index === props.focusedItemIndex && styles.focused}
      `}
      onMouseOver={onMouseOver}
      onClick={onClick}
    >
      {props.item.keyword}
    </li>
  );
};

export default ListItem;
