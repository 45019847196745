import React, { Component } from "react";
import styles from "./style.scss";

import CorpTypeDropDown from "./CorpTypeDropDown";
import OverseasAreaDropDown from "./OverseasAreaDropDown";

import { isEmpty } from "utils/helper/common";

import cn from "classnames";

// 上場
const listedCode = "1";
// 有報
const reportedCode = "2";
// 非上場(日経)
const unlistedCorpCode = "3";
// 非上場(TDB)
const tdbCorpCode = "11";
// 非上場(ケップル)
const startupCorpCode = "13";
// 国内非上場企業
const baseUnlistedCodes = [
  reportedCode,
  unlistedCorpCode,
  startupCorpCode,
  tdbCorpCode
];

// 海外
const overseasCorpCode = "4";

// 海外地域別
const overseasAreaCodes = ["5", "6", "7", "8", "9", "10"];

// 海外非上場
const unlistedOverseasCorpCode = "14";

// 海外非上場地域別
const unlistedOverseasAreaCodes = ["15", "16", "17", "18", "19", "20"];

const overseasCorpCodes = overseasAreaCodes.concat(overseasCorpCode);

const unlistedOverseasCorpCodes = unlistedOverseasAreaCodes.concat(
  unlistedOverseasCorpCode
);

// 海外地域別(list:上場、unlisted:非上場)
const areaCodes = [
  { list: "5", unlisted: "15" },
  { list: "6", unlisted: "16" },
  { list: "7", unlisted: "17" },
  { list: "8", unlisted: "18" },
  { list: "9", unlisted: "19" },
  { list: "10", unlisted: "20" }
];

/**
 * ソース選択
 * @prop {?object[]} items 選択肢のオブジェクト
 * @prop {?function} onChangeSourceSelector 選択変更時処理。引数として選択状態更新後のitemsを渡す
 * @prop {?boolean} shouldShowCount 件数表示するならtrue、しないならfalse。デフォルトはfalse
 * @prop {?string} label
 * items = [
 *   {
 *     id: "CORPFILTER_CHECKBOXID_LISTED_CORP_CreateReport",
 *     labelId: "CORPFILTER_LABELID_LISTED_CORP_CreateReport",
 *     selected: false,
 *     corpCount: "0",
 *     industrycorptopCondition: "10", // 売上高上位x件返し用
 *     active: true,
 *     haveAuthority: true,
 *     fsarea: "",
 *     value: "1",
 *     seq: "1",
 *     inneyCode: "1",
 *     label: "上場"
 *   }
 * ]
 */
const defaultProps = {
  onChangeSourceSelector: () => {},
  items: [],
  label: null,
  shouldShowCount: false,
  disableOverseas: false,
  enableTdb: false,
  enableStartup: false,
  unlistedOverseasHide: false,
  isAllDeactive: false,
  corpTypeTitle: "企業種別"
};

class SourceSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCorpTypeDropDownOpen: false,
      isOverseasAreaDropDownOpen: false,
      isCheckedListedOverseaCorp: false,
      isCheckedUnlistedOverseaCorp: false
    };

    this.corpTypeWrapperRef = React.createRef();
    this.overseasAreaWrapperRef = React.createRef();
    this.corpTypeDropdDownRef = React.createRef();
    this.overseasAreaDropdDownRef = React.createRef();
    this.emptyItem = { value: "", label: "" };
    this.buildCheckboxInfo = this.buildCheckboxInfo.bind(this);
    this.buildOverseasCheckboxInfo = this.buildOverseasCheckboxInfo.bind(this);
    this.onChangeAllCheckbox = this.onChangeAllCheckbox.bind(this);
    this.onChangeParentCheckbox = this.onChangeParentCheckbox.bind(this);
    this.onChangeChildCheckbox = this.onChangeChildCheckbox.bind(this);
    this.openCorpTypeDropDown = this.openCorpTypeDropDown.bind(this);
    this.closeDropDownOnClickOutside = this.closeDropDownOnClickOutside.bind(
      this
    );

    this.buildAreaCheckboxInfo = this.buildAreaCheckboxInfo.bind(this);
    this.onChangeAreaAllCheckbox = this.onChangeAreaAllCheckbox.bind(this);
    this.onChangeAreaCheckbox = this.onChangeAreaCheckbox.bind(this);
    this.getAreaCodes = this.getAreaCodes.bind(this);
    this.openOverseasAreaDropDown = this.openOverseasAreaDropDown.bind(this);
    this.isShowOverseasArea = this.isShowOverseasArea.bind(this);

    this.onChangeCorpTypePosition = this.onChangeCorpTypePosition.bind(this);
    this.onChangeOverseasAreaPosition = this.onChangeOverseasAreaPosition.bind(
      this
    );
  }

  componentDidMount() {
    this.props.onChangeSourceSelector(
      this.checkItemInfo(this.props.items),
      false
    );

    const checkItemInfo = this.checkItemInfo(this.props.items);

    const overseasListedItem = checkItemInfo.find(
      item => item.inneyCode === overseasCorpCode
    );
    const overseasUnlistedItem = checkItemInfo.find(
      item => item.inneyCode === unlistedOverseasCorpCode
    );
    const overseasListedChecked =
      overseasListedItem && overseasListedItem.selected;
    const overseasUnlistedChecked =
      overseasUnlistedItem && overseasUnlistedItem.selected;

    // 海外上場・海外非上場のチェック状態を初期化
    this.setState({
      isCheckedListedOverseaCorp: overseasListedChecked,
      isCheckedUnlistedOverseaCorp: overseasUnlistedChecked
    });

    if (this.props.windowRef && this.props.windowRef.current) {
      this.props.windowRef.current.bodyRef.current.addEventListener(
        "click",
        this.closeDropDownOnClickOutside
      );
      this.props.windowRef.current.bodyRef.current.addEventListener(
        "scroll",
        this.onChangeCorpTypePosition
      );
      this.props.windowRef.current.bodyRef.current.addEventListener(
        "scroll",
        this.onChangeOverseasAreaPosition
      );
    } else {
      window.addEventListener("click", this.closeDropDownOnClickOutside);
      window.addEventListener("scroll", this.onChangeCorpTypePosition);
      window.addEventListener("scroll", this.onChangeOverseasAreaPosition);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.closeDropDownOnClickOutside);
    window.removeEventListener("scroll", this.onChangeCorpTypePosition);
    window.removeEventListener("scroll", this.onChangeOverseasAreaPosition);
  }

  checkItemInfo(items) {
    let overseasFlag = false;
    let unlistedOverseasFlag = false;
    let checkedItems = items.map(item => {
      if (overseasCorpCodes.includes(item.inneyCode)) {
        item.selected =
          item.active && item.haveAuthority && !this.props.disableOverseas
            ? item.selected
            : false;
        overseasFlag = overseasFlag || item.selected;
        return item;
      } else if (unlistedOverseasCorpCodes.includes(item.inneyCode)) {
        item.selected =
          item.active &&
          item.haveAuthority &&
          !this.props.disableOverseas &&
          !this.props.unlistedOverseasHide
            ? item.selected
            : false;
        unlistedOverseasFlag = unlistedOverseasFlag || item.selected;
        return item;
      }
      return item;
    });

    checkedItems = checkedItems.map(item => {
      if (item.inneyCode === overseasCorpCode) {
        item.selected = overseasFlag;
        return item;
      } else if (item.inneyCode === unlistedOverseasCorpCode) {
        item.selected = unlistedOverseasFlag;
        return item;
      }
      return item;
    });

    return checkedItems;
  }

  /**
   * チェックボックス情報生成
   *
   * @param {string} inneyCode 企業種別inneyCode
   * @returns {object} チェックボックス表示のための情報
   */
  buildCheckboxInfo(inneyCode) {
    // 種別情報取得
    const item = this.props.items.find(item => item.inneyCode === inneyCode);
    if (item === undefined || !item.haveAuthority) return undefined;

    // TDB 無効時は非表示
    if (!this.props.enableTdb && inneyCode === tdbCorpCode) return undefined;

    // 非上場(ケップル)無効時は非表示
    if (!this.props.enableStartup && inneyCode === startupCorpCode)
      return undefined;

    // ログイン時に取得した active が false なら非表示
    if (!item.active) return undefined;

    // 有効/無効
    const isActive =
      !this.props.isAllDeactive &&
      (!this.props.shouldShowCount || item.corpCount !== "0");

    // チェック状態
    const isChecked = item.selected;

    // 表示企業数
    const count = isActive ? parseInt(item.corpCount) : 0;

    return {
      item,
      isActive,
      isChecked,
      count: this.props.shouldShowCount ? count : ""
    };
  }

  /**
   * 海外企業用チェックボックス情報生成
   *
   * @param {string} inneyCode 企業種別inneyCode
   * @returns {object} チェックボックス表示のための情報
   */
  buildOverseasCheckboxInfo(inneyCode, areasInfo) {
    if (this.props.disableOverseas) return undefined;

    // 種別情報取得
    const item = this.props.items.find(item => item.inneyCode === inneyCode);
    if (item === undefined || !item.haveAuthority) return undefined;
    //スクリーニング企業シナリオ選択時、海外上場非対応時非表示にする
    if (isEmpty(areasInfo) && this.props.unlistedOverseasHide && !item.active)
      return undefined;

    // 有効/無効
    const isActive =
      !this.props.isAllDeactive &&
      (!this.props.shouldShowCount ||
        areasInfo.some(({ isActive }) => isActive));

    // 表示企業数
    const count = isActive
      ? areasInfo.reduce((acc, cur) => acc + cur.count, 0)
      : 0;

    // 地域がすべて選択されているか
    const isChecked = areasInfo.every(({ isChecked }) => isChecked);

    // 地域が１つでも選択されているか
    const isAreaIndeterminate =
      !isChecked && areasInfo.some(({ isChecked }) => isChecked);

    return {
      item,
      isActive,
      isChecked,
      count: this.props.shouldShowCount ? count : "",
      isAreaIndeterminate
    };
  }

  /**
   * チェックボックス情報生成
   *
   * @param {string} inneyCode 企業種別inneyCode
   * @param {boolean} overseasFlag 海外チェック状態
   * @param {boolean} unlistedOverseasFlag 海外非上場チェック状態
   * @returns {object} チェックボックス表示のための情報
   */
  buildAreaCheckboxInfo(inneyCode, overseasFlag, unlistedOverseasFlag) {
    // 海外企業無効時は非表示
    if (
      this.props.disableOverseas &&
      (overseasAreaCodes.indexOf(inneyCode.list) !== -1 ||
        unlistedOverseasAreaCodes.indexOf(inneyCode.unlisted) !== -1)
    )
      return undefined;

    // 種別情報取得
    const overseasItem = this.props.items.find(
      item => item.inneyCode === inneyCode.list
    );
    const unlistedOverseasItem = this.props.items.find(
      item => item.inneyCode === inneyCode.unlisted
    );

    if (overseasItem === undefined && unlistedOverseasItem === undefined)
      return undefined;

    // ログイン時に取得した active が false なら非表示
    if (
      !overseasItem.active &&
      (!unlistedOverseasItem.active || this.props.unlistedOverseasHide)
    )
      return undefined;

    const total =
      (overseasFlag ? parseInt(overseasItem.corpCount) : 0) +
      (unlistedOverseasFlag ? parseInt(unlistedOverseasItem.corpCount) : 0);

    // 有効/無効
    const isActive =
      !this.props.isAllDeactive && (!this.props.shouldShowCount || total !== 0);

    // チェック状態
    const isChecked = overseasItem.selected || unlistedOverseasItem.selected;

    // 表示企業数
    const count = isActive ? total : 0;

    return {
      item: overseasItem,
      isActive,
      isChecked,
      count: this.props.shouldShowCount ? count : ""
    };
  }

  getAreaCodes(code, allCheckFlag, list1, list2) {
    const checkedItems = this.checkItemInfo(this.props.items);
    switch (
      [
        checkedItems.find(
          item => item.inneyCode === overseasCorpCode && item.selected
        )
          ? 1
          : 0,
        checkedItems.find(
          item => item.inneyCode === unlistedOverseasCorpCode && item.selected
        )
          ? 1
          : 0
      ].toString()
    ) {
      case "1,0":
        if (allCheckFlag) {
          return list1;
        }
        return [code];
      case "0,1":
        if (allCheckFlag) {
          return list2;
        }
        return [(parseInt(code) + 10).toString()];
      default:
        if (allCheckFlag) {
          return list1.concat(list2);
        }
        return [code, (parseInt(code) + 10).toString()];
    }
  }

  // すべてチェック変更時
  onChangeAllCheckbox(checked) {
    const selectedList = this.props.items.map(item => ({
      ...item,
      selected: checked
    }));
    this.setState({
      isCheckedListedOverseaCorp: checked,
      isCheckedUnlistedOverseaCorp: checked
    });
    this.props.onChangeSourceSelector(this.checkItemInfo(selectedList), true);
  }

  // すべてチェック変更時
  onChangeAreaAllCheckbox(checked) {
    const areaCorpList = this.getAreaCodes(
      null,
      true,
      overseasAreaCodes,
      unlistedOverseasAreaCodes
    );

    const selectedList = this.props.items.map(item => {
      if (!areaCorpList.includes(item.inneyCode)) {
        return item;
      }

      return {
        ...item,
        selected: checked
      };
    });

    if (areaCorpList.includes(overseasCorpCode)) {
      this.setState({
        isCheckedListedOverseaCorp: true,
        isCheckedUnlistedOverseaCorp: areaCorpList.includes(
          unlistedOverseasCorpCode
        )
      });
    } else if (areaCorpList.includes(unlistedOverseasCorpCode)) {
      this.setState({
        isCheckedListedOverseaCorp: false,
        isCheckedUnlistedOverseaCorp: true
      });
    } else {
      this.setState({
        isCheckedListedOverseaCorp: true,
        isCheckedUnlistedOverseaCorp: true
      });
    }

    this.props.onChangeSourceSelector(this.checkItemInfo(selectedList), true);
  }

  // 子要素ありチェック変更時
  onChangeParentCheckbox(codes, checked, isAreaIndeterminate) {
    //海外、海外非上場企業種別がハイフン表示状態で押下した場合、海外と海外非上場が連動される
    if (
      (codes.includes(overseasCorpCode) ||
        codes.includes(unlistedOverseasCorpCode)) &&
      isAreaIndeterminate
    ) {
      codes = overseasCorpCodes.concat(unlistedOverseasCorpCodes);
    }

    if (codes.includes(overseasCorpCode)) {
      this.setState({
        isCheckedListedOverseaCorp: checked,
        isCheckedUnlistedOverseaCorp: codes.includes(unlistedOverseasCorpCode)
      });
    } else if (codes.includes(unlistedOverseasCorpCode)) {
      this.setState({
        isCheckedListedOverseaCorp: false,
        isCheckedUnlistedOverseaCorp: checked
      });
    }
    const selectedList = this.props.items.map(item => {
      if (!codes.includes(item.inneyCode)) {
        return item;
      }
      return {
        ...item,
        selected: checked
      };
    });

    this.props.onChangeSourceSelector(this.checkItemInfo(selectedList), true);
  }

  // チェック変更時
  onChangeChildCheckbox(code, checked) {
    const selectedList = this.props.items.map(item => {
      if (code !== item.inneyCode) {
        return item;
      }
      return {
        ...item,
        selected: checked
      };
    });

    this.props.onChangeSourceSelector(this.checkItemInfo(selectedList), true);
  }

  onChangeAreaCheckbox(code, checked) {
    const codes = this.getAreaCodes(code, false, null, null);
    const selectedList = this.props.items.map(item => {
      if (!codes.includes(item.inneyCode)) {
        return item;
      }

      return {
        ...item,
        selected: checked
      };
    });

    const eitherOneSelected = selectedList.some(
      item => item.selected && item.active
    );

    if (codes.some(code => overseasCorpCodes.includes(code))) {
      this.setState({
        isCheckedListedOverseaCorp: eitherOneSelected
      });
    } else {
      this.setState({
        isCheckedListedOverseaCorp: false
      });
    }

    if (codes.some(code => unlistedOverseasCorpCode.includes(code))) {
      this.setState({
        isCheckedUnlistedOverseaCorp: eitherOneSelected
      });
    } else {
      this.setState({
        isCheckedUnlistedOverseaCorp: false
      });
    }

    this.props.onChangeSourceSelector(selectedList, true);
  }

  onChangeCorpTypePosition() {
    if (!this.corpTypeWrapperRef.current || !this.corpTypeDropdDownRef.current)
      return;
    const position = this.corpTypeWrapperRef.current.getBoundingClientRect();
    this.corpTypeDropdDownRef.current.style.position = "relative";
    this.corpTypeDropdDownRef.current.style.top = `${position.height}px`;
    this.corpTypeDropdDownRef.current.style.left = `${0 -
      position.width -
      12}px`;
  }

  onChangeOverseasAreaPosition() {
    if (
      !this.overseasAreaWrapperRef.current ||
      !this.overseasAreaDropdDownRef.current
    )
      return;

    const position = this.overseasAreaWrapperRef.current.getBoundingClientRect();
    this.overseasAreaDropdDownRef.current.style.position = "relative";
    this.overseasAreaDropdDownRef.current.style.top = `${position.height}px`;
    this.overseasAreaDropdDownRef.current.style.left = `${0 -
      position.width -
      12}px`;
  }

  openCorpTypeDropDown() {
    this.setState(prev => {
      if (!prev.isCorpTypeDropDownOpen) {
        this.onChangeCorpTypePosition();
      }
      return { isCorpTypeDropDownOpen: !prev.isCorpTypeDropDownOpen };
    });
  }

  openOverseasAreaDropDown() {
    this.setState(prev => {
      if (!prev.isOverseasAreaDropDownOpen) {
        this.onChangeOverseasAreaPosition();
      }
      return { isOverseasAreaDropDownOpen: !prev.isOverseasAreaDropDownOpen };
    });
  }

  closeDropDownOnClickOutside(event) {
    if (
      this.state.isCorpTypeDropDownOpen &&
      this.corpTypeWrapperRef.current !== null &&
      this.corpTypeDropdDownRef.current !== null &&
      !this.corpTypeWrapperRef.current.contains(event.target) &&
      !this.corpTypeDropdDownRef.current.contains(event.target)
    ) {
      this.setState({ isCorpTypeDropDownOpen: false });
    }

    if (
      this.state.isOverseasAreaDropDownOpen &&
      this.overseasAreaWrapperRef.current !== null &&
      this.overseasAreaDropdDownRef.current !== null &&
      !this.overseasAreaWrapperRef.current.contains(event.target) &&
      !this.overseasAreaDropdDownRef.current.contains(event.target)
    ) {
      this.setState({ isOverseasAreaDropDownOpen: false });
    }
  }

  isShowOverseasArea(flag) {
    if (flag) {
      return true;
    } else {
      return (
        this.state.isCheckedListedOverseaCorp ||
        this.state.isCheckedUnlistedOverseaCorp
      );
    }
  }

  render() {
    // 上場企業のチェックボックス
    const listedCheckboxInfo = this.buildCheckboxInfo(listedCode);
    const isListedChecked = listedCheckboxInfo
      ? listedCheckboxInfo.isChecked
      : false;
    const isListedActive = listedCheckboxInfo
      ? listedCheckboxInfo.isActive
      : false;
    const listedCount = listedCheckboxInfo ? listedCheckboxInfo.count : 0;

    // 非上場企業の情報
    const unlistedCheckboxInfo = baseUnlistedCodes
      .map(code => this.buildCheckboxInfo(code))
      .filter(info => !isEmpty(info));

    const checkboxGroups = [
      {
        parentCheckbox: {
          label: "非上場"
        },
        childCheckbox: [...unlistedCheckboxInfo]
      }
    ];

    // 海外地域別の情報
    const overseasAreaInfo = overseasAreaCodes
      .map(code => this.buildCheckboxInfo(code))
      .filter(info => !isEmpty(info));
    // 海外のチェックボックス
    const overseasCheckboxInfo = this.buildOverseasCheckboxInfo(
      overseasCorpCode,
      overseasAreaInfo
    );
    const isOverseasChecked = overseasCheckboxInfo
      ? overseasCheckboxInfo.isChecked
      : false;
    const isOverseasIndeterminate = overseasCheckboxInfo
      ? overseasCheckboxInfo.isAreaIndeterminate
      : false;
    const isOverseasActive = overseasCheckboxInfo
      ? overseasCheckboxInfo.isActive
      : false;
    const overseasCount = overseasCheckboxInfo ? overseasCheckboxInfo.count : 0;

    // 海外非上場地域別の情報
    const unlistedOverseasAreaInfo = unlistedOverseasAreaCodes
      .map(code => this.buildCheckboxInfo(code))
      .filter(info => !isEmpty(info));
    // 海外上場のチェックボックス
    const unlistedOverseasCheckboxInfo = !this.props.unlistedOverseasHide
      ? this.buildOverseasCheckboxInfo(
          unlistedOverseasCorpCode,
          unlistedOverseasAreaInfo
        )
      : undefined;
    const isUnlistedOverseasChecked = unlistedOverseasCheckboxInfo
      ? unlistedOverseasCheckboxInfo.isChecked
      : false;
    const isUnlistedOverseasIndeterminate = unlistedOverseasCheckboxInfo
      ? unlistedOverseasCheckboxInfo.isAreaIndeterminate
      : false;
    const isUnlistedOverseasActive = unlistedOverseasCheckboxInfo
      ? unlistedOverseasCheckboxInfo.isActive
      : false;
    const unlistedOverseasCount = unlistedOverseasCheckboxInfo
      ? unlistedOverseasCheckboxInfo.count
      : 0;

    const allCount =
      checkboxGroups.reduce((acc, cur) => {
        const childTotalCount = cur.childCheckbox.reduce(
          (acc, cur) => acc + cur.count,
          0
        );
        return acc + childTotalCount;
      }, 0) +
      listedCount +
      overseasCount +
      unlistedOverseasCount;

    const isAllChecked = checkboxGroups.every(
      checkboxGroup =>
        checkboxGroup.childCheckbox
          .filter(({ isActive }) => isActive)
          .every(({ isChecked }) => isChecked) &&
        isListedChecked &&
        (overseasCheckboxInfo ? isOverseasChecked : true) &&
        (unlistedOverseasCheckboxInfo ? isUnlistedOverseasChecked : true)
    );

    const isAllActive = !isEmpty(
      checkboxGroups.find(
        checkboxGroup =>
          checkboxGroup.childCheckbox.some(({ isActive }) => isActive) ||
          isListedActive ||
          isOverseasActive ||
          isUnlistedOverseasActive
      )
    );

    const isAllIndeterminate =
      !isAllChecked &&
      (checkboxGroups.some(checkboxGroup =>
        checkboxGroup.childCheckbox.some(({ isChecked }) => isChecked)
      ) ||
        isListedChecked ||
        isOverseasChecked ||
        isUnlistedOverseasChecked);

    // 地域別の情報
    const areaInfo = areaCodes
      .map(code =>
        this.buildAreaCheckboxInfo(
          code,
          (isOverseasChecked ||
            isOverseasIndeterminate ||
            this.state.isCheckedListedOverseaCorp) &&
            isOverseasActive,
          (isUnlistedOverseasChecked ||
            isUnlistedOverseasIndeterminate ||
            this.state.isCheckedUnlistedOverseaCorp) &&
            isUnlistedOverseasActive
        )
      )
      .filter(info => !isEmpty(info));

    const areaAllCount = this.props.shouldShowCount
      ? areaInfo.reduce((acc, cur) => acc + cur.count, 0)
      : "";

    const isAreaAllChecked = isEmpty(areaInfo)
      ? false
      : areaInfo.every(({ isChecked }) => isChecked);

    const isAreaAllActive = isEmpty(areaInfo)
      ? false
      : areaInfo.some(({ isActive }) => isActive);

    const isAreaAllIndeterminate =
      !isAreaAllChecked &&
      (isEmpty(areaInfo) ? false : areaInfo.some(({ isChecked }) => isChecked));

    return (
      <div className={styles.component}>
        {this.props.label && (
          <span className={styles.label}>{this.props.label}</span>
        )}
        <div
          ref={this.corpTypeWrapperRef}
          className={cn(styles.title)}
          onClick={this.openCorpTypeDropDown}
          data-testid="Common-CorpTypeSourceSelector-Implementation-corpTypeWrapper"
        >
          <span>{this.props.corpTypeTitle}</span>
          <span className={styles.icon} />
        </div>
        <div className={`${this.state.isCorpTypeDropDownOpen && styles.hover}`}>
          {!isEmpty(unlistedCheckboxInfo) && (
            <div
              className={styles.dropDownContainer}
              ref={this.corpTypeDropdDownRef}
            >
              <CorpTypeDropDown
                checkboxGroups={checkboxGroups}
                listedCheckboxInfo={listedCheckboxInfo}
                overseasCheckboxInfo={overseasCheckboxInfo}
                unlistedOverseasCheckboxInfo={unlistedOverseasCheckboxInfo}
                overseasCorpCodes={overseasCorpCodes}
                unlistedOverseasCorpCodes={unlistedOverseasCorpCodes}
                allCount={this.props.shouldShowCount ? allCount : ""}
                isAllActive={isAllActive}
                isAllChecked={isAllChecked}
                isAllIndeterminate={isAllIndeterminate}
                onChangeAllCheckbox={this.onChangeAllCheckbox}
                onChangeParentCheckbox={this.onChangeParentCheckbox}
                onChangeChildCheckbox={this.onChangeChildCheckbox}
                shouldShowCount={this.props.shouldShowCount}
              />
            </div>
          )}
        </div>
        {this.isShowOverseasArea(isAreaAllChecked || isAreaAllIndeterminate) &&
          !isEmpty(areaInfo) && (
            <>
              <div
                ref={this.overseasAreaWrapperRef}
                className={cn(styles.title, {
                  [styles.isApplied]: !isAllChecked
                })}
                onClick={this.openOverseasAreaDropDown}
                data-testid="Common-CorpTypeSourceSelector-Implementation-overseasAreaWrapper"
              >
                <span>地域（海外）</span>
                <span className={styles.icon} />
              </div>
              <div
                className={`${this.state.isOverseasAreaDropDownOpen &&
                  styles.hover}`}
              >
                <div
                  className={styles.dropDownContainer}
                  ref={this.overseasAreaDropdDownRef}
                >
                  <OverseasAreaDropDown
                    areaInfo={areaInfo}
                    allCount={areaAllCount}
                    isAllActive={isAreaAllActive}
                    isAllChecked={isAreaAllChecked}
                    isAllIndeterminate={isAreaAllIndeterminate}
                    onChangeAllCheckbox={this.onChangeAreaAllCheckbox}
                    onChangeChildCheckbox={this.onChangeAreaCheckbox}
                  />
                </div>
              </div>
            </>
          )}
      </div>
    );
  }
}

SourceSelector.defaultProps = defaultProps;
export default SourceSelector;
