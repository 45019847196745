import React, { useRef } from "react";
import styles from "./style.scss";
import IconImage from "images/icon/arrow_right_circle.svg";
import { CorporationTable } from "../../CorporationTable";
import EmptyView from "components/Common/EmptyView";
import { isEmpty } from "utils/helper/common";
import LoadingSpinner from "components/Common/LoadingSpinner";

interface Collection {
  binderId: string;
  caption: string;
}

interface Corp {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
  dataExistsFlg?: boolean;
}

interface Props {
  collections: Collection[];
  corporations: Corp[];
  selectedCorp: Corp[];
  maxSelectableNumber: number;
  isLoading: boolean;
  onSelectCollection: (id: string) => void;
  onSelectCorp: (corps: Corp[]) => void;
}

export const Collection: React.FC<Props> = ({
  collections,
  corporations,
  selectedCorp,
  maxSelectableNumber,
  isLoading,
  onSelectCollection,
  onSelectCorp
}) => {
  const isInit = useRef(true);
  const list = corporations.filter(item => item.dataExistsFlg === true);

  return (
    <div className={styles.container}>
      <div className={styles.collections}>
        {collections.map((collection, index) => (
          <div
            className={styles.row}
            key={`collection-${collection.binderId}-${index}`}
            onClick={() => {
              onSelectCollection(collection.binderId);
              isInit.current = false;
            }}
          >
            {collection.caption}
          </div>
        ))}
      </div>
      <div className={styles.arrow}>
        <div className={styles.icon}>
          <img src={IconImage} />
        </div>
      </div>
      <div className={styles.corporations}>
        {isEmpty(corporations) || isLoading ? (
          <div className={styles.emptyContainer}>
            {isLoading ? (
              <div className={styles.loading}>
                <LoadingSpinner size="L" />
              </div>
            ) : (
              !isInit.current && <EmptyView isSearchResult={false} />
            )}
          </div>
        ) : (
          <CorporationTable
            items={list}
            selectedCorp={selectedCorp}
            maxSelectableNumber={maxSelectableNumber}
            onSelect={onSelectCorp}
          />
        )}
      </div>
    </div>
  );
};
