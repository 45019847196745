import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useReduxState } from "hooks/useReduxState";
import { Response, DeleteResponse } from "models/api/binder/List/Corporation";
import { Response as ResponseTabList } from "models/api/binder/List";
import { request } from "utils/apiClient";
import {
  setCorporations,
  setBinderCategoryInfo,
  setCorporationDownload,
  setBinderInfo,
  setCorporationsLoading
} from "modules/binders/item/actions";
import { actionCreators as comparisonCorpResultActionCreators } from "modules/tool/comparison/corp/result-data";
import { actionCreators as comparisonCorpActionCreators } from "modules/tool/comparison/corp";
import { pick } from "lodash";
import { download } from "utils/helper/download";

export const useCorporationItem = () => {
  const corporationList = useReduxState(s => s.binders.item.corporations);
  const corporations = corporationList.filter(item => item.dataExistsFlg);
  const downloadParams = useReduxState(s => s.binders.item.corporationDownload);
  const isLoading = useReduxState(s => s.binders.item.loading);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const dispatch = useDispatch();

  const fetchCorporationList = useCallback(
    async (binderId: string) => {
      dispatch(setCorporationsLoading(true));
      await Promise.all([
        request<Response>("/binder/list/corp", { binderId }).then(response => {
          dispatch(setCorporations(response.corpList));
          dispatch(
            setCorporationDownload(
              pick(response, [
                "dlpropriety",
                "dlxls",
                "dlxlspath",
                "dlcsv",
                "dlcsvpath"
              ])
            )
          );
        }),
        request<ResponseTabList>("/binder/list", { binderId }).then(
          response => {
            dispatch(setBinderCategoryInfo(response.lists));
            dispatch(setBinderInfo(response.binderInfo));
          }
        )
      ]);
      dispatch(setCorporationsLoading(false));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const [selectedList, setSelectedList] = useState<string[]>([]);
  const onChangeCheck = (cardId: string, check: boolean) => {
    setSelectedList(prev => {
      return check ? [cardId, ...prev] : prev.filter(item => item !== cardId);
    });
  };
  const onChangeCheckAll = (check: boolean) => {
    setSelectedList(() => {
      if (!check) return [];
      return corporations.map(item => item.cardId);
    });
  };

  const clearCheck = () => {
    onChangeCheckAll(false);
  };

  const deleteCorporations = () => {
    setIsLoadingDelete(true);
    return request<DeleteResponse>(
      "/binder/card/delete",
      { cardId: selectedList.join(",") },
      "POST"
    ).finally(() => setIsLoadingDelete(false));
  };

  const moveComparisonCorporation = () => {
    const corpCodes = selectedList.map(cardId => {
      const corporation = corporations.find(item => item.cardId === cardId);
      return corporation ? corporation.corpCode : "";
    });
    dispatch(comparisonCorpResultActionCreators.clearResultData());
    dispatch(
      comparisonCorpActionCreators.setDefaultSelectedCodes(corpCodes.join(","))
    );
  };
  const downloadPpt = async (item: string) => {
    const params = {
      key: item,
      keyBinaly: item,
      keyBinaryAttach: item
    };
    download({ params, key: "ppt", type: "ppt" }, "/binder/industry");
  };

  return {
    corporations,
    fetchCorporationList,
    isLoading,
    isLoadingDelete,
    selectedList,
    onChangeCheck,
    onChangeCheckAll,
    clearCheck,
    deleteCorporations,
    moveComparisonCorporation,
    downloadParams,
    downloadPpt
  };
};
