import React, { useState, useEffect } from "react";
import styles from "./style.scss";
import TextInput from "components/Common/TextInput";
import Checkbox from "components/Common/Checkbox";
import {
  BinderActionType,
  BinderInfo,
  Media as MediaType,
  Complements,
  ComplementsError,
  Item
} from "models/BinderItem";
import {
  useSearchList,
  useSelectMenu,
  useRecentList,
  useCollection
} from "./hook";
import { setSeletedCorpItems } from "modules/binders/item/actions";
import Button from "components/Common/Button/Text";
import cn from "classnames";
import { BinderRequestParams } from "features/BinderPage/Common/BinderActionModal/hook";
import { useModalUtil } from "hooks/useModalUtil";
import { FormulaSearch } from "features/FormulaSearch";
import { request } from "utils/apiClient";
import { validateKeywords } from "features/FormulaSearch/handlers";
import Radio from "components/Common/Radio";
import { useReduxState } from "hooks/useReduxState";
import { ToolTip } from "features/ToolTip";

import openIcon from "images/icon/accordion_open.svg";
import closeIcon from "images/icon/accordion_close.svg";
import { trackAction } from "utils/helper/analytics";
import PayTag from "components/Common/PayTag";
import { RawOperand } from "models/GlobalSearch";
import { convertSearchKeyword } from "utils/helper/searchTag";
import { isEmpty, trimAllSpace } from "utils/helper/common";
import { CompanyModal } from "features/Modal/CompanyModal";
import { useDispatch } from "react-redux";

const mediaTypes = ["新聞", "雑誌", "NIKKEI Prime", "英文記事", "その他"];

interface Props {
  menuType: BinderActionType;
  binderInfo: BinderInfo | undefined;
  onAction: (params: BinderRequestParams) => void;
  handleSave?: (params: BinderRequestParams) => void;
  isSaveLoading?: boolean;
  isOpenCompanyModal: boolean;
  setOpenCompanyModal: (isOpen: boolean) => void;
  error: {
    definition: string;
    background: string;
    task: string;
    technology: string;
    topics: string;
    regulation: string;
    other: string;
  };
  setIsOpenHelp: (isOpen: boolean) => void;
  setIsOpenDetailModal: (isOpen: boolean) => void;
  setOtherError: (value: string) => void;
}

export const BinderForm: React.FunctionComponent<Props> = props => {
  const authority = useReduxState(
    state => state.auth.saved.collectionAuthority
  );

  const selectedItems = useReduxState(
    state => state.binders.item.selectedCorps
  );
  const dispatch = useDispatch();

  const setOtherError = props.setOtherError;
  const [sharedFlg, setSharedFlg] = useState(false);
  const [publishedFlg, setPublishedFlg] = useState(false);
  const [keywordFeedFlg, setKeywordFeedFlg] = useState(true);
  const [searchType, setSearchType] = useState("0");
  const [companyFeedFlg, setCompanyFeedFlg] = useState(true);
  const [caption, setCaption] = useState("");
  const [description, setDescription] = useState("");
  const [definition, setDefinition] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [captionValidation, setCaptionValidation] = useState("");
  const [descriptionValidation, setDescriptionValidation] = useState("");
  const [definitionError, setDefinitionError] = useState<boolean>(false);
  const [background, setBackground] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [backgroundError, setBackgroundError] = useState<boolean>(false);
  const [task, setTask] = useState<Complements[]>([{ text: "", label: "" }]);
  const [taskError, setTaskError] = useState<boolean>(false);
  const [technology, setTechnology] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [technologyError, setTechnologyError] = useState<ComplementsError[]>(
    []
  );
  const [topics, setTopics] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [topicsError, setTopicsError] = useState<ComplementsError[]>([]);
  const [regulation, setRegulation] = useState<Complements[]>([
    { text: "", label: "" }
  ]);
  const [regulationError, setRegulationError] = useState<ComplementsError[]>(
    []
  );
  const [mediaError, setMediaError] = useState("");
  const [keywordError, setKeywordError] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [keyword, setKeyword] = useState<string>("");
  const [keywordLabel, setKeywordLabel] = useState<string>("");

  const [rawOperand, setRawOperand] = useState<RawOperand>({
    and: props.binderInfo?.andKeyword || "",
    or: props.binderInfo?.orKeyword || "",
    not: props.binderInfo?.notKeyword || ""
  });
  const [mediaList, setMediaList] = useState<MediaType[]>([]);

  const [openEditComplement, setOpenEditComplement] = useState(false);

  const { removeKeyEventListener, addKeyEventListener } = useModalUtil();
  const onlyNotErrorMsg =
    "「すべてを含む」もしくは「いずれかを含む」にキーワードを設定してください";

  const isShowing = {
    caption:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate,
    description:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate,
    keyword:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate,
    share:
      props.menuType === "new" ||
      props.menuType === "clone_show_new" ||
      props.menuType === "share",
    media:
      props.menuType === "edit" ||
      props.menuType === "editItem" ||
      props.menuType === "new",
    publish: props.menuType === "publish",
    editComplement:
      props.menuType !== "share" &&
      props.menuType !== "publish" &&
      authority?.complemntUpdate
  };

  const trackMediaCheck = (
    collectionId: string,
    mediaCode: string,
    checked: boolean,
    newCollection: boolean
  ) => {
    trackAction("clickMediaCheckBox", {
      collectionId,
      mediaCode,
      checked,
      newCollection
    });
  };

  useEffect(() => {
    setDefinitionError(definition[0].text.length > 1500);
    setBackgroundError(background[0].text.length > 1500);
    setTaskError(task[0].text.length > 1500);
  }, [background, definition, task]);

  useEffect(() => {
    setCaption(props.binderInfo?.caption ?? "");
    setDescription(props.binderInfo?.description ?? "");
    setDefinition(
      props.binderInfo?.definition[0]
        ? props.binderInfo?.definition
        : [{ text: "", label: "" }]
    );
    setBackground(
      props.binderInfo?.background[0]
        ? props.binderInfo?.background
        : [{ text: "", label: "" }]
    );
    setTask(
      props.binderInfo?.task[0]
        ? props.binderInfo?.task
        : [{ text: "", label: "" }]
    );
    setTechnology(
      props.binderInfo?.technology[0]
        ? props.binderInfo?.technology
        : [{ text: "", label: "" }]
    );
    setTopics(
      props.binderInfo?.topics[0]
        ? props.binderInfo?.topics
        : [{ text: "", label: "" }]
    );
    setRegulation(
      props.binderInfo?.regulation[0]
        ? props.binderInfo?.regulation
        : [{ text: "", label: "" }]
    );
    setSearchType(
      props.binderInfo?.searchType ? props.binderInfo.searchType : "0"
    );
    if (props.binderInfo) {
      if (!isEmpty(props.binderInfo.query)) {
        setKeyword(props.binderInfo.query);
        setKeywordLabel(props.binderInfo.query);
      } else {
        setKeywordLabel(
          convertSearchKeyword({
            and: props.binderInfo?.andKeyword || "",
            or: props.binderInfo?.orKeyword || "",
            not: props.binderInfo?.notKeyword || ""
          })
        );
      }
    }
    const hasKeyword =
      props.binderInfo?.andKeyword !== "" ||
      props.binderInfo?.orKeyword !== "" ||
      props.binderInfo?.notKeyword !== "" ||
      props.binderInfo?.query !== "";
    setKeywordFeedFlg(hasKeyword);
    if (props.binderInfo?.includeRelatedCorp) {
      setCompanyFeedFlg(props.binderInfo.includeRelatedCorp === "1");
    }
    setSharedFlg(props.binderInfo?.sharedFlg ?? false);
    if (props.binderInfo?.publicStatus) {
      setPublishedFlg(props.binderInfo?.publicStatus === "1");
    }

    if (props.binderInfo?.corpList) {
      dispatch(
        setSeletedCorpItems(
          props.binderInfo.corpList.map(corp => {
            return {
              companyName: corp.corpName,
              companyCode: corp.corpCode,
              stkCode: corp.stkCode,
              source: corp.source,
              baseOptFlg: corp.baseOptFlg
            };
          })
        )
      );
    }
  }, [props.binderInfo, dispatch]);

  useEffect(() => {
    if (caption.length > 255) {
      setCaptionValidation("コレクション名は255文字以内で入力してください");
    } else {
      setCaptionValidation("");
    }
  }, [caption]);

  useEffect(() => {
    if (description.length > 255) {
      setDescriptionValidation("説明は255文字以内で入力してください");
    } else {
      setDescriptionValidation("");
    }
  }, [description]);

  useEffect(() => {
    setKeywordError("");
  }, [keyword]);

  useEffect(() => {
    setKeywordError("");
  }, [rawOperand]);

  useEffect(() => {
    if (!keywordFeedFlg) {
      setKeywordError("");
    }
    if (!companyFeedFlg) {
      setCompanyError("");
    }
  }, [companyFeedFlg, keywordFeedFlg, searchType]);

  useEffect(() => {
    setCompanyError("");
  }, [selectedItems]);

  useEffect(() => {
    if (mediaList.findIndex(media => media.checked) > -1) {
      setMediaError("");
    }
  }, [mediaList]);

  const fetchMediaList = async () => {
    if (
      (props.menuType === "edit" || props.menuType === "editItem") &&
      props.binderInfo?.mediaList
    ) {
      setMediaList(props.binderInfo.mediaList);
      return;
    }

    const response = await request<{ mediaList: MediaType[] }>(
      "/binder/article-media"
    );
    setMediaList(response.mediaList);
  };

  const changeText = (witchBlock: string, value: string, index: number) => {
    switch (witchBlock) {
      case "defintion":
        const definitionSets = definition.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: definition[index].label
            };
          }
          return setValue;
        });
        setDefinition(definitionSets);
        break;
      case "background":
        const backgroundSets = background.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: background[index].label
            };
          }
          return setValue;
        });
        setBackground(backgroundSets);
        break;
      case "task":
        const taskSets = task.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: task[index].label
            };
          }
          return setValue;
        });
        setTask(taskSets);
        break;
      case "technology":
        const technologySets = technology.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: technology[index].label
            };
          }
          return setValue;
        });
        setTechnology(technologySets);
        break;
      case "topics":
        const topicSets = topics.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: topics[index].label
            };
          }
          return setValue;
        });
        setTopics(topicSets);
        break;
      case "regulation":
        const regulationSets = regulation.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: value,
              label: regulation[index].label
            };
          }
          return setValue;
        });
        setRegulation(regulationSets);
        break;
    }
  };

  const changeTitle = (witchBlock: string, value: string, index: number) => {
    switch (witchBlock) {
      case "defintion":
        const definitionSets = definition.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: definition[index].text,
              label: value
            };
          }
          return setValue;
        });
        setDefinition(definitionSets);
        break;
      case "background":
        const backgroundSets = background.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: background[index].text,
              label: value
            };
          }
          return setValue;
        });
        setBackground(backgroundSets);
        break;
      case "task":
        const taskSets = task.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: task[index].text,
              label: value
            };
          }
          return setValue;
        });
        setTask(taskSets);
        break;
      case "technology":
        const technologySets = technology.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: technology[index].text,
              label: value
            };
          }
          return setValue;
        });
        setTechnology(technologySets);
        break;
      case "topics":
        const topicSets = topics.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: topics[index].text,
              label: value
            };
          }
          return setValue;
        });
        setTopics(topicSets);
        break;
      case "regulation":
        const regulationSets = regulation.map((setValue, setIndex) => {
          if (setIndex === index) {
            return {
              text: regulation[index].text,
              label: value
            };
          }
          return setValue;
        });
        setRegulation(regulationSets);
        break;
    }
  };

  const addBlock = (witchBlock: string) => {
    switch (witchBlock) {
      case "defintion":
        const definitionSets = Object.assign([], definition);
        definitionSets.push({ text: "", label: "" });
        setDefinition(definitionSets);
        break;
      case "background":
        const backgroundSets = Object.assign([], background);
        backgroundSets.push({ text: "", label: "" });
        setBackground(backgroundSets);
        break;
      case "task":
        const taskSets = Object.assign([], task);
        taskSets.push({ text: "", label: "" });
        setTask(taskSets);
        break;
      case "technology":
        const technologySets = Object.assign([], technology);
        technologySets.push({ text: "", label: "" });
        setTechnology(technologySets);
        break;
      case "topics":
        const topicSets = Object.assign([], topics);
        topicSets.push({ text: "", label: "" });
        setTopics(topicSets);
        break;
      case "regulation":
        const regulationSets = Object.assign([], regulation);
        regulationSets.push({ text: "", label: "" });
        setRegulation(regulationSets);
        break;
    }
  };

  const removeBlock = (witchBlock: string) => {
    switch (witchBlock) {
      case "defintion":
        if (definition.length <= 1) {
          setDefinition([{ text: "", label: "" }]);
        } else {
          const definitionSets = Object.assign([], definition);
          definitionSets.pop();
          setDefinition(definitionSets);
        }
        break;
      case "background":
        if (background.length <= 1) {
          setBackground([{ text: "", label: "" }]);
        } else {
          const backgroundSets = Object.assign([], background);
          backgroundSets.pop();
          setBackground(backgroundSets);
        }
        break;
      case "task":
        if (task.length <= 1) {
          setTask([{ text: "", label: "" }]);
        } else {
          const taskSets = Object.assign([], task);
          taskSets.pop();
          setTask(taskSets);
        }
        break;
      case "technology":
        if (technology.length <= 1) {
          setTechnology([{ text: "", label: "" }]);
        } else {
          const technologySets = Object.assign([], technology);
          technologySets.pop();
          setTechnology(technologySets);
        }
        break;
      case "topics":
        if (topics.length <= 1) {
          setTopics([{ text: "", label: "" }]);
        } else {
          const topicSets = Object.assign([], topics);
          topicSets.pop();
          setTopics(topicSets);
        }
        break;
      case "regulation":
        if (regulation.length <= 1) {
          setRegulation([{ text: "", label: "" }]);
        } else {
          const regulationSets = Object.assign([], regulation);
          regulationSets.pop();
          setRegulation(regulationSets);
        }
        break;
    }
  };

  useEffect(() => {
    if (isShowing.media) {
      fetchMediaList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFocus = () => {
    // テキストフィールドフォーカス中はモーダル閉じるキーイベントを解除
    removeKeyEventListener();
  };

  const onBlur = () => {
    // モーダル閉じるキーイベントを貼り直す
    addKeyEventListener();
  };

  const defaultUpperLimit = {
    label: "1500",
    text: "1500"
  };

  const captionProps = {
    caption,
    setCaption,
    error: captionValidation
  };

  const descriptionProps = {
    description,
    setDescription,
    error: descriptionValidation,
    onFocus,
    onBlur
  };

  const definitionProps = {
    definition,
    error: props.error.definition,
    onFocus,
    onBlur,
    changeText,
    definitionError,
    textLimit: props.binderInfo?.upperLimit.definition.text
      ? props.binderInfo?.upperLimit.definition.text
      : defaultUpperLimit.text
  };

  const backgroundProps = {
    background,
    error: props.error.background,
    onFocus,
    onBlur,
    changeText,
    backgroundError,
    textLimit: props.binderInfo?.upperLimit.background.text
      ? props.binderInfo?.upperLimit.background.text
      : defaultUpperLimit.text
  };

  const taskProps = {
    task,
    error: props.error.task,
    onFocus,
    onBlur,
    changeText,
    taskError,
    textLimit: props.binderInfo?.upperLimit.task.text
      ? props.binderInfo?.upperLimit.task.text
      : defaultUpperLimit.text
  };

  const technologyProps = {
    technology,
    error: props.error.technology,
    onFocus,
    onBlur,
    changeText,
    changeTitle,
    addBlock,
    removeBlock,
    technologyError,
    setTechnologyError,
    textLimit: props.binderInfo?.upperLimit.technology.text
      ? props.binderInfo?.upperLimit.technology.text
      : defaultUpperLimit.text,
    titleLimit: props.binderInfo?.upperLimit.technology.label
      ? props.binderInfo?.upperLimit.technology.label
      : defaultUpperLimit.label
  };

  const topicsProps = {
    topics,
    error: props.error.topics,
    onFocus,
    onBlur,
    changeText,
    changeTitle,
    addBlock,
    removeBlock,
    topicsError,
    setTopicsError,
    textLimit: props.binderInfo?.upperLimit.topics.text
      ? props.binderInfo?.upperLimit.topics.text
      : defaultUpperLimit.text,
    titleLimit: props.binderInfo?.upperLimit.topics.label
      ? props.binderInfo?.upperLimit.topics.label
      : defaultUpperLimit.label
  };

  const regulationProps = {
    regulation,
    error: props.error.regulation,
    onFocus,
    onBlur,
    changeText,
    changeTitle,
    addBlock,
    removeBlock,
    regulationError,
    setRegulationError,
    textLimit: props.binderInfo?.upperLimit.regulation.text
      ? props.binderInfo?.upperLimit.regulation.text
      : defaultUpperLimit.text,
    titleLimit: props.binderInfo?.upperLimit.regulation.label
      ? props.binderInfo?.upperLimit.regulation.label
      : defaultUpperLimit.label
  };

  const keywordProps = {
    keywordFeedFlg,
    setKeywordFeedFlg,
    searchType,
    setSearchType,
    rawOperand,
    setRawOperand,
    companyFeedFlg,
    setCompanyFeedFlg,
    keyword,
    setKeyword,
    menuType: props.menuType,
    setIsOpenHelp: props.setIsOpenHelp,
    keywordError,
    keywordLabel,
    setKeywordLabel,
    setKeywordError,
    isShowing,
    onlyNotErrorMsg
  };

  const corpSearchProps = {
    selectedItems,
    companyError,
    isOpenCompanyModal: props.isOpenCompanyModal,
    setOpenCompanyModal: props.setOpenCompanyModal,
    menuType: props.menuType
  };

  const shareProps = {
    sharedFlg,
    setSharedFlg
  };

  const publishProps = {
    publishedFlg,
    setPublishedFlg
  };

  const mediaProps = {
    keywordFeedFlg,
    keyword,
    rawOperand,
    mediaList,
    setKeywordError,
    isShowing,
    onlyNotErrorMsg,
    onChange: (media: MediaType) => {
      setMediaList(
        mediaList.map(line => {
          const item = { ...line };
          if (item.value !== media.value) return item;
          item.checked = !item.checked;
          trackMediaCheck(
            props.menuType !== "new" && props.binderInfo
              ? props.binderInfo.binderId
              : "新規",
            item.value,
            item.checked,
            props.menuType === "new"
          );
          return item;
        })
      );
    },
    onChangeList: (medium: MediaType[], checked: boolean) => {
      setMediaList(
        mediaList.map(line => {
          const item = { ...line };
          // 非活性の場合は操作をしない
          if (item.baseOptFlg === "9") return item;
          const mediaIndex = medium.findIndex(
            media => media.value === line.value
          );
          if (mediaIndex < 0) return item;
          item.checked = checked;
          trackMediaCheck(
            props.menuType !== "new" && props.binderInfo
              ? props.binderInfo.binderId
              : "新規",
            item.value,
            item.checked,
            props.menuType === "new"
          );

          return item;
        })
      );
    },
    setIsOpenDetailModal: props.setIsOpenDetailModal,
    mediaError,
    menuType: props.menuType
  };

  const reformComplements = () => {
    const definitionText: string[] = [];
    const definitionLabel: string[] = [];
    const backgroundText: string[] = [];
    const backgroundLabel: string[] = [];
    const taskText: string[] = [];
    const taskLabel: string[] = [];
    const technologyText: string[] = [];
    const technologyLabel: string[] = [];
    const topicsText: string[] = [];
    const topicsLabel: string[] = [];
    const regulationText: string[] = [];
    const regulationLabel: string[] = [];
    definition.map(item => {
      definitionText.push(item.text);
      definitionLabel.push(item.label);
    });
    background.map(item => {
      backgroundText.push(item.text);
      backgroundLabel.push(item.label);
    });
    task.map(item => {
      taskText.push(item.text);
      taskLabel.push(item.label);
    });
    technology.map(item => {
      technologyText.push(item.text);
      technologyLabel.push(item.label);
    });
    topics.map(item => {
      topicsText.push(item.text);
      topicsLabel.push(item.label);
    });
    regulation.map(item => {
      regulationText.push(item.text);
      regulationLabel.push(item.label);
    });
    return {
      definitionText: definitionText.join("|"),
      definitionLabel: definitionLabel.join("|"),
      backgroundText: backgroundText.join("|"),
      backgroundLabel: backgroundLabel.join("|"),
      taskText: taskText.join("|"),
      taskLabel: taskLabel.join("|"),
      technologyText: technologyText.join("|"),
      technologyLabel: technologyLabel.join("|"),
      topicsText: topicsText.join("|"),
      topicsLabel: topicsLabel.join("|"),
      regulationText: regulationText.join("|"),
      regulationLabel: regulationLabel.join("|")
    };
  };

  const binderId = props.binderInfo?.binderId ?? "";

  const isComplementsError = (error: ComplementsError) => {
    return error.labelError || error.textError;
  };

  const saveValidation = () => {
    // 検証を開始する前に現在のエラー情報をリセットする
    setCompanyError("");
    setKeywordError("");
    setMediaError("");
    setOtherError("");

    const mediaValidation = () => {
      if (!isShowing.media) return true;

      const checkMedia = isShowing.media
        ? mediaList.filter(item => item.checked)
        : [];
      return checkMedia.length > 0;
    };

    const checkKeywordsLength = (
      keyword: {
        and: string;
        or: string;
        not: string;
      },
      query: string
    ) => {
      return (
        trimAllSpace(keyword.and).length +
          trimAllSpace(keyword.or).length +
          trimAllSpace(keyword.not).length <=
          500 || trimAllSpace(query).length <= 500
      );
    };

    let validate = true;

    if (!mediaValidation()) {
      setMediaError("フィード対象の媒体を指定してください");
      validate = false;
    }

    const validatedKeywords = validateKeywords(
      keyword,
      rawOperand,
      !isEmpty(rawOperand.and) ||
        !isEmpty(rawOperand.or) ||
        !isEmpty(rawOperand.not)
    );

    if (
      isShowing.keyword &&
      keywordFeedFlg &&
      isEmpty(validatedKeywords.rawText) &&
      isEmpty(validatedKeywords.andKeyword) &&
      isEmpty(validatedKeywords.orKeyword) &&
      isEmpty(validatedKeywords.notKeyword)
    ) {
      setKeywordError("キーワードを指定してください");
      validate = false;
    }

    if (
      isShowing.keyword &&
      keywordFeedFlg &&
      !isEmpty(validatedKeywords.notKeyword) &&
      isEmpty(validatedKeywords.andKeyword) &&
      isEmpty(validatedKeywords.orKeyword)
    ) {
      setKeywordError(onlyNotErrorMsg);
      validate = false;
    }

    if (
      !checkKeywordsLength(
        {
          and: validatedKeywords.andKeyword,
          or: validatedKeywords.orKeyword,
          not: validatedKeywords.notKeyword
        },
        validatedKeywords.rawText
      )
    ) {
      setKeywordError(
        "コレクションキーワードは500文字以内で入力してください。"
      );
      validate = false;
    }

    if (isShowing.keyword && companyFeedFlg && selectedItems.length === 0) {
      setCompanyError("企業を指定してください");
      validate = false;
    }

    return validate;
  };

  const clickSave = () => {
    trackAction("myCollectionSave", {
      type: props.menuType === "new" ? "コレクション作成" : "保存"
    });
    // バリデーションチェック
    if (!saveValidation()) {
      return;
    }

    const complementsReform = reformComplements();
    const validatedKeywords = validateKeywords(
      keyword,
      rawOperand,
      !isEmpty(rawOperand.and) ||
        !isEmpty(rawOperand.or) ||
        !isEmpty(rawOperand.not)
    );

    const params = {
      corpCode:
        selectedItems && selectedItems.length > 0
          ? selectedItems.map(item => item.companyCode).join("|")
          : "",
      binderId: props.menuType !== "new" ? binderId : "",
      caption,
      description,
      definition: complementsReform.definitionText,
      definitionLabel: complementsReform.definitionLabel,
      background: complementsReform.backgroundText,
      backgroundLabel: complementsReform.backgroundLabel,
      task: complementsReform.taskText,
      taskLabel: complementsReform.taskLabel,
      technology: complementsReform.technologyText,
      technologyLabel: complementsReform.technologyLabel,
      topics: complementsReform.topicsText,
      topicsLabel: complementsReform.topicsLabel,
      regulation: complementsReform.regulationText,
      regulationLabel: complementsReform.regulationLabel,
      andKeyword: keywordFeedFlg ? validatedKeywords.andKeyword : "",
      orKeyword: keywordFeedFlg ? validatedKeywords.orKeyword : "",
      notKeyword: keywordFeedFlg ? validatedKeywords.notKeyword : "",
      includeRelatedCorp: companyFeedFlg ? "1" : "0",
      setShare: sharedFlg,
      setPublish: publishedFlg,
      searchType,
      query: keywordFeedFlg ? validatedKeywords.rawText : "",
      mediaList: isShowing.media
        ? mediaList
            .filter(item => item.checked)
            .map(item => item.value)
            .join(",")
        : ""
    };
    if (props.handleSave) {
      props.handleSave(params);
    } else {
      props.onAction(params);
    }
  };

  return (
    <div
      className={`
        ${cn(styles.component)}
        ${(props.menuType === "new" ||
          props.menuType === "edit" ||
          props.menuType === "editItem") &&
          cn(styles.addAndEditForm)}
      `}
    >
      {props.error.other && (
        <div className={styles.otherError}>{props.error.other}</div>
      )}
      {isShowing.caption && <Caption {...captionProps} />}
      {isShowing.description && <Description {...descriptionProps} />}
      {isShowing.editComplement && (
        <div className={cn(styles.editComplement)}>
          <div
            className={cn(styles.openEditComplement)}
            onClick={() => {
              trackAction("myCollectionEditMoreClick");
              setOpenEditComplement(!openEditComplement);
            }}
            data-testid="BinderPage-Common-BinderForm-openEditComplement"
          >
            <img
              src={openEditComplement ? openIcon : closeIcon}
              className={styles.editComplementIcon}
            />
            <span>補足情報</span>
          </div>
          {openEditComplement && (
            <>
              <Definition {...definitionProps} />
              <Background {...backgroundProps} />
              <Task {...taskProps} />
              <Technology {...technologyProps} />
              <Topics {...topicsProps} />
              <Regulation {...regulationProps} />
            </>
          )}
        </div>
      )}
      {isShowing.keyword && (
        <div>
          <CorpSearch {...corpSearchProps} />
          <Selection {...keywordProps} />
          <Keyword {...keywordProps} />
        </div>
      )}
      {isShowing.media && <Media {...mediaProps} />}
      {isShowing.share && <Share {...shareProps} />}
      {isShowing.publish && <Publish {...publishProps} />}
      <div
        className={styles.buttonWrapper}
        data-testid="features-BinderPage-Common-BinderForm-button"
      >
        <Button
          className={styles.button}
          color="orange"
          onClick={clickSave}
          disabled={
            definitionError ||
            backgroundError ||
            taskError ||
            technologyError.some(isComplementsError) ||
            topicsError.some(isComplementsError) ||
            regulationError.some(isComplementsError) ||
            !isEmpty(captionValidation) ||
            !isEmpty(descriptionValidation) ||
            props.isSaveLoading
          }
          data-testid="BinderPage-Common-BinderForm-actionButton"
        >
          {props.menuType === "new" ? "コレクションを作成" : "保存"}
        </Button>
      </div>
    </div>
  );
};

interface CaptionProps {
  caption: string;
  setCaption: (arg: string) => void;
  error: string;
}

const Caption: React.FunctionComponent<CaptionProps> = ({
  caption,
  setCaption,
  error
}) => (
  <div className={styles.additem}>
    <div className={styles.label}>コレクション名</div>
    <TextInput
      placeholder="名称未設定"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setCaption(event.target.value);
      }}
      value={caption}
      error={error}
    />
  </div>
);

interface DescriptionProps {
  description: string;
  setDescription: (arg: string) => void;
  error: string;
  onFocus: () => void;
  onBlur: () => void;
}

const Description: React.FunctionComponent<DescriptionProps> = ({
  description,
  setDescription,
  error,
  onFocus,
  onBlur
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>説明</div>
    <textarea
      placeholder="コレクションの説明文"
      onChange={event => {
        setDescription(event.target.value);
      }}
      value={description}
      rows={5}
      onFocus={onFocus}
      onBlur={onBlur}
    />
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface DefinitionProps {
  definition: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  definitionError: boolean;
  textLimit: string;
}

const Definition: React.FunctionComponent<DefinitionProps> = ({
  definition,
  error,
  onFocus,
  onBlur,
  changeText,
  definitionError,
  textLimit
}) => (
  <div
    className={cn(styles.additem, { [styles.error]: error !== "" })}
    data-testid="BinderPage-Common-BinderForm-Definition"
  >
    <div className={styles.label}>用語の定義</div>
    <div className={styles.complement}>
      <textarea
        placeholder="コレクションの用語の定義"
        onChange={event => {
          changeText("defintion", event.target.value, 0);
        }}
        value={definition[0].text}
        rows={5}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
    <div className={styles.complementDescription}>
      コレクションの主要なキーワードの定義について簡潔に説明。
    </div>
    {!error && definitionError && (
      <div className={styles.errorMessage}>
        用語の定義は{textLimit}文字以内で入力してください
      </div>
    )}
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface BackgroundProps {
  background: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  backgroundError: boolean;
  textLimit: string;
}

const Background: React.FunctionComponent<BackgroundProps> = ({
  background,
  error,
  onFocus,
  onBlur,
  changeText,
  backgroundError,
  textLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>背景</div>
    <div className={styles.complement}>
      <textarea
        placeholder="コレクションの背景"
        onChange={event => {
          changeText("background", event.target.value, 0);
        }}
        value={background[0].text}
        rows={5}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
    <div className={styles.complementDescription}>
      このトレンドがなぜ重要なのか？社会的・経済的な背景を説明。
    </div>
    {!error && backgroundError && (
      <div className={styles.errorMessage}>
        背景は{textLimit}文字以内で入力してください
      </div>
    )}
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface TaskProps {
  task: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  taskError: boolean;
  textLimit: string;
}

const Task: React.FunctionComponent<TaskProps> = ({
  task,
  error,
  onFocus,
  onBlur,
  changeText,
  taskError,
  textLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>解決する課題</div>
    <div className={styles.complement}>
      <textarea
        placeholder="コレクションの用語の課題"
        onChange={event => {
          changeText("task", event.target.value, 0);
        }}
        value={task[0].text}
        rows={5}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
    <div className={styles.complementDescription}>
      この技術が解決する社会課題についてキーワードを列挙。1つのコレクションに複数のキーワードが紐づく。(
      , ) カンマ区切りで入力。
    </div>
    {!error && taskError && (
      <div className={styles.errorMessage}>
        解決する課題は{textLimit}文字以内で入力してください
      </div>
    )}
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface TechnologyProps {
  technology: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  changeTitle: (witchBlock: string, value: string, index: number) => void;
  addBlock: (witchBlock: string) => void;
  removeBlock: (witchBlock: string) => void;
  technologyError: ComplementsError[];
  setTechnologyError: (error: ComplementsError[]) => void;
  textLimit: string;
  titleLimit: string;
}

const Technology: React.FunctionComponent<TechnologyProps> = ({
  technology,
  error,
  onFocus,
  onBlur,
  changeText,
  changeTitle,
  addBlock,
  removeBlock,
  technologyError,
  setTechnologyError,
  textLimit,
  titleLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>鍵となる技術</div>
    {technology.map((item, index) => {
      return (
        <div className={styles.complement} key={`technology-${index}`}>
          <TextInput
            placeholder="技術のタイトル"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              changeTitle("technology", event.target.value, index);
              technologyError[index]
                ? (technologyError[index].labelError =
                    event.target.value.length > 1500)
                : (technologyError[index] = {
                    labelError: event.target.value.length > 1500,
                    textError: false
                  });
              setTechnologyError(technologyError);
            }}
            value={item.label}
            error={error}
          />
          {!error && technologyError[index]?.labelError && (
            <div className={styles.errorMessage}>
              鍵となる技術のタイトル{index + 1}は{titleLimit}
              文字以内で入力してください
            </div>
          )}
          <textarea
            placeholder="コレクションの用語の鍵となる技術"
            onChange={event => {
              changeText("technology", event.target.value, index);
              technologyError[index]
                ? (technologyError[index].textError =
                    event.target.value.length > 1500)
                : (technologyError[index] = {
                    labelError: false,
                    textError: event.target.value.length > 1500
                  });
              setTechnologyError(technologyError);
            }}
            value={item.text}
            rows={5}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {!error && technologyError[index]?.textError && (
            <div className={styles.errorMessage}>
              鍵となる技術の本文{index + 1}は{textLimit}
              文字以内で入力してください
            </div>
          )}
        </div>
      );
    })}
    <div className={styles.complementDescription}>
      この分野・ジャンルにおいて鍵となるテクノロジーについて列挙し、それぞれ説明。
    </div>
    <div className={styles.complementDescription}>
      技術名と説明文のペアが1つのコレクションに複数紐づく。
    </div>
    <div className={styles.blockTool}>
      <span
        className={styles.addBlock}
        onClick={() => addBlock("technology")}
        data-testid="BinderPage-Common-BinderForm-Technology-addBlock"
      />
      <span
        className={styles.removeBlock}
        onClick={() => removeBlock("technology")}
        data-testid="BinderPage-Common-BinderForm-Technology-removeBlock"
      />
    </div>
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface TopicsProps {
  topics: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  changeTitle: (witchBlock: string, value: string, index: number) => void;
  addBlock: (witchBlock: string) => void;
  removeBlock: (witchBlock: string) => void;
  topicsError: ComplementsError[];
  setTopicsError: (error: ComplementsError[]) => void;
  textLimit: string;
  titleLimit: string;
}

const Topics: React.FunctionComponent<TopicsProps> = ({
  topics,
  error,
  onFocus,
  onBlur,
  changeText,
  changeTitle,
  addBlock,
  removeBlock,
  topicsError,
  setTopicsError,
  textLimit,
  titleLimit
}) => (
  <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
    <div className={styles.label}>関連する話題</div>
    {topics.map((item, index) => {
      return (
        <div className={styles.complement} key={`topics-${index}`}>
          <TextInput
            placeholder="関連する話題のタイトル"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              changeTitle("topics", event.target.value, index);
              topicsError[index]
                ? (topicsError[index].labelError =
                    event.target.value.length > 1500)
                : (topicsError[index] = {
                    labelError: event.target.value.length > 1500,
                    textError: false
                  });
              setTopicsError(topicsError);
            }}
            value={item.label}
            error={error}
          />
          {!error && topicsError[index]?.labelError && (
            <div className={styles.errorMessage}>
              関連する話題のタイトル{index + 1}は{titleLimit}
              文字以内で入力してください
            </div>
          )}
          <textarea
            placeholder="コレクションの関連する話題"
            onChange={event => {
              changeText("topics", event.target.value, index);
              topicsError[index]
                ? (topicsError[index].textError =
                    event.target.value.length > 1500)
                : (topicsError[index] = {
                    labelError: false,
                    textError: event.target.value.length > 1500
                  });
              setTopicsError(topicsError);
            }}
            value={item.text}
            rows={5}
            onFocus={onFocus}
            onBlur={onBlur}
          />
          {!error && topicsError[index]?.textError && (
            <div className={styles.errorMessage}>
              関連する話題の本文{index + 1}は{textLimit}
              文字以内で入力してください
            </div>
          )}
        </div>
      );
    })}
    <div className={styles.complementDescription}>
      周辺のビジネス領域に関して説明。
    </div>
    <div className={styles.complementDescription}>
      トピックと説明文のペアが1つのコレクションに複数紐づく。
    </div>
    <div className={styles.blockTool}>
      <span
        className={styles.addBlock}
        onClick={() => addBlock("topics")}
        data-testid="BinderPage-Common-BinderForm-Topics-addBlock"
      />
      <span
        className={styles.removeBlock}
        onClick={() => removeBlock("topics")}
        data-testid="BinderPage-Common-BinderForm-Topics-removeBlock"
      />
    </div>
    {error && <div className={styles.errorMessage}>{error}</div>}
  </div>
);

interface RegulationProps {
  regulation: Complements[];
  error: string;
  onFocus: () => void;
  onBlur: () => void;
  changeText: (witchBlock: string, value: string, index: number) => void;
  changeTitle: (witchBlock: string, value: string, index: number) => void;
  addBlock: (witchBlock: string) => void;
  removeBlock: (witchBlock: string) => void;
  regulationError: ComplementsError[];
  setRegulationError: (error: ComplementsError[]) => void;
  textLimit: string;
  titleLimit: string;
}

const Regulation: React.FunctionComponent<RegulationProps> = ({
  regulation,
  error,
  onFocus,
  onBlur,
  changeText,
  changeTitle,
  addBlock,
  removeBlock,
  regulationError,
  setRegulationError,
  textLimit,
  titleLimit
}) => {
  return (
    <div className={cn(styles.additem, { [styles.error]: error !== "" })}>
      <div className={styles.label}>関連する法規制</div>
      {regulation.map((item, index) => {
        return (
          <div className={styles.complement} key={`regulation-${index}`}>
            <TextInput
              placeholder="関連する法規制のタイトル"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                changeTitle("regulation", event.target.value, index);
                regulationError[index]
                  ? (regulationError[index].labelError =
                      event.target.value.length > 1500)
                  : (regulationError[index] = {
                      labelError: event.target.value.length > 1500,
                      textError: false
                    });
                setRegulationError(regulationError);
              }}
              value={item.label}
              error={error}
            />
            {!error && regulationError[index]?.labelError && (
              <div className={styles.errorMessage}>
                関連する法規制のタイトル{index + 1}は{titleLimit}
                文字以内で入力してください
              </div>
            )}
            <textarea
              placeholder="コレクションの関連する法規制"
              onChange={event => {
                changeText("regulation", event.target.value, index);
                regulationError[index]
                  ? (regulationError[index].textError =
                      event.target.value.length > 1500)
                  : (regulationError[index] = {
                      labelError: false,
                      textError: event.target.value.length > 1500
                    });
                setRegulationError(regulationError);
              }}
              value={item.text}
              rows={5}
              onFocus={onFocus}
              onBlur={onBlur}
            />
            {!error && regulationError[index]?.textError && (
              <div className={styles.errorMessage}>
                関連する法規制の本文{index + 1}は{textLimit}
                文字以内で入力してください
              </div>
            )}
          </div>
        );
      })}
      <div className={styles.blockTool}>
        <span
          className={styles.addBlock}
          onClick={() => addBlock("regulation")}
          data-testid="BinderPage-Common-BinderForm-Regulation-addBlock"
        />
        <span
          className={styles.removeBlock}
          onClick={() => removeBlock("regulation")}
          data-testid="BinderPage-Common-BinderForm-Regulation-removeBlock"
        />
      </div>
      {error && <div className={styles.errorMessage}>{error}</div>}
    </div>
  );
};

interface DropdownProps {
  title: string;
  description: string;
  content: React.ReactNode;
  disabled?: boolean;
  onSwitch?: (isShow: boolean) => void;
  error?: string;
  keyword: string;
  keywordFeedFlg: boolean;
  rawOperand: RawOperand;
  setKeywordError: (arg: string) => void;
  isShowing: {
    caption: boolean | undefined;
    description: boolean | undefined;
    keyword: boolean | undefined;
    share: boolean;
    media: boolean;
    publish: boolean;
    editComplement: boolean | undefined;
  };
  type: string;
  onlyNotErrorMsg: string;
}

const Dropdown: React.FunctionComponent<DropdownProps> = ({
  title,
  description,
  content,
  disabled,
  onSwitch,
  error,
  keyword,
  keywordFeedFlg,
  rawOperand,
  setKeywordError,
  isShowing,
  type,
  onlyNotErrorMsg
}) => {
  const [isShow, setIsShow] = useState(false);

  return (
    <div
      className={`${styles.dropdown} ${disabled && styles.dropdownDisabled}`}
    >
      <div className={styles.dropWrapper}>
        <div className={`${styles.label} ${isShow && styles.labelShow}`}>
          <div className={styles.title}>
            <span>{title}</span>
            <span className={styles.error}>{error}</span>
          </div>
          {isShow ? (
            <div className={styles.content}>{content}</div>
          ) : (
            <div
              className={
                description ? styles.description : styles.descriptionEmpty
              }
            >
              {description || "未設定"}
            </div>
          )}
        </div>
        <div
          className={styles.operate}
          onClick={() => {
            if (type === "keyword") {
              if (error === onlyNotErrorMsg) {
                setKeywordError("");
              }
              const validatedKeywords = validateKeywords(
                keyword,
                rawOperand,
                !isEmpty(rawOperand.and) ||
                  !isEmpty(rawOperand.or) ||
                  !isEmpty(rawOperand.not)
              );
              if (
                isShowing.keyword &&
                keywordFeedFlg &&
                !isEmpty(validatedKeywords.notKeyword) &&
                isEmpty(validatedKeywords.andKeyword) &&
                isEmpty(validatedKeywords.orKeyword)
              ) {
                setKeywordError(onlyNotErrorMsg);
                return;
              }
            }
            setIsShow(!isShow);
            onSwitch && onSwitch(!isShow);
          }}
        >
          <div className={styles.text}>{isShow ? "閉じる" : "設定する"}</div>
          <div className={isShow ? styles.packup : styles.expand} />
        </div>
      </div>
    </div>
  );
};

interface KeywordProps {
  keywordFeedFlg: boolean;
  rawOperand: RawOperand;
  setRawOperand: (arg: RawOperand) => void;
  menuType: string;
  keyword: string;
  setKeyword: (arg: string) => void;
  setIsOpenHelp: (isOpen: boolean) => void;
  keywordError: string;
  keywordLabel: string;
  setKeywordLabel: (arg: string) => void;
  setKeywordError: (arg: string) => void;
  isShowing: {
    caption: boolean | undefined;
    description: boolean | undefined;
    keyword: boolean | undefined;
    share: boolean;
    media: boolean;
    publish: boolean;
    editComplement: boolean | undefined;
  };
  onlyNotErrorMsg: string;
}

const Keyword: React.FunctionComponent<KeywordProps> = ({
  keywordFeedFlg,
  rawOperand,
  setRawOperand,
  menuType,
  keyword,
  setKeyword,
  setIsOpenHelp,
  keywordError,
  keywordLabel,
  setKeywordLabel,
  setKeywordError,
  isShowing,
  onlyNotErrorMsg
}) => {
  return (
    <Dropdown
      title="キーワード"
      description={keywordLabel || ""}
      disabled={!keywordFeedFlg}
      error={keywordError}
      keyword={keyword}
      keywordFeedFlg={keywordFeedFlg}
      rawOperand={rawOperand}
      setKeywordError={setKeywordError}
      isShowing={isShowing}
      type="keyword"
      onlyNotErrorMsg={onlyNotErrorMsg}
      content={
        <div className={styles.checkboxWrapper}>
          <div className={styles.keyword}>
            <FormulaSearch
              showTitle={false}
              switchOnRightBottom={true}
              rawOperand={rawOperand}
              onChangeRawOperand={setRawOperand}
              witch="collection"
              disabled={!keywordFeedFlg}
              atlasPageName={
                menuType === "new" ? "コレクション作成" : "コレクション編集"
              }
              canOpenCaution={true}
              keyword={keyword}
              onChangeRawText={keyword => {
                setKeywordLabel(keyword);
                setKeyword(keyword);
              }}
              setIsOpenHelp={setIsOpenHelp}
              validateSwtich={keywordFeedFlg}
              onSwitchError={setKeywordError}
              currentError={keywordError}
              onlyNotErrorMsg={onlyNotErrorMsg}
            />
          </div>
        </div>
      }
      onSwitch={isShow => {
        if (isShow) {
          trackAction("myCollectionKeywordClick", {
            action:
              menuType === "new" ? "コレクション新規作成" : "コレクション編集"
          });
        }
        if (!isShow && (rawOperand.and || rawOperand.or || rawOperand.not)) {
          setKeywordLabel(
            convertSearchKeyword({
              and: rawOperand.and || "",
              or: rawOperand.or || "",
              not: rawOperand.not || ""
            })
          );
        }
      }}
    />
  );
};

interface CorpSearchProps {
  selectedItems: Item[];
  companyError: string;
  isOpenCompanyModal: boolean;
  setOpenCompanyModal: (isOpen: boolean) => void;
  menuType: string;
}

const CorpSearch: React.FunctionComponent<CorpSearchProps> = ({
  selectedItems,
  companyError,
  isOpenCompanyModal,
  setOpenCompanyModal,
  menuType
}) => {
  const dispatch = useDispatch();

  const searchList = useSearchList();
  const selectMenu = useSelectMenu();
  const recentList = useRecentList();
  const collection = useCollection();

  const clickSelect = () => {
    trackAction("myCollectionCorpSelect", {
      action: menuType === "new" ? "コレクション新規作成" : "コレクション編集"
    });
    setOpenCompanyModal(true);
  };

  const addCompany = (companies: Item[]) => {
    if (companies) {
      dispatch(setSeletedCorpItems(companies));
    }
    setOpenCompanyModal(false);
  };

  return (
    <div className={styles.corpSearch}>
      <div className={styles.title}>
        <span className={styles.label}>企業</span>
        <Button className={styles.button} color="primary" onClick={clickSelect}>
          企業選択
        </Button>
        <span className={styles.error}>{companyError}</span>
      </div>
      <div className={styles.description}>
        コレクションの「企業」に登録されます。登録した企業はフィード条件として利用できます。
      </div>
      <div className={styles.content}>
        {selectedItems && selectedItems.length > 0 ? (
          selectedItems.map((corp, index) => (
            <span key={corp.companyCode + "-" + index}>
              {index > 0 && <span>、</span>}
              <span>{corp.companyName}</span>
            </span>
          ))
        ) : (
          <span className={styles.noData}>未設定</span>
        )}
      </div>
      <CompanyModal
        isOpen={isOpenCompanyModal}
        defaultSelectedItems={selectedItems}
        searchList={searchList}
        selectMenu={selectMenu}
        recentList={recentList}
        collection={collection}
        onClose={() => setOpenCompanyModal(false)}
        onChange={addCompany}
        maxSelectableNumber={2000}
        sendCollectionAtlas={true}
        collectionMenuType={menuType}
      />
    </div>
  );
};

interface SelectionProps {
  keywordFeedFlg: boolean;
  setKeywordFeedFlg: (arg: boolean) => void;
  searchType: string;
  setSearchType: (value: string) => void;
  companyFeedFlg: boolean;
  setCompanyFeedFlg: (arg: boolean) => void;
  menuType: string;
}

const Selection: React.FunctionComponent<SelectionProps> = ({
  keywordFeedFlg,
  setKeywordFeedFlg,
  searchType,
  setSearchType,
  companyFeedFlg,
  setCompanyFeedFlg,
  menuType
}) => {
  const onChange = (condition: {
    isKeyword: boolean;
    isCompany: boolean;
    isAnd: boolean;
  }) => {
    trackAction("myCollectionConditionalClick", {
      action: menuType === "new" ? "コレクション新規作成" : "コレクション編集"
    });
    setCompanyFeedFlg(condition.isCompany);
    setKeywordFeedFlg(condition.isKeyword);
    setSearchType(condition.isAnd ? "1" : "0");
  };

  return (
    <div className={`${styles.additem} ${styles.feeds}`}>
      <div className={styles.feedLabel}>フィード条件</div>
      <div className={styles.feedDescription}>以下の条件で記事を抽出します</div>
      <div
        className={styles.item}
        onClick={() => {
          onChange({
            isKeyword: true,
            isCompany: true,
            isAnd: false
          });
        }}
        data-testid="BinderPage-Common-BinderForm-setKeywordOrCompanyFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={companyFeedFlg && keywordFeedFlg && searchType === "0"}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>企業またはキーワード</div>
          <div className={styles.itemDescription}>
            登録した企業またはキーワードのいずれかに合致する記事を抽出します
          </div>
        </div>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          onChange({
            isKeyword: true,
            isCompany: true,
            isAnd: true
          });
        }}
        data-testid="BinderPage-Common-BinderForm-setKeywordAndCompanyFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={companyFeedFlg && keywordFeedFlg && searchType === "1"}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>企業とキーワード</div>
          <div className={styles.itemDescription}>
            登録した企業またはキーワードの両方に合致する記事を抽出します
          </div>
        </div>
      </div>
      <div
        className={styles.item}
        onClick={() =>
          onChange({
            isKeyword: false,
            isCompany: true,
            isAnd: false
          })
        }
        data-testid="BinderPage-Common-BinderForm-setCompanyFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={companyFeedFlg && !keywordFeedFlg}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>企業</div>
          <div className={styles.itemDescription}>
            登録した企業に関連する記事を抽出します
          </div>
        </div>
      </div>
      <div
        className={styles.item}
        onClick={() => {
          onChange({
            isKeyword: true,
            isCompany: false,
            isAnd: false
          });
        }}
        data-testid="BinderPage-Common-BinderForm-setKeywordFeed"
      >
        <Radio
          name="feedConditions"
          isSelected={!companyFeedFlg && keywordFeedFlg}
          uncontrolled
        />
        <div className={styles.itemContent}>
          <div className={styles.itemName}>キーワード</div>
          <div className={styles.itemDescription}>
            指定したキーワードを含む記事を抽出します
          </div>
        </div>
      </div>
    </div>
  );
};

interface ShareProps {
  sharedFlg: boolean;
  setSharedFlg: (arg: boolean) => void;
}

const Share: React.FunctionComponent<ShareProps> = ({
  sharedFlg,
  setSharedFlg
}) => (
  <div
    className={cn(styles.additem, styles.share)}
    data-testid="BinderPage-Common-BinderForm-share"
  >
    <div className={styles.label}>共有設定</div>
    <Checkbox
      name="組織にコレクションを共有する"
      className={styles.checkbox}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        trackAction("myCollectionEditShareCheck");
        setSharedFlg(event.target.checked);
      }}
      isChecked={sharedFlg}
    />
    <div className={styles.tips}>
      組織に共有されたコレクションは全員が編集・削除を行えるようになります
    </div>
  </div>
);

interface PublishProps {
  publishedFlg: boolean;
  setPublishedFlg: (arg: boolean) => void;
}

const Publish: React.FunctionComponent<PublishProps> = ({
  publishedFlg,
  setPublishedFlg
}) => (
  <div className={cn(styles.additem, styles.media)}>
    <div className={styles.label}>公開</div>
    <Checkbox
      name="日経コレクションとして公開する"
      className={styles.checkbox}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        setPublishedFlg(event.target.checked);
      }}
      isChecked={publishedFlg}
    />
  </div>
);

interface MediaProps {
  mediaList: MediaType[];
  onChange: (media: MediaType) => void;
  onChangeList: (media: MediaType[], checked: boolean) => void;
  setIsOpenDetailModal: (isOpen: boolean) => void;
  mediaError: string;
  menuType: string;
  keyword: string;
  keywordFeedFlg: boolean;
  rawOperand: RawOperand;
  setKeywordError: (arg: string) => void;
  isShowing: {
    caption: boolean | undefined;
    description: boolean | undefined;
    keyword: boolean | undefined;
    share: boolean;
    media: boolean;
    publish: boolean;
    editComplement: boolean | undefined;
  };
  onlyNotErrorMsg: string;
}

const Media: React.FunctionComponent<MediaProps> = props => {
  let result = "";
  let allMedia: string[] = [];
  mediaTypes.map(mediaType => {
    const medium = props.mediaList.filter(
      media => media.hierarchy === mediaType
    );

    const names = medium
      .filter(media => media.checked)
      .map(media => {
        return media.value === "WC1" ? "外部サイト（官公庁）" : media.label;
      });
    allMedia = allMedia.concat(...names);

    result = allMedia.join(",");
  });

  return (
    <Dropdown
      title="媒体"
      description={result}
      error={props.mediaError}
      keyword={props.keyword}
      keywordFeedFlg={props.keywordFeedFlg}
      rawOperand={props.rawOperand}
      setKeywordError={props.setKeywordError}
      isShowing={props.isShowing}
      type="media"
      onlyNotErrorMsg={props.onlyNotErrorMsg}
      content={
        <div className={cn(styles.additem, styles.media)}>
          <div className={styles.checkboxContainer}>
            {props.mediaList.length !== 0 &&
              mediaTypes.map((mediaType, mediaTypeIndex) => {
                const medium = props.mediaList.filter(
                  media => media.hierarchy === mediaType
                );
                const activeCheckbox = medium.filter(
                  media => media.baseOptFlg !== "9"
                );
                const checkedCheckbox = medium.filter(
                  media => media.baseOptFlg !== "9" && media.checked
                );
                const allChecked = medium.every(
                  media => media.baseOptFlg === "9"
                )
                  ? medium.every(media => media.checked)
                  : activeCheckbox.length === checkedCheckbox.length;

                return (
                  <div
                    className={styles.mediaGroup}
                    key={`media-wrapper-${mediaTypeIndex}`}
                  >
                    <div className={styles.parentCheckboxWrapper}>
                      <Checkbox
                        name={mediaType}
                        isActive={medium.some(
                          media => media.baseOptFlg !== "9"
                        )}
                        isIndeterminate={
                          medium.some(media => media.checked) && !allChecked
                        }
                        isChecked={allChecked}
                        onChange={() => {
                          const checked = !medium.every(
                            media => media.baseOptFlg === "9" || media.checked
                          );

                          props.onChangeList(medium, checked);
                        }}
                      />
                      <div className={styles.helpIconWrapper}>
                        {mediaType === "英文記事" && (
                          <ToolTip text="検索キーワードは英文で指定してください">
                            <div className={styles.searchHelpIcon} />
                          </ToolTip>
                        )}
                      </div>
                    </div>
                    <div className={styles.mediaList}>
                      {medium.map((media, index) => {
                        const isActive = media.baseOptFlg !== "9";
                        return (
                          <span
                            className={styles.item}
                            key={`media-${index}`}
                            data-testid={`BinderPage-Common-BinderForm-media-${index}`}
                          >
                            <Checkbox
                              name={
                                media.value === "WC1"
                                  ? "外部サイト（官公庁）"
                                  : media.label
                              }
                              isSmall
                              className={styles.checkbox}
                              onChange={() => {
                                props.onChange(media);
                              }}
                              isChecked={media.checked}
                              isActive={isActive}
                            />
                            {/* 日経産業新聞は更新停止のラベルを追加する */}
                            {media.value === "NSS" && (
                              <span className={styles.updateStop}>
                                （更新停止）
                              </span>
                            )}
                            {media.mediaFeeType === "1" && (
                              <PayTag isActive={isActive} />
                            )}
                            {!isActive && media.mediaFeeType === "0" && (
                              <ToolTip
                                content={
                                  <div className={styles.tooltipContent}>
                                    オプションでご利用いただけます。
                                    <span
                                      className={styles.detail}
                                      onClick={() =>
                                        props.setIsOpenDetailModal(true)
                                      }
                                    >
                                      詳細
                                    </span>
                                  </div>
                                }
                              >
                                <div
                                  className={cn(styles.lockIcon, styles.cursor)}
                                />
                              </ToolTip>
                            )}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      }
      onSwitch={isShow => {
        if (isShow) {
          trackAction("myCollectionMediaClick", {
            action:
              props.menuType === "new"
                ? "コレクション新規作成"
                : "コレクション編集"
          });
        }
      }}
    />
  );
};
