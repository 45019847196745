import { createAction, handleActions } from "redux-actions";
import * as ActionTypes from "./actionType";
import { request } from "utils/apiClient/base";
import { produce } from "immer";
import { union } from "lodash";

const initialState = {
  list: [],
  items: [],
  isLoading: {
    list: false,
    items: false
  },
  checkedCodes: []
};

export default handleActions(
  {
    [`${ActionTypes.BINDER_LIST_GET}_PENDING`]: () => ({
      ...initialState,
      isLoading: {
        ...initialState.isLoading,
        list: true
      }
    }),
    [`${ActionTypes.BINDER_LIST_GET}_REJECTED`]: state =>
      produce(state, draft => {
        draft.isLoading.list = false;
      }),
    [`${ActionTypes.BINDER_LIST_GET}_FULFILLED`]: (state, action) =>
      produce(state, draft => {
        draft.list = action.payload.binderList;
        draft.isLoading.list = false;
      }),
    [`${ActionTypes.BINDER_ITEM_GET}_PENDING`]: state =>
      produce(state, draft => {
        draft.isLoading.items = true;
      }),
    [`${ActionTypes.BINDER_ITEM_GET}_REJECTED`]: state =>
      produce(state, draft => {
        draft.isLoading.items = false;
      }),
    [`${ActionTypes.BINDER_ITEM_GET}_FULFILLED`]: (state, action) =>
      produce(state, draft => {
        draft.items = action.payload.corpList;
        draft.isLoading.items = false;
      }),
    [ActionTypes.CHECKED_TOGGLE]: (state, action) => {
      if (action.payload.isAll) {
        return produce(state, draft => {
          draft.checkedCodes = action.payload.checked
            ? union(
                state.checkedCodes,
                state.items.map(item => item.corpCode)
              )
            : [];
        });
      }

      return produce(state, draft => {
        draft.checkedCodes = action.payload.checked
          ? union(state.checkedCodes, [action.payload.code])
          : state.checkedCodes.filter(code => code !== action.payload.code);
      });
    }
  },
  initialState
);

export const actionCreators = {
  getBinderList: createAction(ActionTypes.BINDER_LIST_GET, id =>
    request("/common/binder", {
      excludeBinderId: id || ""
    })
  ),
  getBinderItem: createAction(ActionTypes.BINDER_ITEM_GET, params =>
    request("/common/binder/list/corp", params)
  ),
  toggleChecked: createAction(ActionTypes.CHECKED_TOGGLE)
};
