import React, { useCallback, useEffect, useState } from "react";
import styles from "./style.scss";
import { IndustryList } from "./IndustryList";
import { Corp, MstL, MstM, MstS } from "models/CorpScreening";
import IconImage from "images/icon/arrow_right_circle.svg";
import CorpTypeSourceSelector from "components/Common/CorpTypeSourceSelector";
import Checkbox from "components/Common/Checkbox";
import { CorpList } from "./CorpList";
import { setCompanySelectSourceItems } from "modules/tool/screening/corp/modernItem/actions";
import { useDispatch } from "react-redux";

interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
}

interface SourceItem {
  active: boolean;
  corpCount: string;
  fsarea: string;
  haveAuthority: boolean;
  id: string;
  inneyCode: string;
  label: string;
  labelId: string;
  selected: boolean;
  seq: string;
  value: string;
}

interface Props {
  mstL: MstL[];
  mstM: MstM[];
  mstS: MstS[];
  corpList: Corp[];
  selectedCorp: Item[];
  loadingMstM: boolean;
  loadingMstS: boolean;
  loadingCorp: boolean;
  getMstM: ({ nkgncodel }: { nkgncodel: string }) => void;
  getMstS: ({
    nkgncodel,
    nkgncodem
  }: {
    nkgncodel: string;
    nkgncodem: string;
  }) => void;
  getCorps: (params: {
    allChecked: boolean;
    concatenatedCorpFilterValue: string;
    corpIndustyCode: string;
    mainFlgComp: string;
    nkgncodes: string;
  }) => void;
  onSelect: (corp: Item[]) => void;
  sourceItems: SourceItem[];
  maxSelectableNumber: number;
  disableOverseas?: boolean;
  windowRef: React.RefObject<unknown>;
  alretItemCodes?: string[];
}

export const Industry: React.FC<Props> = ({
  mstL,
  mstM,
  mstS,
  corpList,
  selectedCorp,
  loadingMstM,
  loadingMstS,
  loadingCorp,
  getMstM,
  getMstS,
  getCorps,
  onSelect,
  sourceItems,
  maxSelectableNumber,
  disableOverseas = false,
  windowRef,
  alretItemCodes = []
}) => {
  const [mstCodeL, setMstCodeL] = useState<string>();
  const [mstCodeM, setMstCodeM] = useState<string>();
  const [mstCodeS, setMstCodeS] = useState<string>();
  const [isMainCorp, setIsMainCorp] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string>("");
  const dispatch = useDispatch();

  const getSelectedIds = useCallback(
    (items: SourceItem[]) => {
      if (alretItemCodes.length > 0) {
        items = items.filter(item => alretItemCodes.includes(item.value));
      }
      return items
        .filter(item => item.value !== "98")
        .map(item => {
          return item.selected ? `#${item.value}` : "#";
        })
        .join("");
    },
    [alretItemCodes]
  );

  useEffect(() => {
    setSelectedIds(getSelectedIds(sourceItems));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sourceItems]);

  const onClickMstL = (code: string) => {
    setMstCodeL(code);
    getMstM({ nkgncodel: code });
  };

  const onClickMstM = (code: string) => {
    if (!mstCodeL) return;
    setMstCodeM(code);
    getMstS({ nkgncodel: mstCodeL, nkgncodem: code });
  };

  const onClickMstS = (code: string) => {
    setMstCodeS(code);
    getCorps({
      nkgncodes: code,
      concatenatedCorpFilterValue: selectedIds,
      mainFlgComp: isMainCorp ? "1" : "0",
      allChecked: false,
      corpIndustyCode: code
    });
  };

  const onChangeSourceSelect = (items: SourceItem[]) => {
    const filterValue = getSelectedIds(items);

    setSelectedIds(filterValue);

    dispatch(setCompanySelectSourceItems(items));

    if (mstCodeS) {
      getCorps({
        nkgncodes: mstCodeS,
        concatenatedCorpFilterValue: filterValue,
        mainFlgComp: isMainCorp ? "1" : "0",
        allChecked: false,
        corpIndustyCode: mstCodeS
      });
    }
  };

  const onChangeMainFlag = (flag: boolean) => {
    setIsMainCorp(flag);

    getCorps({
      nkgncodes: mstCodeS || "",
      concatenatedCorpFilterValue: selectedIds,
      mainFlgComp: flag ? "1" : "0",
      allChecked: false,
      corpIndustyCode: mstCodeS || ""
    });
  };

  const onInit = (items: SourceItem[]) => {
    setSelectedIds(getSelectedIds(items));
  };

  return (
    <>
      <div className={styles.setting}>
        <CorpTypeSourceSelector
          items={sourceItems}
          windowRef={windowRef}
          onChangeSourceSelector={onChangeSourceSelect}
          onInit={onInit}
          disableOverseas={disableOverseas}
          unlistedOverseasHide
          disabledSaveSelection
        />
        <Checkbox
          isChecked={isMainCorp}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChangeMainFlag(e.target.checked);
          }}
          name="選択した業種を主とする企業のみ"
        />
      </div>
      <div className={styles.listContainer}>
        <div className={styles.listWrapper}>
          <IndustryList
            title="業界大分類"
            items={mstL.map(item => ({
              name: item.nkgnnamel,
              code: item.nkgncodel
            }))}
            selected={mstCodeL}
            key={"industry-L"}
            onClick={code => onClickMstL(code)}
          />
        </div>
        <div className={styles.arrow}>
          <div className={styles.icon}>
            <img src={IconImage} />
          </div>
        </div>
        <div className={styles.listWrapper}>
          <IndustryList
            title="業界中分類"
            items={mstM.map(item => ({
              name: item.nkgnnamem,
              code: item.nkgncodem
            }))}
            selected={mstCodeM}
            isLoading={loadingMstM}
            key={"industry-M"}
            onClick={code => onClickMstM(code)}
          />
        </div>
        <div className={styles.arrow}>
          <div className={styles.icon}>
            <img src={IconImage} />
          </div>
        </div>
        <div className={styles.listWrapper}>
          <IndustryList
            title="業界小分類"
            items={mstS.map(item => ({
              name: item.nkgnnames,
              code: item.nkgncodes
            }))}
            selected={mstCodeS}
            isLoading={loadingMstS}
            key={"industry-S"}
            onClick={code => onClickMstS(code)}
          />
        </div>
        <div className={styles.arrow}>
          <div className={styles.icon}>
            <img src={IconImage} />
          </div>
        </div>
        <div className={styles.listWrapper}>
          <CorpList
            items={corpList}
            selected={selectedCorp}
            maxSelectableNumber={maxSelectableNumber}
            isLoading={loadingCorp}
            onSelect={onSelect}
          />
        </div>
      </div>
    </>
  );
};
