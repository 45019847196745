import { useCallback, useMemo, useState } from "react";
import { Corp, MstL, MstM, MstS } from "models/CorpScreening";
import { request } from "utils/apiClient";
import { useReduxState } from "hooks/useReduxState";
import { actionCreators as commonActionCreators } from "modules/common/BinderList";
import { useDispatch } from "react-redux";
import { useIpAuthFlg } from "hooks/useIpAuthFlg";
import { setCompanySelectSourceItems } from "modules/tool/screening/corp/modernItem/actions";

interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn: string;
}

interface CompanyResponse {
  companyCode: string;
  companyKbn: string;
  companyName: string;
  diffflg: string;
  kabushikiCode: string;
  source: string;
}

interface SourceItem {
  active: boolean;
  corpCount: string;
  fsarea: string;
  haveAuthority: boolean;
  id: string;
  inneyCode: string;
  label: string;
  labelId: string;
  selected: boolean;
  seq: string;
  value: string;
}

export const useSearchList = () => {
  const [searchResult, setSearchResult] = useState<Item[] | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const type = useReduxState(
    state => state.commonSourceSelector.authType.toolScreeningCorpItem
  );

  const searchCorp = async (
    companyName: string,
    suggestFlag: boolean,
    suggestMeta?: string
  ) => {
    setIsLoading(true);
    const response = await request<{ companyList: CompanyResponse[] }>(
      "/common/corp-search/company-by-suggest",
      {
        companyName,
        suggestFlag,
        suggestMeta,
        corpIndustyKbn: "8"
      }
    );
    setSearchResult(
      response.companyList.map(item => ({
        companyCode: item.companyCode,
        companyName: item.companyName,
        stkCode: item.kabushikiCode,
        source: item.source,
        companyKbn: item.companyKbn
      }))
    );
    setIsLoading(false);
  };

  return {
    searchResult,
    searchCorp,
    type,
    isLoading,
    isLoadingSearch: false
  };
};

export const useSelectMenu = () => {
  const [needsMstL, setNeedsMstL] = useState<MstL[]>([]);
  const [needsMstM, setNeedsMstM] = useState<MstM[]>([]);
  const [needsMstS, setNeedsMstS] = useState<MstS[]>([]);
  const [corpList, setCorpList] = useState<Corp[]>([]);
  const [loadingMstM, setLoadingMstM] = useState<boolean>(false);
  const [loadingMstS, setLoadingMstS] = useState<boolean>(false);
  const [loadingCorp, setLoadingCorp] = useState<boolean>(false);

  const sourceItems = useReduxState<SourceItem[]>(
    state => state.toolScreeningCorpItemModern.item.companySelectSourceItems
  );
  const personalSettings = useReduxState(
    state => state.commonSourceSelector.personalSettings
  );

  const dispatch = useDispatch();

  const getNeedsMstL = async () => {
    if (needsMstL.length) return;

    setNeedsMstM([]);
    setNeedsMstS([]);
    setCorpList([]);

    const response = await request<{ needsMstL: MstL[] }>(
      "/common/corp-search/corp",
      {
        nkgncodel: null,
        nkgncodem: null,
        nkgncodes: null,
        corpIndustyKbn: "8"
      }
    );
    setNeedsMstL(response.needsMstL);
  };

  const getNeedsMstM = async ({ nkgncodel }: { nkgncodel: string }) => {
    setNeedsMstS([]);
    setCorpList([]);
    setLoadingMstM(true);

    const response = await request<{ needsMstM: MstM[] }>(
      "/common/corp-search/needs-mst-m",
      {
        corpIndustyKbn: "8",
        nkgncodel: nkgncodel
      }
    );
    setNeedsMstM(response.needsMstM);
    setLoadingMstM(false);
  };

  const getNeedsMstS = async ({
    nkgncodel,
    nkgncodem
  }: {
    nkgncodel: string;
    nkgncodem: string;
  }) => {
    setCorpList([]);

    setLoadingMstS(true);
    const response = await request<{ needsMstS: MstS[] }>(
      "/common/corp-search/needs-mst-s",
      {
        corpIndustyKbn: "8",
        nkgncodel: nkgncodel,
        nkgncodem: nkgncodem
      }
    );

    setNeedsMstS(response.needsMstS);
    setLoadingMstS(false);
  };

  const onClickNeedsMstS = async (params: {
    allChecked: boolean;
    concatenatedCorpFilterValue: string;
    corpIndustyCode: string;
    mainFlgComp: string;
    nkgncodes: string;
  }) => {
    const corpFilterValue = params.concatenatedCorpFilterValue.split("#");
    // 該当する企業種別以外も # だけは送る必要があるため # を付与
    const concatenatedCorpFilterValue = sourceItems
      .map(item => {
        if (corpFilterValue.includes(item.inneyCode)) {
          return item.inneyCode;
        }
        return null;
      })
      .join("#");

    setLoadingCorp(true);
    const response = await request<{ companyList: Corp[] }>(
      "/common/corp-search/company",
      {
        ...params,
        concatenatedCorpFilterValue: "#" + concatenatedCorpFilterValue,
        corpIndustyKbn: "8"
      }
    );
    setCorpList(response.companyList);
    setLoadingCorp(false);
  };

  const initSourceItems = () => {
    dispatch(setCompanySelectSourceItems([...personalSettings]));
  };

  const clear = () => {
    setNeedsMstM([]);
    setNeedsMstS([]);
    setCorpList([]);
  };

  return {
    corpList,
    getNeedsMstL,
    getNeedsMstM,
    getNeedsMstS,
    onClickNeedsMstS,
    clear,
    initSourceItems,
    needsMstL,
    needsMstM,
    needsMstS,
    sourceItems,
    loadingMstM,
    loadingMstS,
    disableOverseas: false,
    loadingCorp
  };
};

interface Company {
  companyCode: string;
  companyKbn: string;
  companyName: string;
  diffflg: string;
  stkCode: string;
  source: string;
}

export const useRecentList = () => {
  const [recentList, setRecentList] = useState<Company[]>([]);

  const fetchList = useCallback(() => {
    return request<{
      companyList: CompanyResponse[];
    }>("/common/corp-search/latestview-company", {
      corpIndustyKbn: "8"
    });
  }, []);

  const getRecent = async () => {
    const response = await fetchList();
    setRecentList(
      response.companyList.map(item => ({
        ...item,
        stkCode: item.kabushikiCode
      }))
    );
  };

  const selectCorp = () => {};

  return {
    getRecent,
    selectCorp,
    recentList
  };
};

export const useCollection = () => {
  const collections = useReduxState(state => state.commonBinderList.list);
  const corpList = useReduxState(state => state.commonBinderList.items);
  const binderActionState = useReduxState(
    state => state.binders.list.binderAction
  );
  const isLoading = useReduxState(
    state => state.commonBinderList.isLoading.items
  );

  const dispatch = useDispatch();
  const ipAuthFlg = useIpAuthFlg();

  const getBinders = useCallback(() => {
    if (ipAuthFlg) return; // IP認証の場合は処理しない
    let excludeBinderId = "";
    if (
      binderActionState &&
      binderActionState.binderInfo &&
      binderActionState.binderInfo.binderId
    ) {
      excludeBinderId = binderActionState.binderInfo.binderId;
    }
    dispatch(commonActionCreators.getBinderList(excludeBinderId));
  }, [dispatch, ipAuthFlg, binderActionState]);

  const onSelectCollection = (id: string) => {
    dispatch(
      commonActionCreators.getBinderItem({
        binderId: id,
        corpFilter: ""
      })
    );
  };

  const corporations = useMemo(() => {
    return corpList.map((item: { corpName: string; corpCode: string }) => ({
      ...item,
      companyName: item.corpName,
      companyCode: item.corpCode
    }));
  }, [corpList]);

  return {
    collections,
    corporations,
    isLoading,
    getBinders,
    onSelectCollection
  };
};
