import React from "react";
import styles from "./style.scss";

import { request } from "utils/apiClient/suggest";
import { trimSpaces } from "utils/helper/format";
import { trimAllSpace } from "utils/helper/common";

import Input from "./Input";
import List from "./List";

/**
 * 検索サジェスト
 *
 * @prop {string} type - サジェストリクエストの種類
 * @prop {?string} placeholder - プレースホルダ
 * @prop {?function} onEnterWithKeyword - キーワード確定時 callback
 * @prop {?function} onSelectSuggest - サジェストアイテム選択時 callback
 * @prop {?boolean} isTopSearch - 一括検索デザインか
 * @prop {?boolean} isKeepKeyword - 入力/選択後キーワードを維持するか
 * @prop {?string} title - input要素のtitle
 * @prop {?string} defaultKeyword - キーワード初期状態
 */

const delayTime = 50;
let delayTimerObj;

const defaultProps = {
  placeholder: "",
  onEnterWithKeyword: () => {},
  onSelectSuggest: () => {},
  isTopSearch: false,
  isKeepKeyword: false,
  defaultKeyword: "",
  title: ""
};

class Suggest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      keyword: "",
      listOpened: false,
      focusedItemIndex: -1,
      userKeyword: "",
      default: true
    };

    this.getSuggest = this.getSuggest.bind(this);
    this.clearSuggest = this.clearSuggest.bind(this);
    this.updateKeyword = this.updateKeyword.bind(this);
    this.closeList = this.closeList.bind(this);
    this.onFocusItem = this.onFocusItem.bind(this);
    this.onEnterWithKeyword = this.onEnterWithKeyword.bind(this);
    this.onSelectSuggest = this.onSelectSuggest.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const newState = { listOpened: state.items.length > 0 };
    if (state.default)
      return {
        ...newState,
        keyword: props.defaultKeyword,
        default: false
      };
    return newState;
  }

  stopDelayTimer() {
    if (delayTimerObj) {
      clearTimeout(delayTimerObj);
      delayTimerObj = null;
    }
  }

  startDelayTimer(ms) {
    this.stopDelayTimer();
    return new Promise(resolve => {
      delayTimerObj = setTimeout(resolve, ms);
    });
  }

  // 検索
  getSuggest(data) {
    this.startDelayTimer(delayTime)
      .then(() => request(data))
      .then(response => {
        this.setState({
          items: response,
          focusedItemIndex: -1
        });
      });
  }

  // 入力状態クリア
  clearSuggest() {
    this.setState({
      items: []
    });
  }

  // 入力値更新
  updateKeyword(keyword) {
    const nextState = {
      keyword: keyword,
      userKeyword: keyword
    };

    if (0 < trimAllSpace(keyword).length) {
      this.getSuggest({
        term: keyword,
        type: this.props.type
      });
    } else {
      nextState.items = [];
      nextState.focusedItemIndex = -1;
    }

    this.setState(nextState);
  }

  // リスト閉じる
  closeList(isClearKeyword = false) {
    this.setState(prevState => ({
      items: [],
      focusedItemIndex: -1,
      keyword:
        !this.props.isKeepKeyword && isClearKeyword ? "" : prevState.keyword
    }));
  }

  // アイテムフォーカス(マウスオーバー)時
  onFocusItem(focusedItemIndex, needChangeKeyword = false) {
    if (!needChangeKeyword) {
      this.setState({ focusedItemIndex: focusedItemIndex });
    } else {
      this.setState(prevState => {
        let keyword = prevState.userKeyword;
        // リストの項目が選択されている場合
        if (focusedItemIndex >= 0) {
          const focusedKeyword = this.state.items[focusedItemIndex].keyword;
          const separateIndex = focusedKeyword.lastIndexOf("(");
          // "(小分類)" などの後方のカッコ部を削除
          keyword =
            separateIndex >= 0
              ? focusedKeyword.substr(0, separateIndex)
              : focusedKeyword;
        }
        return {
          focusedItemIndex: focusedItemIndex,
          keyword: keyword
        };
      });
    }
  }

  // 検索キーワード確定時
  onEnterWithKeyword(item) {
    this.closeList();
    this.props.onEnterWithKeyword(trimSpaces(item));
  }

  // サジェストアイテム選択時
  onSelectSuggest(item, changeNeedKeyword) {
    this.closeList(true);
    const separateIndex = item.keyword.lastIndexOf("(");
    // "(小分類)" などの後方のカッコ部を削除
    const keyword =
      separateIndex >= 0 ? item.keyword.substr(0, separateIndex) : item.keyword;
    this.props.onSelectSuggest({
      ...item,
      keyword: keyword,
      originalKeyword: item.keyword
    });
    if (changeNeedKeyword) {
      // 企業・業界選択時は検索ワードクリア
      this.setState({
        focusedItemIndex: -1,
        keyword: !this.props.isKeepKeyword ? "" : keyword
      });
    }
  }

  render() {
    return (
      <div className={styles.component} data-testid="Common-Suggest">
        <Input
          items={this.state.items}
          keyword={this.state.keyword}
          opened={this.state.listOpened}
          focusedItemIndex={this.state.focusedItemIndex}
          listOpened={this.state.listOpened}
          setItemFocus={index => {
            this.onFocusItem(index, true);
          }}
          updateKeyword={this.updateKeyword}
          closeSuggestList={this.closeList}
          onEnterCallback={this.onEnterWithKeyword}
          selectCallback={item => {
            this.onSelectSuggest(item, false);
          }}
          placeholder={this.props.placeholder}
          isTopSearch
          title={this.props.title}
        />
        <List
          items={this.state.items}
          opened={this.state.listOpened}
          focusedItemIndex={this.state.focusedItemIndex}
          setItemFocus={index => {
            this.onFocusItem(index, false);
          }}
          selectCallback={item => {
            this.onSelectSuggest(item, true);
          }}
        />
      </div>
    );
  }
}

Suggest.defaultProps = defaultProps;
export default Suggest;
