import React, { useState, useRef, useEffect } from "react";
import styles from "./style.scss";
import { BinderInfo, BinderActionType } from "models/BinderItem";
import { BinderActionSet } from "models/BinderItem";
import { trackAction } from "utils/helper/analytics";
import { useReduxState } from "hooks/useReduxState";
import { preloadImage } from "utils/helper/preload";
import MenuHoverImage from "images/icon/action_menu_hover.svg";
import EditImage from "images/icon/edit_16_on.svg";
import ShareImage from "images/icon/share_16_on.svg";
import CopyImage from "images/icon/copy_16_on.svg";
import DeleteImage from "images/icon/delete_16_on.svg";

interface BinderActionsType {
  binderInfo: BinderInfo;
  setBinderActionModal?: (value: BinderActionSet) => void;
  onChangeRef?: (refs: {
    listRef: HTMLUListElement | null;
    wrapperRef: HTMLDivElement | null;
  }) => void;
  onOpen?: () => void;
}

export const BinderActions: React.FunctionComponent<BinderActionsType> = props => {
  const [isOpen, setIsOpen] = useState(false);
  const authority = useReduxState(
    state => state.auth.saved.collectionAuthority
  );

  const listRef = useRef<HTMLUListElement | null>(null);
  const onClickOption = () => {
    setIsOpen(prevState => !prevState);
    trackAction("myCollectionOtherClick", {
      id: props.binderInfo.binderId,
      name: props.binderInfo.caption
    });
  };

  const trackActionOperation = (actionType: BinderActionType) => {
    if (
      actionType === "clone_show_new" ||
      actionType === "delete_return_index" ||
      actionType === "editItem" ||
      actionType === "new"
    )
      return;

    let action: "編集" | "複製" | "削除" | "公開";
    switch (actionType) {
      case "edit":
        action = "編集";
        break;
      case "clone":
        action = "複製";
        break;
      case "delete":
        action = "削除";
        break;
      case "publish":
        action = "公開";
        break;
      default:
        return;
    }

    if (location.pathname !== "/" && location.pathname !== "/binder/personal")
      return;

    const locationType = location.pathname === "/" ? "トップ" : "コレクション";
    const type = location.pathname === "/binder/personal" ? "一覧" : undefined;

    trackAction("onClickMyCollectionOperation", {
      action: action,
      location: locationType,
      type: type
    });
  };

  // 範囲外の押下でポップアップが消えるようにする
  const wrapperRef = useRef<HTMLDivElement>(null);
  const onClickOutside = (event: MouseEvent) => {
    setIsOpen(prevState => {
      const target: any = event.target;
      if (
        prevState &&
        wrapperRef.current &&
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        !wrapperRef.current.contains(target)
      ) {
        return false;
      }
      return prevState;
    });
  };

  useEffect(() => {
    preloadImage([
      MenuHoverImage,
      EditImage,
      ShareImage,
      CopyImage,
      DeleteImage
    ]);
    window.addEventListener("click", e => onClickOutside(e));
    return window.removeEventListener("click", e => onClickOutside(e));
  }, []);

  useEffect(() => {
    if (!props.onChangeRef) return;
    props.onChangeRef({
      listRef: listRef.current,
      wrapperRef: wrapperRef.current
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, listRef.current, wrapperRef.current]);

  useEffect(() => {
    if (isOpen && props.onOpen) props.onOpen();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <div
      className={styles.actionWrapper}
      ref={wrapperRef}
      onClick={onClickOption}
      data-testid="BinderPage-List-Components-Common-BinderActions"
    >
      <div className={styles.icon} />
      {isOpen && (
        <ul className={styles.vsList} ref={listRef}>
          {authority?.update && (
            <li
              onClick={() => {
                const actionType = "edit";
                trackActionOperation(actionType);
                props.setBinderActionModal?.({
                  type: actionType,
                  binderInfo: props.binderInfo
                });
              }}
              data-testid="BinderPage-List-Components-Common-BinderActions-editButton"
            >
              <span className={styles.editBtn} />
              編集
            </li>
          )}
          <li
            onClick={() => {
              const actionType = "clone";
              trackActionOperation(actionType);
              props.setBinderActionModal?.({
                type: actionType,
                binderInfo: props.binderInfo
              });
            }}
            data-testid="BinderPage-List-Components-Common-BinderActions-cloneButton"
          >
            <span className={styles.copyBtn} />
            複製
          </li>
          {authority?.delete && (
            <li
              onClick={() => {
                const actionType = "delete";
                trackActionOperation(actionType);
                props.setBinderActionModal?.({
                  type: actionType,
                  binderInfo: props.binderInfo
                });
              }}
              data-testid="BinderPage-List-Components-Common-BinderActions-deleteButton"
            >
              <span className={styles.deleteBtn} />
              削除
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
