import { BindersAction } from "./actions";
import {
  BinderInfo,
  Article,
  Corporation,
  Industry,
  Statistic,
  CategoryInfo,
  ExternalPage,
  Item
} from "models/BinderItem";
import { Reducer } from "redux";
import produce from "immer";
import { FileDownload } from "models/FileDownload";

export interface BinderItemState {
  binderInfo?: BinderInfo;
  categoryInfoList: CategoryInfo[];
  articles: Article[];
  corporations: Corporation[];
  loading: boolean;
  industries: Industry[];
  statistics: Statistic[];
  saveArticles: Article[];
  externalPages: ExternalPage[];
  corporationDownload: FileDownload;
  selectedItem?: string;
  isAdding: boolean;
  articleOffset: number;
  articleLimit: number;
  selectedCorps: Item[];
}

const initialState: BinderItemState = {
  binderInfo: undefined,
  categoryInfoList: [],
  articles: [],
  corporations: [],
  loading: false,
  industries: [],
  statistics: [],
  saveArticles: [],
  externalPages: [],
  corporationDownload: {
    dlpropriety: "",
    dlxls: "",
    dlxlspath: "",
    dlcsv: "",
    dlcsvpath: ""
  },
  selectedItem: undefined,
  isAdding: false,
  articleOffset: 0,
  articleLimit: 100,
  selectedCorps: []
};

export const bindersItem: Reducer<BinderItemState, BindersAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "binders/item/SET_BINDER_INFO":
      return produce(state, draft => {
        draft.binderInfo = action.payload;
      });
    case "binders/item/SET_BINDER_CATEGORY_INFO":
      return produce(state, draft => {
        draft.categoryInfoList = action.payload;
      });
    case "binders/item/SET_ARTICLES":
      return produce(state, draft => {
        draft.articles = action.payload;
      });
    case "binders/item/SET_CORPORATIONS":
      return produce(state, draft => {
        draft.corporations = action.payload;
      });
    case "binders/item/SET_CORPORATIONS_LOADING":
      return produce(state, draft => {
        draft.loading = action.payload;
      });
    case "binders/item/SET_INDUSTRIES":
      return produce(state, draft => {
        draft.industries = action.payload;
      });
    case "binders/item/SET_STATISTICS":
      return produce(state, draft => {
        draft.statistics = action.payload;
      });
    case "binders/item/SET_SAVE_ARTICLES":
      return produce(state, draft => {
        draft.saveArticles = action.payload;
      });
    case "binders/item/SET_EXTERNAL_PAGES":
      return produce(state, draft => {
        draft.externalPages = action.payload;
      });
    case "binders/item/SET_CORPORATION_DOWNLOAD":
      return produce(state, draft => {
        draft.corporationDownload = action.payload;
      });
    case "binders/item/SET_CORPORATION_MEMO":
      return produce(state, draft => {
        draft.corporations = state.corporations.map((c: Corporation) => {
          return c.cardId === action.payload.cardId
            ? { ...c, memo: action.payload.memo }
            : c;
        });
      });
    case "binders/item/CHANGE_ISADDING":
      return produce(state, draft => {
        draft.isAdding = action.payload.isAdding;
      });
    case "binders/item/SET_ARTICLE_OFFSET":
      return produce(state, draft => {
        draft.articleOffset = action.payload.articleOffset;
      });
    case "binders/item/SET_ARTICLE_LIMIT":
      return produce(state, draft => {
        draft.articleLimit = action.payload.articleLimit;
      });
    case "binders/item/ADD_ARTICLES":
      return produce(state, draft => {
        draft.articles = draft.articles.concat(action.payload.articles);
      });
    case "binders/item/SET_SELECTED_CORP_ITEMS":
      return produce(state, draft => {
        draft.selectedCorps = action.payload.selectedCorps;
      });
    default:
      return state;
  }
};
