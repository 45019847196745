import React from "react";
import styles from "./style.scss";
import cn from "classnames";
import LoadingSpinner from "components/Common/LoadingSpinner";

interface Item {
  name: string;
  code: string;
}

interface Props {
  title: string;
  items: Item[];
  key: string;
  selected?: string;
  isLoading?: boolean;
  onClick: (codeL: string) => void;
}

export const IndustryList: React.FC<Props> = ({
  title,
  items,
  key,
  selected,
  isLoading,
  onClick
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.list}>
        {isLoading ? (
          <div className={styles.emptyContainer}>
            <LoadingSpinner size="L" />
          </div>
        ) : (
          items.map((item, index) => (
            <div
              key={`${key}-row-${index}`}
              onClick={() => onClick(item.code)}
              className={cn(styles.row, {
                [styles.selected]: selected === item.code
              })}
            >
              {item.name}
            </div>
          ))
        )}
      </div>
    </div>
  );
};
