import React, { useEffect, useState } from "react";
import styles from "./style.scss";
import { trackAction } from "utils/helper/analytics";
import Select from "components/Common/Select";
import cn from "classnames";
import { RawInput } from "features/RawInput";
import { useDispatch } from "react-redux";

const limitOptions: { label: string; value: number }[] = [
  {
    label: "20件",
    value: 20
  },
  {
    label: "30件",
    value: 30
  },
  {
    label: "50件",
    value: 50
  },
  {
    label: "100件",
    value: 100
  }
];

export interface Props {
  onChange: (value: string) => void;
  onKeywordSearch: (keyword: string) => void;
  keyword: string;
  setKeyword: (keyword: string) => void;
  cardViewMode: boolean;
  changeLimit: (limit: number) => void;
  setCardViewMode: (mode: boolean) => void;
  limit: number;
  sort: string;
  isSearch: boolean;
}

export const Sort: React.FunctionComponent<Props> = props => {
  const dispatch = useDispatch();
  const [sortOptions, setSortOptions] = useState<
    {
      label: "更新順" | "登録順" | "関連度順";
      value: string;
    }[]
  >([
    {
      label: "更新順",
      value: "1"
    },
    {
      label: "登録順",
      value: "2"
    },
    {
      label: "関連度順",
      value: "3"
    }
  ]);
  const [showSort, setShowSort] = useState(true);
  const handleSelect = (option: {
    label: "更新順" | "登録順" | "関連度順";
    value: string;
  }) => {
    trackAction("myCollectionSortChange", option.label);
    props.onChange(option.value);
  };

  const handleLimit = (option: { label: string; value: number }) => {
    trackAction("myCollectionLimitChange", option.label);
    props.changeLimit(option.value);
  };

  const onSearch = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "Enter") {
      handleSearch(props.keyword);
    }
  };

  const handleSearch = (keyword: string) => {
    props.onKeywordSearch(keyword);
  };

  useEffect(() => {
    setShowSort(false);
    const oldSortOptions = [...sortOptions];
    const hasRelevance = oldSortOptions.some(option => option.value === "3");
    if (props.isSearch && !hasRelevance) {
      oldSortOptions.push({
        label: "関連度順",
        value: "3"
      });
      setSortOptions([...oldSortOptions]);
    }
    if (!props.isSearch && hasRelevance) {
      const index = oldSortOptions.findIndex(option => option.value === "3");
      oldSortOptions.splice(index, 1);
      setSortOptions([...oldSortOptions]);
    }
    Promise.resolve().then(() => {
      setShowSort(true);
    });
  }, [props.isSearch, sortOptions]);

  return (
    <div className={styles.sort}>
      <div className={styles.sortOptions}>
        <div>
          <RawInput
            rawText={props.keyword}
            onChangeRawText={rawText => {
              dispatch(props.setKeyword(rawText));
            }}
            placeholder="キーワードで絞り込み"
            addonPrepend={<span className={styles.searchIcon} />}
            addonAppend={
              props.keyword && (
                <span
                  className={styles.closeIcon}
                  onClick={() => handleSearch("")}
                />
              )
            }
            className={styles.rawInput}
            onKeyDown={event => onSearch(event)}
          />
        </div>
        <span className={styles.label}>並び順</span>
        {showSort && (
          <Select
            optionList={sortOptions}
            selectedOption={props.sort}
            onSelected={handleSelect}
            onClick={() => {
              trackAction("myCollectionSortClick");
            }}
            className={styles.selectBox}
          />
        )}
        {!props.cardViewMode && (
          <Select
            optionList={limitOptions}
            selectedOption={props.limit}
            onSelected={handleLimit}
            onClick={() => {
              trackAction("myCollectionLimitClick");
            }}
            className={styles.limitBox}
          />
        )}
      </div>

      <div className={styles.displayOptions}>
        <div className={styles.switchViewMode}>
          <span
            onClick={() => {
              trackAction("myCollectionCardClick");
              props.setCardViewMode(true);
            }}
            className={cn(styles.cardViewMode, {
              [styles.active]: props.cardViewMode
            })}
          />
          <span
            onClick={() => {
              trackAction("myCollectionListClick");
              props.setCardViewMode(false);
            }}
            className={cn(styles.tableViewMode, {
              [styles.active]: !props.cardViewMode
            })}
            data-testid="BinderPage-List-Components-Common-Sort-tableViewMode"
          />
        </div>
      </div>
    </div>
  );
};
