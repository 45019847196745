exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__container--2sN0s {\n  border: solid 1px #95a9b9;\n}\n\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__title--22LEN {\n  height: 42px;\n  background-color: #ccd7e1;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__row--eNlK9 {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  padding: 8px 16px;\n  line-height: 16px;\n  cursor: pointer;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__row--eNlK9:nth-child(odd) {\n  background-color: #fff;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__row--eNlK9:nth-child(even) {\n  background-color: #f2f6f8;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__row--eNlK9:hover {\n  background-color: #c5ddeb;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__row--eNlK9.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__selected--2UkSy {\n  color: #fff;\n  background-color: #49677f;\n}\n\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__list--26j7W {\n  overflow-y: auto;\n  height: 280px;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__list--26j7W .src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__emptyContainer--2qTUU {\n  height: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"container": "src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__container--2sN0s",
	"title": "src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__title--22LEN",
	"row": "src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__row--eNlK9",
	"selected": "src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__selected--2UkSy",
	"list": "src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__list--26j7W",
	"emptyContainer": "src-features-Modal-CompanyModal-Tab-Industry-IndustryList-style__emptyContainer--2qTUU"
};