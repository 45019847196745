import React, { Component } from "react";
import { connect } from "react-redux";

import { actionCreators } from "modules/common/SourceSelector";
import SourceSelector from "components/Common/CorpTypeSourceSelector/Implementation";

import { isEmpty } from "utils/helper/common";

class SourceSelectorContainer extends Component {
  constructor() {
    super();
    this.state = {
      isInit: false
    };
    this.onChangeSelection = this.onChangeSelection.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    const { onInit, savedItems } = props;
    if (!state.isInit && onInit) {
      onInit(savedItems);
      return {
        isInit: true
      };
    }
    return null;
  }

  pickDisplayItems(savedItems, items, isNeedMergeActive) {
    if (isEmpty(items)) return savedItems;

    // 保存データとマージする
    return savedItems.map(item => {
      const target = items.find(data => data.id.startsWith(item.id));
      // 保存済みデータにない場合はそのまま利用
      if (target == undefined) return item;
      // selected, corpCount のみ上書き
      const newItem = {
        ...item,
        selected: target.selected,
        corpCount: target.corpCount
      };
      if (target.hasOwnProperty("industrycorptopCondition")) {
        newItem.industrycorptopCondition = "";
      }
      // active マージが必要な場合のみマージする
      if (isNeedMergeActive) newItem.active = target.active;
      return newItem;
    });
  }

  // チェックボックス変更時に Store に保存
  onChangeSelection(items, flag) {
    if (!this.props.disabledSaveSelection) {
      this.props.saveSelection(items);
    }
    if (flag) {
      this.props.onChangeSourceSelector &&
        this.props.onChangeSourceSelector(items);
    }
  }

  render() {
    const {
      savedItems,
      items,
      isNeedMergeActive = false,
      ...otherProps
    } = this.props;

    const displayItems = this.pickDisplayItems(
      savedItems,
      items,
      isNeedMergeActive
    );

    return (
      <SourceSelector
        {...otherProps}
        items={displayItems}
        onChangeSourceSelector={this.onChangeSelection}
      />
    );
  }
}

const mapStateToProps = state => ({
  savedItems: state.reducers.commonSourceSelector.items
});

const mapDispatchToProps = dispatch => ({
  saveSelection: items => dispatch(actionCreators.saveSelection(items))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SourceSelectorContainer);
