import React from "react";
import styles from "./style.scss";

import ListItem from "./listItem";

const List = props => {
  return (
    <ul
      className={`
      ${styles.component}
      ${!props.opened && styles.empty}
    `}
    >
      {props.opened &&
        props.items.map((item, index) => {
          return (
            <ListItem
              key={index}
              index={index}
              item={item}
              focusedItemIndex={props.focusedItemIndex}
              setItemFocus={props.setItemFocus}
              selectCallback={props.selectCallback}
            />
          );
        })}
    </ul>
  );
};

export default List;
