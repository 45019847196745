import { MaAction } from "./actions";
import { Reducer } from "redux";
import produce from "immer";

interface Ma {
  title: string;
  content: string;
  announceDate: string;
  updateDate: string;
  payment: string;
  keyBody: string;
  forcedPaidConfirmFlg: string;
}

interface ReportMa {
  startNo: string;
  endNo: string;
  totalCount: string;
  maList: Ma[];
  transitionId: string;
}

export interface Loading {
  list: boolean;
}

interface MaState {
  reportMa?: ReportMa;
  isLoading: Loading;
}

const initialState: MaState = {
  reportMa: undefined,
  isLoading: {
    list: false
  }
};

export const ma: Reducer<MaState, MaAction> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case "article/ma/SET_REPORT_MA":
      return produce(state, draft => {
        draft.reportMa = action.payload.data;
      });
    case "article/ma/SET_LOADING":
      return produce(state, draft => {
        draft.isLoading[action.payload.key] = action.payload.value;
      });
    default:
      return state;
  }
};
