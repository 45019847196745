exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-features-Modal-CompanyModal-Tab-Industry-style__setting--3BMwc {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: justify;\n      justify-content: space-between;\n  margin-bottom: 8px;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-style__setting--3BMwc > div {\n  height: 30px;\n}\n\n.src-features-Modal-CompanyModal-Tab-Industry-style__listContainer--1hDJM {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-features-Modal-CompanyModal-Tab-Industry-style__listWrapper--pB7K3 {\n  height: 322px;\n  box-sizing: border-box;\n  width: calc(25% - 12px);\n}\n.src-features-Modal-CompanyModal-Tab-Industry-style__listWrapper--pB7K3 .src-features-Modal-CompanyModal-Tab-Industry-style__emptyContainer--wUsII {\n  width: 100%;\n  height: 100%;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-pack: center;\n      justify-content: center;\n  -ms-flex-align: center;\n      align-items: center;\n}\n\n.src-features-Modal-CompanyModal-Tab-Industry-style__arrow--205Kj {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  width: 16px;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-style__arrow--205Kj .src-features-Modal-CompanyModal-Tab-Industry-style__icon--1F3Bs {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: center;\n      justify-content: center;\n  width: 16px;\n  height: 32px;\n  background-color: #95a9b9;\n}\n.src-features-Modal-CompanyModal-Tab-Industry-style__arrow--205Kj .src-features-Modal-CompanyModal-Tab-Industry-style__icon--1F3Bs img {\n  width: 13px;\n  margin: 7px 1px 8px;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"setting": "src-features-Modal-CompanyModal-Tab-Industry-style__setting--3BMwc",
	"listContainer": "src-features-Modal-CompanyModal-Tab-Industry-style__listContainer--1hDJM",
	"listWrapper": "src-features-Modal-CompanyModal-Tab-Industry-style__listWrapper--pB7K3",
	"emptyContainer": "src-features-Modal-CompanyModal-Tab-Industry-style__emptyContainer--wUsII",
	"arrow": "src-features-Modal-CompanyModal-Tab-Industry-style__arrow--205Kj",
	"icon": "src-features-Modal-CompanyModal-Tab-Industry-style__icon--1F3Bs"
};