import React, { useLayoutEffect, useRef } from "react";
import styles from "./style.scss";

import Checkbox from "components/Common/Checkbox";
import { isEmpty } from "utils/helper/common";

const CorpTypeDropDown = props => {
  // 上場企業
  const listedCheckboxInfo = props.listedCheckboxInfo;
  // 海外企業
  const overseasCheckboxInfo = props.overseasCheckboxInfo;
  // 海外企業codes
  const overseasCorpCodes = props.overseasCorpCodes;
  // 海外非上場企業
  const unlistedOverseasCheckboxInfo = props.unlistedOverseasCheckboxInfo;
  // 海外非上場codes
  const unlistedOverseasCorpCodes = props.unlistedOverseasCorpCodes;

  const ref = useRef(null);

  useLayoutEffect(() => {
    if (ref.current) {
      ref.current.style.marginRight = `-${ref.current.clientWidth}px`;
    }
  });

  return (
    <div ref={ref} className={styles.dropDown}>
      <ul
        className={styles.vsList}
        data-testid="Common-CorpTypeSourceSelector-Implementation-CorpTypeDropDown-vsList"
      >
        <li
          className={styles.allCheckboxItem}
          data-testid="Common-CorpTypeSourceSelector-CorpTypeDropDown"
        >
          <Checkbox
            name={`すべて ${props.allCount}`}
            onChange={e => props.onChangeAllCheckbox(e.target.checked)}
            isActive={props.isAllActive}
            isChecked={props.isAllChecked}
            isIndeterminate={props.isAllIndeterminate}
            noHover={true}
            textClassName={styles.checkboxLabel}
          />
        </li>
        <li>
          {/* 上場 */}
          <ul className={styles.vsList}>
            <li
              className={styles.checkboxParentItem}
              data-testid="Common-CorpTypeSourceSelector-Implementation-CorpTypeDropDown-listedParentItem"
            >
              <Checkbox
                name={`${listedCheckboxInfo.item.label} ${listedCheckboxInfo.count}`}
                onChange={e => {
                  props.onChangeChildCheckbox(
                    listedCheckboxInfo.item.inneyCode,
                    e.target.checked
                  );
                }}
                className={styles.checkbox}
                isActive={listedCheckboxInfo.isActive}
                isChecked={listedCheckboxInfo.isChecked}
                isSmall={false}
                textClassName={styles.checkboxLabel}
                noHover={true}
              />
            </li>
            {/* 非上場 */}
            {props.checkboxGroups.map(checkboxGroup => {
              const totalCount = props.shouldShowCount
                ? checkboxGroup.childCheckbox.reduce(
                    (acc, cur) => acc + cur.count,
                    0
                  )
                : "";
              const isActive = !isEmpty(
                checkboxGroup.childCheckbox.find(({ isActive }) => isActive)
              );
              const isChecked = checkboxGroup.childCheckbox
                .filter(({ isActive }) => isActive)
                .every(({ isChecked }) => isChecked);
              const isIndeterminate =
                !isChecked &&
                !checkboxGroup.childCheckbox
                  .filter(({ isActive }) => isActive)
                  .every(({ isChecked }) => isChecked === false);

              return (
                <React.Fragment key={checkboxGroup.parentCheckbox.label}>
                  <li
                    className={styles.checkboxParentItem}
                    data-testid="Common-CorpTypeSourceSelector-Implementation-CorpTypeDropDown-unlistedParentItem"
                  >
                    <Checkbox
                      name={`${checkboxGroup.parentCheckbox.label} ${totalCount}`}
                      onChange={e => {
                        props.onChangeParentCheckbox(
                          checkboxGroup.childCheckbox.map(
                            child => child.item.inneyCode
                          ),
                          e.target.checked
                        );
                      }}
                      className={styles.checkbox}
                      isActive={isActive}
                      isChecked={isChecked}
                      isIndeterminate={isIndeterminate}
                      isSmall={false}
                      textClassName={styles.checkboxLabel}
                      noHover={true}
                    />
                  </li>
                  {checkboxGroup.childCheckbox.map(childCheckbox => {
                    const label =
                      props.allCount !== null
                        ? `${childCheckbox.item.label} ${childCheckbox.count}`
                        : childCheckbox.item.label;
                    const isActive = childCheckbox.isActive;

                    return (
                      <li
                        key={childCheckbox.item.inneyCode}
                        className={styles.checkboxChildItem}
                        data-testid="Common-CorpTypeSourceSelector-Implementation-CorpTypeDropDown-unlistedChildItem"
                      >
                        <Checkbox
                          name={label}
                          onChange={e => {
                            props.onChangeChildCheckbox(
                              childCheckbox.item.inneyCode,
                              e.target.checked
                            );
                          }}
                          className={styles.checkbox}
                          isActive={isActive}
                          isChecked={childCheckbox.isChecked}
                          isSmall={false}
                          textClassName={styles.checkboxLabel}
                          noHover={true}
                        />
                      </li>
                    );
                  })}
                </React.Fragment>
              );
            })}
            {/* 海外 */}
            {overseasCheckboxInfo && (
              <li
                className={styles.checkboxParentItem}
                data-testid="Common-CorpTypeSourceSelector-Implementation-CorpTypeDropDown-overseasParentItem"
              >
                <Checkbox
                  name={`${overseasCheckboxInfo.item.label} ${overseasCheckboxInfo.count}`}
                  onChange={e => {
                    props.onChangeParentCheckbox(
                      overseasCorpCodes,
                      e.target.checked,
                      overseasCheckboxInfo.isAreaIndeterminate ||
                        (unlistedOverseasCheckboxInfo
                          ? unlistedOverseasCheckboxInfo.isAreaIndeterminate
                          : false)
                    );
                  }}
                  className={styles.checkbox}
                  isActive={overseasCheckboxInfo.isActive}
                  isChecked={overseasCheckboxInfo.isChecked}
                  isIndeterminate={overseasCheckboxInfo.isAreaIndeterminate}
                  isSmall={false}
                  textClassName={styles.checkboxLabel}
                  noHover={true}
                />
              </li>
            )}
            {/* 海外非上場 */}
            {unlistedOverseasCheckboxInfo && (
              <li
                className={styles.checkboxParentItem}
                data-testid="Common-CorpTypeSourceSelector-Implementation-CorpTypeDropDown-unlistedOverseasParentItem"
              >
                <Checkbox
                  name={`${unlistedOverseasCheckboxInfo.item.label} ${unlistedOverseasCheckboxInfo.count}`}
                  onChange={e => {
                    props.onChangeParentCheckbox(
                      unlistedOverseasCorpCodes,
                      e.target.checked,
                      (overseasCheckboxInfo
                        ? overseasCheckboxInfo.isAreaIndeterminate
                        : false) ||
                        unlistedOverseasCheckboxInfo.isAreaIndeterminate
                    );
                  }}
                  className={styles.checkbox}
                  isActive={unlistedOverseasCheckboxInfo.isActive}
                  isChecked={unlistedOverseasCheckboxInfo.isChecked}
                  isIndeterminate={
                    unlistedOverseasCheckboxInfo.isAreaIndeterminate
                  }
                  isSmall={false}
                  textClassName={styles.checkboxLabel}
                  noHover={true}
                />
              </li>
            )}
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default CorpTypeDropDown;
