import React from "react";
import styles from "./style.scss";
import { CorporationTable } from "../../CorporationTable";

interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
}

interface Props {
  items: Item[];
  selectedCorp: Item[];
  maxSelectableNumber: number;
  onSelect: (corp: Item[]) => void;
}

export const Recent: React.FC<Props> = ({
  items,
  selectedCorp,
  maxSelectableNumber,
  onSelect
}) => {
  return (
    <div className={styles.container}>
      <CorporationTable
        items={items}
        selectedCorp={selectedCorp}
        maxSelectableNumber={maxSelectableNumber}
        onSelect={onSelect}
      />
    </div>
  );
};
