import React from "react";
import styles from "./style.scss";
import TextButton from "components/Common/Button/Text";
import Modal from "components/Common/Modal/Minimum";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  maxSelectNumber: number;
}

export const UpperLimitModal: React.FC<Props> = ({
  isOpen,
  onClose,
  maxSelectNumber
}) => {
  return (
    <Modal
      header="選択できなかった企業があります"
      onClose={onClose}
      isOpen={isOpen}
    >
      <div className={styles.modalContent}>
        <div className={styles.contentBody}>
          選択可能な企業数の上限は{maxSelectNumber}件です。
          <br />
          一括選択によって{maxSelectNumber}
          件を超えた場合、選択できていない企業があります。
          <br />
          <br />
          選択済みの企業をご確認いただき、適宜調整してください。
        </div>
        <div className={styles.buttonWrapper}>
          <TextButton onClick={onClose} className={styles.button}>
            OK
          </TextButton>
        </div>
      </div>
    </Modal>
  );
};
