import React, { useMemo } from "react";

import Checkbox from "components/Common/Checkbox";
import styles from "./style.scss";
import { ToolTip } from "features/ToolTip";
import EmptyView from "components/Common/EmptyView";

interface Item {
  companyCode: string;
  companyName: string;
  stkCode: string;
  source: string;
  companyKbn?: string;
}

interface Props {
  items: Item[];
  selectedCorp: Item[];
  maxSelectableNumber: number;
  onSelect: (corp: Item[]) => void;
}

export const CorporationTable: React.FC<Props> = ({
  items,
  selectedCorp,
  maxSelectableNumber,
  onSelect
}) => {
  const onClick = (corp: Item, checked: boolean) => {
    if (checked) {
      onSelect([...selectedCorp, corp]);
      return;
    }
    onSelect(
      selectedCorp.filter(item => item.companyCode !== corp.companyCode)
    );
  };

  const isAllChecked = useMemo(
    () =>
      items.length > 0 &&
      items.every(item =>
        selectedCorp.find(corp => corp.companyCode === item.companyCode)
      ),
    [items, selectedCorp]
  );

  const isLimit = selectedCorp.length >= maxSelectableNumber;

  const onAllCheck = (checked: boolean) => {
    if (checked) {
      const newCorps = items.filter(
        corp =>
          !!!selectedCorp.find(tmp => tmp.companyCode === corp.companyCode)
      );
      onSelect([...selectedCorp, ...newCorps]);
      return;
    }

    const excludeCorps = selectedCorp.filter(
      item => !!!items.find(tmp => tmp.companyCode === item.companyCode)
    );

    onSelect([...excludeCorps]);
  };

  const isSelectedEmpty = useMemo(() => {
    const selectedCompanyCodes = selectedCorp.map(item => item.companyCode);
    return !items.some(item => selectedCompanyCodes.includes(item.companyCode));
  }, [items, selectedCorp]);

  if (!items.length) return <EmptyView />;

  return (
    <div className={styles.tableContainer}>
      <table className={styles.vsTable}>
        <thead>
          <tr>
            <th data-testid="components-Tool-Screening-Sorporation-Item-Modern-Condition-Modal-CompanyModal-CorporationTable-allCheckbox">
              {isLimit && isSelectedEmpty ? (
                <ToolTip
                  position="right"
                  text={`項目選択数を${maxSelectableNumber}件以下にすることで選択可能になります。`}
                  tooltipWidth={354}
                  slideLength="0"
                >
                  <div className={styles.checkboxContainer}>
                    <Checkbox isChecked={false} isActive={false} />
                  </div>
                </ToolTip>
              ) : (
                <Checkbox
                  isChecked={isLimit || isAllChecked}
                  onChange={() => {
                    if (isLimit) {
                      onAllCheck(false);
                      return;
                    }
                    onAllCheck(!isAllChecked);
                  }}
                />
              )}
            </th>
            <th>株式コード</th>
            <th>ソース</th>
            <th>企業名</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => {
            const target = selectedCorp.find(
              corp => corp.companyCode === item.companyCode
            );
            const isChecked = Boolean(target);

            return (
              <tr
                key={`recent-item-${index}`}
                data-testid="components-Tool-Screening-Sorporation-Item-Modern-Condition-Modal-CompanyModal-CorporationTable-tbodyRow"
                onClick={() => onClick(item, !target)}
              >
                <td>
                  {!isLimit || isChecked ? (
                    <Checkbox isChecked={isChecked} />
                  ) : (
                    <ToolTip
                      position="right"
                      text={`項目選択数を${maxSelectableNumber}件以下にすることで選択可能になります。`}
                      tooltipWidth={354}
                      slideLength="0"
                    >
                      <div className={styles.checkboxContainer}>
                        <Checkbox isChecked={isChecked} isActive={false} />
                      </div>
                    </ToolTip>
                  )}
                </td>
                <td>{item.stkCode}</td>
                <td>{item.source}</td>
                <td>{item.companyName}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
