var escape = require("../../../../../node_modules/css-loader/lib/url/escape.js");
exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Suggest-Input-style__search--2Ilmx {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  -ms-flex-pack: start;\n      justify-content: flex-start;\n  box-sizing: border-box;\n  height: 40px;\n  padding: 0 10px;\n  border: 1px solid #c6e0f0;\n  border-radius: 6px;\n  outline: none;\n  background: #fff;\n  box-shadow: 0 2px 1px 0 rgba(84, 153, 195, 0.25) inset;\n}\n.src-components-Common-Suggest-Input-style__search--2Ilmx.src-components-Common-Suggest-Input-style__focus--3qWks {\n  outline: 1px dotted #212121;\n  outline: 3px auto -webkit-focus-ring-color;\n}\n.src-components-Common-Suggest-Input-style__search--2Ilmx .src-components-Common-Suggest-Input-style__icon--zn07f {\n  width: 24px;\n  height: 24px;\n  background: url(" + escape(require("images/icon/search_navy.svg")) + ");\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"search": "src-components-Common-Suggest-Input-style__search--2Ilmx",
	"focus": "src-components-Common-Suggest-Input-style__focus--3qWks",
	"icon": "src-components-Common-Suggest-Input-style__icon--zn07f"
};