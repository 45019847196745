exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "/* stylelint-disable */\n\n.src-components-Common-Suggest-List-style__component--3sTjD {\n  position: absolute;\n  z-index: 5;\n  width: 100%;\n  margin: 0;\n  padding: 0;\n  list-style: none;\n  cursor: pointer;\n  border: 1px solid #c6e0f0;\n  border-top: none;\n  border-radius: 4px;\n  background-color: #fff;\n  font-size: 13px;\n}\n.src-components-Common-Suggest-List-style__component--3sTjD.src-components-Common-Suggest-List-style__empty--f-o19 {\n  border: none;\n}\n\n.src-components-Common-Suggest-List-style__item--3HY3h {\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-align: center;\n      align-items: center;\n  margin-right: 0;\n  padding: 8px;\n  background-color: #fff;\n}\n.src-components-Common-Suggest-List-style__item--3HY3h.src-components-Common-Suggest-List-style__focused--gBkcu {\n  background-color: #eaf1f5;\n}", ""]);

// exports
exports.locals = {
	"lHeaderHeight": "56px",
	"lCompanyHeight": "114px",
	"h2FontSize": "28px",
	"h3FontSize": "20px",
	"bodyLineHeight": "20px",
	"lGnShrinkWindowWidth": "1591px",
	"tableVerticalPadding": "8px",
	"tableHorizontalPadding": "16px",
	"lPersonnelBreakWidth": "1599px",
	"cNavigationAccent": "#f57f17",
	"cTableBodyOdd": "#fff",
	"cTableBodyEven": "#f2f6f8",
	"cTableHover": "#c5ddeb",
	"cTableHeadOdd": "#eaf1f5",
	"cTableHeadEven": "#dee8ef",
	"zPageLoading": "2",
	"component": "src-components-Common-Suggest-List-style__component--3sTjD",
	"empty": "src-components-Common-Suggest-List-style__empty--f-o19",
	"item": "src-components-Common-Suggest-List-style__item--3HY3h",
	"focused": "src-components-Common-Suggest-List-style__focused--gBkcu"
};