import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./style.scss";
import { useTable } from "./hook";
import { usePersonalBinder } from "../../PersonalBinderList/hook";
import { useToast } from "hooks/useToast";
import { BinderItem } from "models/BinderItem";
import cn from "classnames";
import { BinderActionSet, BinderType } from "models/BinderItem";
import { ToolTip } from "features/ToolTip";
import { BinderActions } from "../BinderActions";
import { trackAction } from "utils/helper/analytics";
import { useIpAuthFlg } from "hooks/useIpAuthFlg";
import { preloadImage } from "utils/helper/preload";
import DuplicateImage from "images/icon/icn_duplicate_collection_hover.svg";
import { isExternalUrl, replaceUrl } from "utils/helper/replaceDescriptionUrl";

interface Props {
  type: BinderType;
  items: BinderItem[];
  setBinderActionModal?: (value: BinderActionSet) => void;
  tableWrapperRef?: React.MutableRefObject<HTMLDivElement | null>;
}

export const Table: React.FunctionComponent<Props> = props => {
  const { updatePinned, authority, updateMailCollection, limit } = useTable();

  const { fetchPersonalBinders } = usePersonalBinder();

  const toast = useToast();

  const ipAuthFlg = useIpAuthFlg();

  const [linkOnHover, setLinkOnHover] = useState({
    x: 0,
    y: 0,
    over: false,
    url: ""
  });

  const onClickPin = async (prevPin: boolean, binderId: string) => {
    await updatePinned(binderId);
    await fetchPersonalBinders(limit);

    if (prevPin) {
      toast.setMessages(["ピン留めを解除しました"]);
      return;
    }
    toast.setMessages(["ピン留めをしました"]);
  };

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);

  const onChangePosition = () => {
    if (!wrapperRef.current || !listRef.current) return;
    const position = wrapperRef.current.getBoundingClientRect();
    const listPosition = listRef.current.getBoundingClientRect();

    const top = position.top + position.height;

    listRef.current.style.position = "fixed";
    listRef.current.style.top =
      window.innerHeight / 2 > position.top
        ? `${top}px`
        : `${top - listPosition.height - position.height}px`;
    listRef.current.style.left = `${position.left -
      listPosition.width +
      position.width}px`;
  };

  const onChangeRef = (refs: {
    listRef: HTMLUListElement | null;
    wrapperRef: HTMLDivElement | null;
  }) => {
    listRef.current = refs.listRef;
    wrapperRef.current = refs.wrapperRef;
  };

  const aTagMouseOver = (ev: MouseEvent) => {
    const target = (ev.target as unknown) as HTMLDivElement;
    setLinkOnHover({
      x: ev.clientX + 5,
      y: ev.clientY + 10,
      over: true,
      url: target.getAttribute("href") ?? ""
    });
  };

  const aTagMouseOut = () => {
    setLinkOnHover({
      x: 0,
      y: 0,
      over: false,
      url: ""
    });
  };

  const descriptionRef = useRef<HTMLTableSectionElement>(null);

  useEffect(() => {
    if (descriptionRef) {
      const links = descriptionRef.current?.getElementsByTagName("a");

      if (links) {
        for (let i = 0; i < links.length; i++) {
          links.item(i)?.addEventListener("mouseenter", aTagMouseOver, false);
          links.item(i)?.addEventListener("mouseleave", aTagMouseOut, false);
        }
        return () => {
          for (let i = 0; i < links.length; i++) {
            links.item(i)?.removeEventListener("mouseenter", aTagMouseOver);
            links.item(i)?.removeEventListener("mouseleave", aTagMouseOut);
          }
        };
      }
    }
  });

  useEffect(() => {
    if (!props.tableWrapperRef?.current) return;
    preloadImage(DuplicateImage);

    window.addEventListener("scroll", onChangePosition);
    props.tableWrapperRef.current.addEventListener("scroll", onChangePosition);

    return () => {
      window.removeEventListener("scroll", onChangePosition);
      props.tableWrapperRef?.current?.removeEventListener(
        "scroll",
        onChangePosition
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const body = props.items.map((item, index) => {
    return (
      <tr
        key={index}
        data-testid={`BinderPage-List-Components-Common-Table-tbody-tr-${index}`}
        className={
          props.type === "nikkei" && !ipAuthFlg ? styles.isNID : styles.notNID
        }
      >
        {props.type === "personal" && authority?.pinned && (
          <td
            className={styles.pin}
            data-testid="BinderPage-List-Components-Common-Table-pin"
          >
            {item.binderInfo.pinnedFlg === "0" ? (
              <ToolTip text="ピン留めする" position="right">
                <div
                  className={styles.pinDefault}
                  onClick={() => {
                    onClickPin(false, item.binderInfo.binderId);
                    trackAction("onClickMyCollectionPinned", {
                      location: "コレクション",
                      type: "一覧",
                      id: item.binderInfo.binderId,
                      name: item.binderInfo.caption,
                      action: "ピン留め"
                    });
                  }}
                  data-testid="BinderPage-List-Components-Common-Table-pinDefault"
                ></div>
              </ToolTip>
            ) : (
              <ToolTip text="ピン留めを解除する" position="right">
                <div
                  className={styles.pinActive}
                  onClick={() => {
                    onClickPin(true, item.binderInfo.binderId);
                    trackAction("onClickMyCollectionPinned", {
                      location: "コレクション",
                      type: "一覧",
                      id: item.binderInfo.binderId,
                      name: item.binderInfo.caption,
                      action: "ピン留め解除"
                    });
                  }}
                  data-testid="BinderPage-List-Components-Common-Table-pinActive"
                ></div>
              </ToolTip>
            )}
          </td>
        )}
        {props.type === "nikkei" && !ipAuthFlg && (
          <td>
            <ToolTip
              text="マイコレクションにコピー"
              position="right"
              className={styles.tooltip}
            >
              <div
                onClick={() => {
                  trackAction("onCopyToMyCollection", {
                    id: item.binderInfo.binderId,
                    name: item.binderInfo.caption,
                    type: "一覧"
                  });
                  props.setBinderActionModal &&
                    props.setBinderActionModal({
                      type: "clone",
                      binderInfo: item.binderInfo
                    });
                }}
                className={styles.copyToMyCollection}
                data-testid="BinderPage-List-Components-Common-Table-copyToMyCollection"
              >
                <span className={styles.image} />
              </div>
            </ToolTip>
          </td>
        )}
        <td className={styles.collectionName}>
          <Link
            to={`/binder/${item.binderInfo.binderId}/article?type=${props.type}`}
          >
            {item.binderInfo.caption}
          </Link>
        </td>
        <td
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: replaceUrl(item.binderInfo.description)
          }}
        />
        <td className={styles.contentsData}>
          <div className={styles.contents}>
            <div className={styles.tabs}>
              <Link
                to={`/binder/${item.binderInfo.binderId}/corp?type=${props.type}`}
              >
                <span>企業</span>
                <span className={styles.count}>{item.corpTotalCount}</span>
              </Link>
            </div>
            <div className={styles.tabs}>
              <Link
                to={`/binder/${item.binderInfo.binderId}/industry?type=${props.type}`}
              >
                <span>業界</span>
                <span className={styles.count}>{item.industryTotalCount}</span>
              </Link>
            </div>
            <div className={styles.tabs}>
              <Link
                to={`/binder/${item.binderInfo.binderId}/saveArticle?type=${props.type}`}
              >
                <span>保存記事</span>
                <span className={styles.count}>
                  {item.saveArticleTotalCount}
                </span>
              </Link>
            </div>
            <div className={styles.tabs}>
              <Link
                to={`/binder/${item.binderInfo.binderId}/statistics?type=${props.type}`}
              >
                <span>統計</span>
                <span className={styles.count}>
                  {item.statisticsTotalCount}
                </span>
              </Link>
            </div>
            <div className={styles.tabs}>
              <Link
                to={`/binder/${item.binderInfo.binderId}/externalPage?type=${props.type}`}
              >
                <span>ページ</span>
                <span className={styles.count}>
                  {item.externalPageTotalCount}
                </span>
              </Link>
            </div>
          </div>
        </td>
        <td className={styles.updateDate}>{item.binderInfo.updateDate}</td>
        {props.type !== "nikkei" && authority?.mailAlert && (
          <td className={styles.mail}>
            <ToolTip
              text={
                item.binderInfo.mailAlertFlg === "1"
                  ? "コレクションメールを解除"
                  : "コレクションメールを設定"
              }
              className={styles.shadow}
            >
              <div
                className={
                  item.binderInfo.mailAlertFlg === "1"
                    ? styles.mailActive
                    : styles.mailDefault
                }
                onClick={async () => {
                  trackAction("myCollectionMailClick", {
                    id: item.binderInfo.binderId,
                    name: item.binderInfo.caption
                  });
                  await updateMailCollection(item.binderInfo.binderId);
                  fetchPersonalBinders(limit);
                  item.binderInfo.mailAlertFlg === "1"
                    ? toast.setMessages(["コレクションメールを解除しました"])
                    : toast.setMessages(["コレクションメールを設定しました"]);
                }}
                data-testid="BinderPage-List-Components-Common-Table-mail"
              />
            </ToolTip>
          </td>
        )}
        {props.type !== "nikkei" && authority?.share && (
          <td className={styles.share}>
            <ToolTip
              text={item.binderInfo.sharedFlg ? "共有中" : "共有する"}
              className={styles.shadow}
            >
              <div
                className={
                  item.binderInfo.sharedFlg
                    ? styles.shareActive
                    : styles.shareDefault
                }
                onClick={() => {
                  trackAction("myCollectionShareClick", {
                    id: item.binderInfo.binderId,
                    name: item.binderInfo.caption
                  });

                  props.setBinderActionModal &&
                    props.setBinderActionModal({
                      type: "share",
                      binderInfo: item.binderInfo
                    });
                }}
                data-testid="BinderPage-List-Components-Common-Table-share"
              />
            </ToolTip>
          </td>
        )}
        {props.type !== "nikkei" && (
          <td className={styles.binderAction}>
            <div className={styles.tabs}>
              <BinderActions
                binderInfo={item.binderInfo}
                setBinderActionModal={props.setBinderActionModal}
                onChangeRef={onChangeRef}
                onOpen={onChangePosition}
              />
            </div>
          </td>
        )}
      </tr>
    );
  });

  return (
    <>
      <table
        className={cn(styles.vsTable, {
          [styles.personalTable]: props.type === "personal",
          [styles.nikkeiTable]: props.type !== "personal"
        })}
        data-testid="BinderPage-List-Components-Common-Table"
      >
        <thead>
          <tr>
            {props.type !== "nikkei" || !ipAuthFlg ? <th></th> : null}
            <th
              colSpan={
                props.type === "personal" ? (authority?.pinned ? 1 : 0) : 1
              }
            >
              コレクション名
            </th>
            <th>説明文</th>
            <th>コンテンツ</th>
            <th>更新日</th>
            {props.type !== "nikkei" ? <th colSpan={3}></th> : null}
          </tr>
        </thead>
        <tbody ref={descriptionRef}>{body}</tbody>
      </table>
      {linkOnHover.over && isExternalUrl(linkOnHover.url) && (
        <div
          style={{
            top: linkOnHover.y + "px",
            left: linkOnHover.x + "px",
            position: "fixed"
          }}
          className={cn(styles.linkOnHover)}
        >
          <div>リンク先のサイトは日経バリューサーチとは異なるサイトです。</div>
          <a>{linkOnHover.url}</a>
        </div>
      )}
    </>
  );
};
