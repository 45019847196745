import React, { Component } from "react";
import styles from "./style.scss";

import TextInput from "components/Common/TextInput";

const defaultProps = {
  isTopSearch: false
};

class Input extends Component {
  constructor() {
    super();

    this.state = {
      isInputJapanese: false,
      preventing: false, // 二重検索防止フラグ
      isFocus: false
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onWindowClick = this.onWindowClick.bind(this);
    this.preventDoubleSearch = this.preventDoubleSearch.bind(this);
  }

  componentDidMount() {
    window.addEventListener("click", this.onWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.onWindowClick);
  }

  scrollUp() {
    const { items, listOpened, focusedItemIndex } = this.props;

    if (items.length === 0 || !listOpened) return;

    let nextItemIndex = focusedItemIndex - 1;
    if (focusedItemIndex === -1) {
      nextItemIndex = items.length - 1;
    }
    if (nextItemIndex !== focusedItemIndex) {
      this.props.setItemFocus(nextItemIndex);
    }
  }

  scrollDown() {
    const { items, listOpened, focusedItemIndex } = this.props;

    if (items.length === 0 || !listOpened) return;

    let nextItemIndex = focusedItemIndex + 1;
    if (items.length - 1 < nextItemIndex) {
      nextItemIndex = -1;
    }
    if (nextItemIndex !== focusedItemIndex) {
      this.props.setItemFocus(nextItemIndex);
    }
  }

  onKeyDown(event) {
    // 日本語入力時はリスト操作しない
    if (this.state.isInputJapanese) return;

    switch (event.key) {
      case "ArrowUp":
        this.scrollUp();
        // カーソルが動くのを止める
        event.preventDefault();
        break;
      case "ArrowDown":
        this.scrollDown();
        break;
      case "Escape":
        this.props.closeSuggestList();
        break;
      case "Enter": {
        const keyword = this.props.keyword;
        if (keyword.length <= 0) return;

        if (0 <= this.props.focusedItemIndex) {
          this.props.selectCallback(
            this.props.items[this.props.focusedItemIndex]
          );
        } else if (!this.state.preventing) {
          this.props.onEnterCallback(keyword);
          this.preventDoubleSearch();
        }
        break;
      }
    }
  }

  onClick() {
    this.props.closeSuggestList();
  }

  onWindowClick() {
    this.props.closeSuggestList();
  }

  onFocus() {
    this.setState({ isFocus: true });
  }

  onBlur() {
    this.setState({ isFocus: false });
  }

  preventDoubleSearch() {
    // 1秒間は再検索しないようにする
    this.setState({ preventing: true }, () => {
      setTimeout(() => {
        this.setState({ preventing: false });
      }, 1000);
    });
  }

  render() {
    const props = {
      value: this.props.keyword,
      onChange: e => this.props.updateKeyword(e.target.value),
      onKeyUp: e => {
        if (e.keyCode === 244) return this.setState({ isInputJapanese: false });
        if (e.keyCode === 243) return this.setState({ isInputJapanese: true });
      },
      onKeyDown: this.onKeyDown,
      onCompositionStart: () => this.setState({ isInputJapanese: true }),
      onCompositionEnd: e => {
        if (e.data.replace("　", "").length > 0) {
          this.setState({ isInputJapanese: false });
        }
      },
      onClick: this.onClick,
      placeholder: this.props.placeholder,
      isTopSearch: this.props.isTopSearch
    };
    if (this.props.title !== "") props["title"] = this.props.title;

    return (
      <label
        className={`${styles.search} ${this.state.isFocus && styles.focus}`}
      >
        <span className={styles.icon} />
        <TextInput
          {...props}
          isNoShadow={true}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        />
      </label>
    );
  }
}

Input.defaultProps = defaultProps;

export default Input;
