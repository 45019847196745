import React, { useState, useEffect, useRef, useCallback } from "react";
import styles from "./style.scss";
import Modal from "components/Common/Modal";
import { SearchModeModal } from "features/BinderPage/Common/SearchModeModal";
import { useFormulaSearch } from "./hook";
import { QueryBuilder } from "./QueryBuilder";
import Button from "components/Common/Button/Text";
import cn from "classnames";
import { Witch } from "modules/operandRawSearch";
import { trackAction } from "utils/helper/analytics";
import { AdvancedSearch } from "features/AboutSearch/AdvancedSearch";
import { convertSearchKeyword, splitKeyword } from "utils/helper/searchTag";
import { RawOperand } from "models/GlobalSearch";
import { isEmpty } from "utils/helper/common";

interface Props {
  onChangeRawOperand: (rawOperand: RawOperand) => void;
  rawOperand: RawOperand;
  search?: () => void;
  onChangeRawText: (keyword: string) => void;
  witch: Witch;
  keyword: string;
  disabled?: boolean;
  atlasPageName: string;
  canOpenCaution?: boolean;
  isOpenHelp?: boolean;
  setIsOpenHelp: (isOpen: boolean) => void;
  showTitle?: boolean;
  switchOnRightBottom?: boolean;
  validateSwtich?: boolean;
  onSwitchError?: (msg: string) => void;
  currentError?: string;
  onlyNotErrorMsg?: string;
}

interface SelectedInf {
  key: number | undefined;
  rawOperand: RawOperand;
  rawText: string;
}

const initialOperandRaw = { and: "", or: "", not: "" };
const OPERANDS = ["and", "or", "not", "(", ")", "AND", "OR", "NOT"];

export const FormulaSearch: React.FC<Props> = ({
  onChangeRawText,
  onChangeRawOperand,
  rawOperand,
  search,
  witch,
  keyword,
  disabled = false,
  atlasPageName,
  canOpenCaution = false,
  isOpenHelp = false,
  setIsOpenHelp,
  showTitle = true,
  switchOnRightBottom = false,
  validateSwtich = false,
  onSwitchError = () => {},
  currentError = "",
  onlyNotErrorMsg = ""
}) => {
  const [selected, setSelected] = useState<SelectedInf>({
    key: undefined,
    rawOperand: initialOperandRaw,
    rawText: ""
  });
  const {
    advancedMode,
    historyModalOpenStatus,
    setHistoryModalStatus,
    setMode,
    history,
    advancedHistory,
    resetHistory,
    resetAdvancedHistory
  } = useFormulaSearch();

  const [isOpenDropHistory, setIsOpenDropHistory] = useState(false);
  const [focusHistoryIndex, setFocusHistoryIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  const switchMode = useCallback(() => {
    if (advancedMode[witch]) {
      onChangeRawText(convertSearchKeyword(rawOperand));
      onChangeRawOperand({ and: "", or: "", not: "" });
    } else {
      onChangeRawText("");
      onChangeRawOperand({
        and: splitKeyword(keyword)
          .filter(word => !OPERANDS.includes(word))
          .join(" "),
        or: "",
        not: ""
      });
    }
  }, [
    advancedMode,
    witch,
    onChangeRawOperand,
    rawOperand,
    onChangeRawText,
    keyword
  ]);

  // キーワードの動作を復元します。元のパラメータが 3 つある場合は、デフォルトで 3 つのパラメータが表示されます。
  useEffect(() => {
    if (rawOperand.and || rawOperand.or || rawOperand.not) {
      setMode(true, witch);
    }
  }, [rawOperand, witch, setMode]);

  // 範囲外の押下でポップアップが消えるようにする
  const wrapperRef = useRef<HTMLFormElement>(null);

  const onClickOutside = (event: MouseEvent) => {
    const target: any = event.target;
    if (
      wrapperRef.current &&
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      !wrapperRef.current.contains(target)
    ) {
      setIsOpenDropHistory(false);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case "ArrowUp": {
        // カーソルが移動しないようにする
        event.preventDefault();
        const setIndex = focusHistoryIndex - 1;

        if (history[witch].length > 0) {
          setIsOpenDropHistory(true);
        }

        if (setIndex >= 1) {
          setFocusHistoryIndex(setIndex);
        } else {
          setFocusHistoryIndex(
            history[witch].length >= 5 ? 5 : history[witch].length
          );
        }
        break;
      }
      case "ArrowDown": {
        // カーソルが移動しないようにする
        event.preventDefault();
        const setIndex = focusHistoryIndex + 1;

        if (history[witch].length > 0) {
          setIsOpenDropHistory(true);
        }

        if (setIndex <= 5 && setIndex <= history[witch].length) {
          setFocusHistoryIndex(setIndex);
        } else {
          setFocusHistoryIndex(history[witch].length === 0 ? 0 : 1);
        }
        break;
      }
      case "Escape": {
        setFocusHistoryIndex(0);
        setIsOpenDropHistory(false);
        break;
      }
      case "Enter": {
        if (focusHistoryIndex !== 0) {
          event.preventDefault();
          onChangeRawOperand({
            ...rawOperand
            // rawText: history[witch][focusHistoryIndex - 1]
          });
          setIsOpenDropHistory(false);
          setFocusHistoryIndex(0);
        }
        break;
      }
    }
  };

  const atlasParamsCreate = () => {
    const condition = advancedMode[witch] ? "無効化" : "有効化";

    return { condition };
  };

  const trackClickChangeAdvancedMode = () => {
    const { condition } = atlasParamsCreate();

    trackAction("clickChangeAdvancedMode", {
      pageName: atlasPageName,
      condition: condition
    });
  };

  const handleRawInputKeyPress = (event: React.KeyboardEvent<Element>) => {
    if (event.key === "Enter") {
      search && search();
    }
  };

  const openModal = (keyword: string) => {
    setModalOpen(!isEmpty(keyword));
    if (isEmpty(keyword)) {
      switchMode();
      setMode(!advancedMode[witch], witch);
      trackClickChangeAdvancedMode();
    }
  };

  const handleSwitchMode = () => {
    if (canOpenCaution && keyword) {
      openModal(keyword);
    } else {
      // B2B_NVS_FR_2018-5557 コレクションのキーワード設定に新機能を追加
      //【含まない】にコンテンツがある場合、【すべてを含む】と【いずれかを含む】の少なくとも 1 つはコンテンツを持っている必要があります。それ以外の場合、補助入力モードから通常の入力モードに切り替えることはできません。
      if (currentError === onlyNotErrorMsg) {
        onSwitchError("");
      }
      if (
        validateSwtich &&
        advancedMode[witch] &&
        !isEmpty(rawOperand.not) &&
        isEmpty(rawOperand.and) &&
        isEmpty(rawOperand.or)
      ) {
        onSwitchError(
          "「すべてを含む」もしくは「いずれかを含む」にキーワードを設定してください"
        );
        return;
      }
      switchMode();
      setMode(!advancedMode[witch], witch);
      trackClickChangeAdvancedMode();
    }
  };

  useEffect(() => {
    window.addEventListener("click", e => onClickOutside(e));
    return window.removeEventListener("click", e => onClickOutside(e));
  }, []);

  useEffect(() => {
    if (witch === "collection") {
      setMode(false, "collection");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [witch]);

  const reversedHistory = [...history[witch]].reverse();
  const reversedAdvancedHistory = [...advancedHistory[witch]].reverse();

  return (
    <>
      <SearchModeModal
        title={"入力中のキーワードは破棄されます"}
        copyText={keyword}
        onClickCloseButton={() => setModalOpen(false)}
        isOpen={modalOpen && canOpenCaution}
        onClickChangeButton={() => {
          switchMode();
          setMode(!advancedMode[witch], witch);
          trackClickChangeAdvancedMode();
        }}
        description="現在入力中のキーワードを、切替後の画面（入力補助モード）に引き継ぐことはできません。切替後も引き続き参照したい場合は、現在入力中の情報をコピーし、テキストエディタ等に貼り付けてご活用ください。"
        keepButtonText="モードを切り替える"
      />
      <div
        className={cn(styles.title, {
          [styles.disclosure]: witch === "disclosure"
        })}
      >
        {witch !== "disclosure" && showTitle && (
          <div className={styles.word}>キーワード</div>
        )}
        {!switchOnRightBottom && (
          <div className={styles.selectSwitch}>
            {witch !== "collection" && (
              <div className={styles.history}>
                <span className={styles.historyIcon} />
                <span
                  onClick={() => {
                    setHistoryModalStatus(true);
                  }}
                >
                  検索履歴
                </span>
              </div>
            )}
            <div className={styles.mode}>
              <span className={styles.modeIcon} />
              <span
                onClick={handleSwitchMode}
                data-testid="FormulaSearch-switchMode"
              >
                検索モード切替
              </span>
            </div>
            <span
              className={styles.searchHelpIcon}
              onClick={() => {
                setIsOpenHelp(true);
              }}
            />
          </div>
        )}
      </div>
      {!advancedMode[witch] && (
        <form
          onSubmit={e => {
            e.preventDefault();
            onChangeRawText(keyword.trim());
            search && search();
          }}
          ref={wrapperRef}
        >
          <div
            className={cn(styles.input, {
              [styles.openDropHistory]: isOpenDropHistory,
              [styles.disabled]: disabled
            })}
          >
            <span className={styles.searchIcon} />
            <input
              data-testid="OperandRawSearch-FormulaSearch-text-input"
              className={styles.inputKeyword}
              type="text"
              value={keyword}
              onChange={e => onChangeRawText(e.target.value)}
              onFocus={() => {
                if (witch !== "collection" && history[witch].length > 0) {
                  setIsOpenDropHistory(true);
                }
              }}
              onKeyDown={event => onKeyDown(event)}
              disabled={disabled}
            />
            {isOpenDropHistory && (
              <div className={cn(styles.dropHistory)}>
                {reversedHistory.map((keywords: string, index: number) => {
                  if (index >= 5) {
                    return;
                  }
                  return (
                    <div
                      className={cn(
                        styles.keywordHistory,
                        styles.keywordDropHistory,
                        { [styles.active]: index === focusHistoryIndex - 1 }
                      )}
                      key={index}
                    >
                      <div
                        className={cn(styles.keywordHistoryText)}
                        onClick={() => {
                          onChangeRawText(keywords);
                          setIsOpenDropHistory(false);
                        }}
                      >
                        <span className={styles.historyIcon} />
                        <span>{keywords}</span>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </form>
      )}
      {advancedMode[witch] && (
        <QueryBuilder
          rawOperand={rawOperand}
          onChangeRawOperand={onChangeRawOperand}
          historyMode={false}
          onEnter={handleRawInputKeyPress}
          disabled={disabled}
        />
      )}
      <div className={styles.infoWrapper}>
        <div className={cn(styles.info)}>
          （i）語順も含めて完全一致するものを検索したい場合は、対象を半角二重引用符（&quot;&quot;）で括って入力してください
        </div>
        {switchOnRightBottom && (
          <div
            className={`${styles.selectSwitch} ${switchOnRightBottom &&
              styles.switchOnRightBottom}`}
          >
            {witch !== "collection" && (
              <div className={styles.history}>
                <span className={styles.historyIcon} />
                <span
                  onClick={() => {
                    setHistoryModalStatus(true);
                  }}
                >
                  検索履歴
                </span>
              </div>
            )}
            <div className={styles.mode}>
              <span className={styles.modeIcon} />
              <span
                onClick={handleSwitchMode}
                data-testid="FormulaSearch-switchMode"
              >
                検索モード切替
              </span>
            </div>
            <span
              className={styles.searchHelpIcon}
              onClick={() => {
                setIsOpenHelp(true);
              }}
            />
          </div>
        )}
      </div>
      <Modal
        className={styles.historyModal}
        title={"検索履歴"}
        onClose={() => setHistoryModalStatus(false)}
        isOpen={historyModalOpenStatus}
        uncontrolled
      >
        <div className={styles.historyWrap}>
          {advancedMode[witch] &&
            reversedAdvancedHistory.map(
              (operandRaw: RawOperand, index: number) => {
                return (
                  <div className={cn(styles.advancedHistory)} key={index}>
                    <QueryBuilder
                      rawOperand={operandRaw}
                      historyMode={true}
                      disabled={true}
                      selected={selected.key === index}
                      onClick={() => {
                        setSelected({
                          ...selected,
                          key: index,
                          rawOperand: operandRaw
                        });
                      }}
                    />
                    <span
                      className={cn(
                        styles.historyDelete,
                        styles.advancedHistoryDelete
                      )}
                      onClick={() => {
                        const setHistory = Object.assign(
                          [],
                          reversedAdvancedHistory
                        );
                        setHistory.splice(index, 1);
                        resetAdvancedHistory(setHistory.reverse(), witch);
                      }}
                    />
                  </div>
                );
              }
            )}
          {!advancedMode[witch] &&
            reversedHistory.map((keywords: string, index: number) => {
              return (
                <div
                  className={cn(styles.keywordHistory, {
                    [styles.selected]: selected.key === index
                  })}
                  key={index}
                >
                  <div
                    className={cn(styles.keywordHistoryText)}
                    onClick={() => {
                      setSelected({
                        ...selected,
                        key: index,
                        rawOperand: {
                          ...rawOperand
                        },
                        rawText: keywords
                      });
                    }}
                  >
                    <span className={styles.historyIcon} />
                    <span>{keywords}</span>
                  </div>
                  <span
                    className={cn(styles.historyDelete)}
                    onClick={() => {
                      const setHistory = Object.assign([], reversedHistory);
                      setHistory.splice(index, 1);
                      resetHistory(setHistory.reverse(), witch);
                    }}
                  />
                </div>
              );
            })}
        </div>
        <div className={cn(styles.buttons, styles.historyButtons)}>
          <Button
            className={styles.cancelButton}
            onClick={() => setHistoryModalStatus(false)}
          >
            キャンセル
          </Button>
          <Button
            color="orange"
            onClick={() => {
              onChangeRawText(selected.rawText);
              onChangeRawOperand(selected.rawOperand);
              setHistoryModalStatus(false);
            }}
          >
            選択
          </Button>
        </div>
      </Modal>
      {isOpenHelp && (
        <AdvancedSearch
          onClose={() => {
            setIsOpenHelp(false);
          }}
        />
      )}
    </>
  );
};
